<div
  class="hud-player-level"
  (click)="!disabled && openHudTabs()"
  [ngbTooltip]="levelTooltipTpl"
  placement="bottom-left"
  container="body"
  tooltipClass="hud delayed"
>
  <div
    class="inner-circle"
    [style.background-image]="'url('+('leaf_hud.png' | asset:'ui') +')'"
  ></div>

  <div class="level">
    {{ player?.level }}
  </div>

  <!-- CIRCLE LEVEL -->
  <svg id="circle" viewBox="0 0 200 200">
    <circle
      cx="100"
      cy="100"
      fill="transparent"
      id="bar"
      r="84"
      stroke-width="32"
      stroke-dasharray="578"
      transform="rotate(0 100,100)"
      [style.strokeDashoffset]="strokeDashOffset"
    ></circle>
  </svg>
</div>

<ng-template #levelTooltipTpl>
  {{'hud.player-level.experience-points' | myTranslate}}<br/>
  {{ player?.points_balance | NumberWithSpaces }} / {{ player?.next_level_points | NumberWithSpaces }}
</ng-template>
