import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractBuildingInfoComponent } from '../../../../abstract/core/abstract-building-info.component';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';
import { select } from '@ngrx/store';
import { selectGameBoardTile } from '../../../../../../../../store/game/selectors';
import { filter } from 'rxjs/operators';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { ApiBuildingService } from '../../../../api/custom/services/api-building.service';
import { BuildingDetailsConfig } from '../../../../../../game-engine/interfaces/building-details-config';

@Component({
  selector: 'app-m-building-custom',
  templateUrl: './m-building-info.component.html',
})
export class MBuildingInfoComponent extends AbstractBuildingInfoComponent implements OnInit, OnDestroy {
  @OwInject(ApiBuildingService) apiBuildingService: ApiBuildingService;

  buildingDetails: BuildingDetailsConfigExtend;
  allBuildingCategories: any[];

  neighbourhoodSums: {
    current_bonus: number;
    max_bonus: number;
  };

  ngOnInit() {
    this.subscribeBoardTile();
  }

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameBoardTile, {playerTileId: this.data.playerTileId}),
        filter(state => !!state),
      )
      .subscribe((tile) => {
        this.getCategories(tile);
      });
  }

  getPlayerBuildingDetails(playerBuildingId: number) {
    this.buildingsService.getPlayerBuildingDetails(playerBuildingId)
      .subscribe((buildingDetails) => {
        buildingDetails['category_details'] = this.getCategoryDetails(buildingDetails.category_id);
        this.buildingDetails = buildingDetails;
        this.afterBuildingDetails();
      });
  }

  getCategories(tile) {
    this.apiBuildingService.getBuildingCategories()
      .subscribe((r) => {
        this.allBuildingCategories = r;
        this.getPlayerBuildingDetails(tile.player_building.player_building_id);
      });
  }

  getCategoryDetails(categoryId) {
    const cat = this.allBuildingCategories.find(c => c.id == categoryId);
    return cat || null;
  }

  afterBuildingDetails() {
    this.setNeighbourhoodSums();
  }

  setNeighbourhoodSums() {
    if (this.buildingDetails['neighbourhood']?.length) {
      this.neighbourhoodSums = {
        current_bonus: 0,
        max_bonus: 0,
      };

      this.buildingDetails['neighbourhood'].forEach((item) => {
        this.neighbourhoodSums.current_bonus += item.current_bonus;
        this.neighbourhoodSums.max_bonus += item.max_bonus;
      });
    }
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}

export interface BuildingDetailsConfigExtend extends BuildingDetailsConfig {
  category_details?: {
    icon?: string;
    id?: string;
    name?: string;
    order?: number;
  };
}
