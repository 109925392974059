<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <div class="title">
      {{'hud.city-character.title' | myTranslate}}
    </div>
  </div>

  <div class="top-description">
    {{'hud.city-character.description' | myTranslate}}
  </div>

  <div class="parameters-container loading-opacity">
    <div class="parameter" *ngFor="let parameter of parameterBalances">
      <div class="image">
        <img [src]="parameter.key + '.png' | asset:'parameters/big'">
      </div>

      <div class="parameter-details">
        <div class="header">
          <div class="title">
            {{ parameter.name }}
          </div>

          <div class="prize-icons-container">
            <div class="value">
              {{ parameter.balance | NumberWithSpaces }}
            </div>

            <div
              class="icon-ranks pointer"
              [class.hide]="!parameter['rank']"
              (click)="openRankDetailsGame(parameter['rank'])"
            >
              <i class="far fa-chart-line"></i>
            </div>
          </div>
        </div>


        <div class="description">
          {{ parameter.tooltip }}
        </div>
      </div>
    </div>
  </div>
</div>
