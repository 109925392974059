import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractMissionsComponent } from "../../../../abstract/core/abstract-missions.component";
import { unsubscribeObject } from "src/app/core/utility/unsubscribe-array";

@Component({
  selector: "app-custom-missions",
  templateUrl: "../../../core/dialogs/missions/missions.component.html"
})
export class MissionsComponent extends AbstractMissionsComponent implements OnInit, OnDestroy{
  subs = {
    CompletedMission: null
  };

  ngOnInit() {
    this.baseInit();
    this.handleAllreadyCompletedMission();
  }

  afterMissionsRequest() {
    this.filterWithoutSlotType(1);
    this.setCurrencyAndProducts();
    this.checkMissionComplete();
    this.setDiffTimes();
    this.sortMissions();
    this.normalMissions.pages = [];
    this.normalMissions.items = this.missions;
    this.generatePages();
  }

  filterWithoutSlotType(missionSlotType: number) {
    this.missions = this.missions.filter((mission) => {
      return (mission.slot.mission_slot_id !== missionSlotType ||
        mission.conditions_completed === mission.conditions_count
      );
    });
  }

  sortMissions() {
    this.missions = this.missions.filter(mission => mission.slot.mission_slot_id > 1);
  }

  // Function handle situation when prerequisites for new mission are already fulfilled on it arrived,
  // and user took all prizes from a several mission without exiting from mission component,
  // then exclamation mark from missions is hidden
  handleAllreadyCompletedMission(): void {
    this.subs.CompletedMission = this.globalService.globalEvents.subscribe(
      (event) => {
        if (!event.value?.missions_to_collect) {
          return;
        }
        if (event.value.missions_to_collect.length === 0) {
          this.dispatchShowedMissions();
        }
      }
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    unsubscribeObject(this.subs);
  }
}
