import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export function customEmailValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {

        const value = control.value;

        if (!value) {
            return null;
        }

        const emailValid = /\S+@\S+\.\S+/.test(value);

        return !emailValid ? {customEmail:true}: null;
    }
}
