import { Component, OnInit } from '@angular/core';
import {
  AbstractRegistrationConfirmAComponent
} from '../../../../abstract/registration-confirm/variant-a/abstract-registration-confirm-a.component';
import { FormControl, Validators } from '@angular/forms';
import { TokenObject } from '../../../../interfaces/token-object';
import { AUTH_ROUTING_ABSOLUTE_PATH } from '../../../../consts/core/routing-auth-absolute.const';
import { ApiOptions } from '../../../../../../core/interfaces/api-options';
import { checkPasswordTheSame } from '../../../../helpers/validators-form-password-the-same.helper';
import {Identifier, IdentifierService} from '../../../../../game/services/identifier.service';
import {OwInject} from '../../../../../../core/decorators/ow-inject.decorator';
import {translate} from '../../../../../../core/helpers/translate.helper';

@Component({
  selector: 'registration-confirm',
  templateUrl: './registration-confirm.component.html',
})
export class RegistrationConfirmComponent extends AbstractRegistrationConfirmAComponent implements OnInit {
  @OwInject(IdentifierService) identifierService;
  identifier = null;
  termsUrl = '';
  slug = '';

  ngOnInit() {
    this.determineSlug();
    this.subscribeRoutingParams();
    this.clearForm();
  }

  clearForm() {

    const inputFields: FormFields = {
      token: this.fb.control(this.token, [Validators.required]),
      username: this.fb.control(null, [Validators.required, Validators.maxLength(23)]),
      password: this.fb.control(null, [Validators.required, Validators.pattern('^(?=.*?[\\W|_])(?=.*?[0-9])(?=.*?[A-ZĘÓĄŚŁŻŹĆŃ]).{8,}$')]),
      passwordRepeat: this.fb.control(null, [Validators.required]),
      confirm: this.fb.control(null, [Validators.required]),
    };
    // Using only when registrating Demo Users
    // if (this.slug === 'demo') {
    //   delete inputFields.username;
    // }

    this.form = this.fb.group(inputFields, {
      validator: checkPasswordTheSame,
    });


    this.slug = this.identifierService.determineSlugFromUrl();
    this.identifierService.getClientIdentifier(this.slug).subscribe((res: Identifier) => {
      this.identifier = res;
      this.identifierService.setIdentifierToLocalStorage(this.identifier);

      const isGameFinished = this.identifierService.checkIfGameIsFinished(res);
      if (isGameFinished) {
        this.identifierService.removeIdentifierFromLocalStorage();
        this.identifierService.redirectToIdentifierPage();
      }

      this.getTermsFileUrl();
    });
  }

  getTermsFileUrl() {
    if (this.identifier['terms_file']) {
      this.termsUrl = `${window.location.origin}/api/files/download/${this.identifier['terms_file'].uuid}/${this.identifier['terms_file'].filename}`;
    }
  }

  registrationConfirm() {
    const data: RegistrationConfirmDataRequest = {
      token: this.form.value.token,
      username: this.form.value.username,
      password: this.form.value.password,
    };

    this.confirmRegistrationRequest(data)
      .subscribe((resp: TokenObject) => {
        this.dispatchToken(resp);
        this.routeToPlayerChoose();
      }, (errResp) => {
        if (errResp.status === 404) {
          errResp.defaultHandler.unsubscribe();
          this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.LOGIN]);
          this.dialogService.openAlert({description: translate('auth.registration-confirm-a.token-expired')});
        }
      });
  }

  onPaste(event) {
    event.preventDefault();
  }

  getErrors(controlName) {
    return controlName.invalid && controlName.dirty && controlName.errors;
  }

  confirmRegistrationRequest(data: RegistrationConfirmDataRequest) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true
    };
    // Using only when registrating Demo Users
    if (this.slug === 'demo') {
      return this.apiService.post('registration/demo/confirm', options);
    }

    return this.apiService.post('registration/confirm', options);
  }

  determineSlug() {
    this.slug = this.identifierService.determineSlugFromUrl();
  }
}

interface RegistrationConfirmDataRequest {
  token: string;
  username?: string;
  password: string;
}

interface FormFields {
  token: FormControl;
  username: FormControl;
  password: FormControl;
  passwordRepeat: FormControl;
  confirm: FormControl;
}
