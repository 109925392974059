export function transformErrorApi(errResp) {
  let errors = "";

  if (errResp.status != 500 && errResp.status != 502 && errResp.status != 0) {
    let jsonBody = errResp.error;

    //if native xhr response
    if (!errResp.error) {
      errResp.error = JSON.parse(errResp.response);
    }

    if (jsonBody.hasOwnProperty('errors')) {
      let errorsApi = jsonBody.errors;

      if (errorsApi['errors']) {
        errorsApi.errors.forEach((error) => {
          errors += error + '<br/>';
        })
      }
    }

    if (jsonBody.hasOwnProperty('errors') && jsonBody['errors'].hasOwnProperty('children')) {
      for (let key in jsonBody.errors.children) {
        if (jsonBody.errors.children[key].hasOwnProperty('errors')) {
          jsonBody.errors.children[key].errors.forEach((error) => {
            // if (dictionaryError[key]) {
            //   errors += (dictionaryError[key] || key) + ': ';
            // }
            errors += error + '<br/>';
          })
        }
      }
    }


    if (jsonBody.hasOwnProperty('message')) {
      if (errors.length == 0) {
        errors += jsonBody.message + '<br/>';
      }
    }
  }

  return errors;
}

export const dictionaryError = {
  'first_name': 'Imię',
  'last_name': 'Nazwisko',
  'telephone': 'Telefon',
  'zip_code': 'Kod pocztowy',
  'city': 'Miasto',
  'branch': 'Oddział',
  'street': 'Ulica',
  'old_password': 'Aktualne hasło',
  'new_password': 'Nowe hasło',
  'locale_number': 'Numer domu i mieszkania',
  'email': 'E-mail',
  'firstName': 'Imię',
  'lastName': 'Nazwisko',
  'pesel': 'Pesel',
  'phone': 'Nr telefonu',
  'bankAccount': 'Nr konta bankowego',
  'postCode': 'Kod pocztowy',
  'homeNumber': 'Nr domu',
  'flatNumber': 'Nr mieszkania',
  'voivodeship': 'Województwo',
  'county': 'Powiat',
  'commune': 'Gmina',
  'nip': 'NIP',
  'name': 'Nazwa firmy',
  'privateEmail': 'Prywatny e-mail',
  'password': 'Hasło',
};
