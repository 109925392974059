import { HudMenuComponent } from '../../../../custom/components/hud-menu/hud-menu.component';
import { HudResourcesComponent } from '../../../../custom/components/hud-resources/hud-resources.component';
import { HudKnowledgeComponent } from '../../../../custom/components/hud-knowledge/hud-knowledge.component';
import { HudParametersComponent } from '../../../../custom/components/hud-parameters/hud-parameters.component';

export const CUSTOM_DYNAMIC_COMPONENTS = {
  HUD_MENU: {
    component: HudMenuComponent,
    viewContainerRef: 'hudMenuTpl',
  },
  HUD_RESOURCES: {
    component: HudResourcesComponent,
    viewContainerRef: 'hudResourcesTpl',
  },
  HUD_KNOWLEDGE: {
    component: HudKnowledgeComponent,
    viewContainerRef: 'dynamicsComponentTpl',
  },
  HUD_PARAMETERS: {
    component: HudParametersComponent,
    viewContainerRef: 'hudParametersTpl',
  }
};
