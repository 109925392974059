import { ComponentFactoryResolver, Directive, Injectable, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../core/decorators/ow-inject.decorator';
import { AbstractMessageService } from '../../../game/game-ui/message/abstract/core/abstract-message.service';
import { GameService } from '../../../game/services/game.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/state';
import { DialogService } from '../../../shared/providers/dialog.service';
import { CurrencyService } from '../../../../core/providers/currency.service';
import { PlayerService } from '../../../player/providers/player.service';
import { GuiService } from '../../../game/services/gui.service';
import { Observable } from 'rxjs';
import { UserActions, UserSelectors } from '../../../../store/user';
import { take } from 'rxjs/operators';
import { PlayerActions, PlayerSelectors } from '../../../../store/player';
import { GlobalEvent } from '../../../../core/interfaces/shared.interfaces';
import { EVENTS } from '../../../../core/consts/core/events';
import { BoardTileUpdate, NewImportantMessageReceived } from '../../../../store/game/actions';
import { UpdateUnreadMessages } from '../../../../store/player/actions';
import { CORE_DYNAMIC_COMPONENTS } from '../../consts/core/dynamic-components/play-game/dynamic-components.const';
import { CUSTOM_DYNAMIC_COMPONENTS } from '../../consts/custom/dynamic-components/play-game/dynamic-components.const';
import { setDynamicsComponents } from '../../../../core/helpers/dynamic-component.helper';
import { PingService } from '../../../../core/providers/ping.service';
import { AndroidService } from '../../../../core/providers/android.service';
import { Router } from '@angular/router';

@Directive()
@Injectable()
export abstract class AbstractPlayGameComponent extends AbstractInjectBaseComponent {
  @OwInject(GameService) gameService: GameService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(CurrencyService) currencyService: CurrencyService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(PingService) pingService: PingService;
  @OwInject(GuiService) guiService: GuiService;
  @OwInject(AndroidService) androidService: AndroidService;
  @OwInject(Router) router: Router;
  @OwInject(ComponentFactoryResolver) componentFactoryResolver: ComponentFactoryResolver;
  @ViewChild('appHudTpl', {static: true, read: ViewContainerRef}) appHudTpl: ViewContainerRef;
  abstract messagesService: AbstractMessageService;
  inactivityTimeout: number;

  gameReady$: Observable<boolean>;

  subs = {
    globalEvents: null,
    user: null,
    importantMessages: null,
    other: null,
  };

  updateUserOnce() {
    this.store
      .pipe(
        select(UserSelectors.selectUser),
        take(1),
      )
      .subscribe((user) => {
        this.store.dispatch(new UserActions.UpdateUser(user));
      });
  }

  updatePlayerOnce() {
    this.store
      .pipe(
        select(PlayerSelectors.selectPlayer),
        take(1),
      )
      .subscribe((player) => {
        this.store.dispatch(new PlayerActions.TryUpdatePlayer(player));
      });
  }

  handleGlobalEvent(event: GlobalEvent) {
    switch (event.name) {
      case EVENTS.GLOBAL.NEW_IMPORTANT_MESSAGE:
        this.store.dispatch(new NewImportantMessageReceived(event.value));
        break;
      case EVENTS.GLOBAL.TILE_CHANGED:
        this.store.dispatch(new BoardTileUpdate(event.value));
        break;
      case EVENTS.GLOBAL.UNREAD_COUNT:
        this.store.dispatch(new UpdateUnreadMessages(event.value));
        break;
    }
  }

  setDynamicsComponents() {
    setDynamicsComponents.bind(this)({
      coreDynamicComponents: CORE_DYNAMIC_COMPONENTS,
      customDynamicComponents: CUSTOM_DYNAMIC_COMPONENTS
    });
  }
}
