import {Injectable} from '@angular/core';
import {ApiService} from '../../../core/providers/api.service';
import {Router} from '@angular/router';
import * as moment from 'moment';

export interface Identifier {
  ranks_disabled: boolean;
  company: string;
  demo: boolean;
  end_game: string;
  is_game_finished: boolean;
  last_rank_calculation_date: string;
  logo_file_name: string;
  logo_url: string;
  terms_file: {
    filename: string;
    uuid: string;
  };
  slug?: string;
  instant_registration?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class IdentifierService {
  slug = '';
  public readonly localStorageIdentifierKey = `owClientIdentifier`;

  constructor(private api: ApiService,
              private router: Router) {
    const identifierData = localStorage.getItem(this.localStorageIdentifierKey);
    if (identifierData) {
      this.slug = identifierData;
    }
  }

  setIdentifierToLocalStorage(data: Identifier, slug: string = null) {
    localStorage.setItem(this.localStorageIdentifierKey, data?.slug || slug);
  }

  getIdentifierFromLocalStorage(): Identifier | {} {
    return localStorage.getItem(this.localStorageIdentifierKey) || null;
  }

  removeIdentifierFromLocalStorage() {
    localStorage.removeItem(this.localStorageIdentifierKey);
  }

  getSlug() {
    return this.slug;
  }

  checkIfGameIsFinished(identifier: Identifier): boolean {
    if (identifier == null) {
      return;
    }

    if (identifier.end_game === '-') {
      return false;
    }

    return !moment().isSameOrBefore(identifier.end_game) || identifier.is_game_finished;
  }

  determineSlugFromUrl() {
    // we know that slug is always after the 'k/' prefix
    const splitPathName = window.location.pathname.split('/');
    const prefixIndex = splitPathName.findIndex(x => x.toLowerCase() === 'k');
    return splitPathName[prefixIndex + 1];
  }

  getClientIdentifier(clientSlug?: string) {
    if (clientSlug) {
      this.slug = clientSlug;
    }
    return this.api.get(`clients/${this.slug}`, { headers: { 'x-token': '' } });
  }

  redirectToIdentifierPage() {
    this.router.navigate(['/identifier']);
  }

  redirectToLoginPage(identifier: Identifier) {
    if (identifier == null) {
      return;
    }
    const url = identifier.slug;
    this.router.navigate([`/main/k/${url}`]);
  }

  isInstantRegistration(identifier: Identifier): boolean {
    if (identifier == null) {
      return;
    }

    return identifier?.instant_registration;
  }
}
