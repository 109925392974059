import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../core/providers/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiQaCustomService {

  constructor(
    public apiService: ApiService,
  ) {
  }

  getAllQaList() {
    return this.apiService.get(`/qa/all`);
  }
}
