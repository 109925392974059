import {Component, OnInit} from '@angular/core';
import { AbstractForgotPasswordComponent } from '../../../../abstract/abstract-forgot-password.component';
import {Identifier, IdentifierService} from '../../../../../game/services/identifier.service';
import {OwInject} from '../../../../../../core/decorators/ow-inject.decorator';
import {ResetPasswordRequest} from '../../../../interfaces/reset-password';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'custom-auth-forgot-password',
  templateUrl: './forgot-password.component.html',

})
export class ForgotPasswordComponent extends AbstractForgotPasswordComponent implements OnInit {
  @OwInject(IdentifierService) identifierService;
  @OwInject(ActivatedRoute) activatedRoute;
  identifier: Identifier = null;

  ngOnInit() {
    const slug = this.identifierService.getSlug();
    const routeSlug = this.activatedRoute.snapshot.params.identifier;

    if (!routeSlug && !slug) {
      this.identifierService.removeIdentifierFromLocalStorage();
      this.identifierService.redirectToIdentifierPage();
      return;
    }

    this.identifierService.getClientIdentifier(routeSlug || slug).subscribe(res => {
      this.identifier = res;
      this.identifierService.setIdentifierToLocalStorage(this.identifier);
    });
  }

  onFormSubmit() {
    const data: ResetPasswordRequest = {
      email: this.form.value.email,
      slug: this.identifier?.slug
    };

    this.authService.resetPasswordRequest(data)
      .pipe(
        map(() => {
          this.formSendSuccess = true;
        }),
        catchError((errResp) => {
          return of(errResp);
        }),
      )
      .subscribe();
  }
}
