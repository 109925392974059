<div class="ow-dialog window-d primary no-transparent">
  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="close()"
  ></m-ui-close-button>

  <!-- TITLE -->
  <p class="title">
    {{ 'buildings.categories.title' | myTranslate }}:
  </p>

  <div class="categories-container">
    <ng-container *ngIf="data.items?.length > 0">
      <div
        class="description"
        *ngFor="let item of data.items"
      >
        <img alt="''" [src]="item.icon | asset: item.path" />
        <span>{{ item.name }}</span>
      </div>
    </ng-container>
  </div>
</div>
