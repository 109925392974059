<table *ngIf="rankResults">
  <thead>
  <tr [ngClass]="{'hide-element': show != 'header'}">
    <ng-container *ngFor="let column of columns">
      <!-- FE COLUMN HEADER -->
      <th
        *ngIf="!column.columnFromApi"
        [style.text-align]="column.textAlign"
      >
        {{ column.header }}
      </th>

      <!-- BE COLUMN HEADER -->
      <ng-container *ngIf="column.columnFromApi">
        <th
          *ngIf="rank['value' + column.columnFromApi + '_label'] as label"
          [style.text-align]="column.textAlign"
        >
          {{ label }}
        </th>
      </ng-container>
    </ng-container>
  </tr>
  </thead>
  <tbody>
  <!-- MY POSITION -->
  <ng-container
    *ngIf="rankResults.my_position"
    [ngTemplateOutlet]="tableTrTpl"
    [ngTemplateOutletContext]="{
          item: rankResults.my_position,
          rank: rank,
          showTr: show === 'myPosition'
        }"
  ></ng-container>

  <!-- RESULTS -->
  <ng-container
    *ngFor="let item of rankResults.results"
    [ngTemplateOutlet]="tableTrTpl"
    [ngTemplateOutletContext]="{
          item: item,
          rank: rank,
          showTr: show === 'results'
  }"
  ></ng-container>
  </tbody>
</table>


<!-- TABLE TR TEMPLATE -->
<ng-template
  #tableTrTpl
  let-item="item"
  let-rank="rank"
  let-showTr="showTr"
>
  <tr [ngClass]="{'hide-element': !showTr}">
    <ng-container *ngFor="let column of columns">
      <!-- FE COLUMN -->
      <ng-container *ngIf="!column.columnFromApi">
        <ng-container *ngIf="column.custom; else defaultTdTpl">
          <!-- PRIZES COLUMN -->
          <td
            *ngIf="column.template === 'prizes'"
            [style.text-align]="column.textAlign"
          >
            <div class="prizes">
              <!-- CURRENCY PRIZES -->
              <m-ui-currency
                *ngFor="let currency of item.currency_prizes"
                [item]="currency"
                [lack]="false"
                [stockView]="STOCK_VIEW.H"
              ></m-ui-currency>

              <!-- PRODUCT PRIZES -->
              <m-ui-product
                *ngFor="let product of item.product_prizes"
                [hideValue]="true"
                [item]="product"
                [lack]="false"
                [stockView]="STOCK_VIEW.H"
                [lockOpenStorage]="true"
              ></m-ui-product>
            </div>
          </td>

          <!-- AVATAR COLUMN -->
          <td
            *ngIf="column.template === 'avatar' && GAME_CONFIG.FLAGS.RANKINGS.IS_VISIBLE_AVATARS"
            [style.text-align]="column.textAlign"
          >
            <div
              [ngStyle]="item.profile_image_url && {'background-image': 'url(' + item.profile_image_url + ')'}"
              class="avatar"
            ></div>
          </td>

          <!-- PRIZE -->
          <td
            *ngIf="column.template === 'prize'"
            [style.text-align]="column.textAlign"
            class="prize"
          >
            <div
              [innerHTML]="item.prize | resourceFromString"
              class="prize"
            ></div>
          </td>
        </ng-container>

        <!-- DEFAULT COLUMN -->
        <ng-template #defaultTdTpl>
          <td [style.text-align]="column.textAlign">
            {{ item[column.value] }}
          </td>
        </ng-template>
      </ng-container>

      <!-- BE COLUMN -->
      <ng-container *ngIf="column.columnFromApi">
        <td
          *ngIf="rank['value' + column.columnFromApi + '_label']"
          [ngbTooltip]="item['value' + column.columnFromApi + '_tooltip']"
          container="body"
          placement="top"
          [style.text-align]="column.textAlign"
        >
          {{ item['value' + column.columnFromApi] }}
        </td>
      </ng-container>
    </ng-container>
  </tr>
</ng-template>
