import * as R from 'ramda';
import { Injectable } from '@angular/core';
import { ButtonMenuConfigFilter, MappedMenuButtonConfig, MenuButtonConfig } from '../interfaces/menu-buttonts-config.interface';
import { PlayerService } from '../../player/providers/player.service';
import { CUSTOM_MENU_BUTTONS_CONFIG } from '../constants/menu-buttons-custom.const';
import { MyTranslatePipe } from '../../../core/pipes/my-translate.pipe';

@Injectable({
  providedIn: 'root'
})
export class MenuButtonsConfigService {

  buttons: MenuButtonConfig[] = [];

  constructor(private playerService: PlayerService) {
    const myTranslatePipe = new MyTranslatePipe();
    const CUSTOM_MENU_BUTTONS_CONFIG_TRANSLATED: MenuButtonConfig[] = CUSTOM_MENU_BUTTONS_CONFIG.map<MenuButtonConfig>(buttonConfig => {
      const newButtonConfig = buttonConfig;
      newButtonConfig.tooltip = myTranslatePipe.transform(newButtonConfig.tooltip);
      return newButtonConfig;
    });

    this.setButtons(CUSTOM_MENU_BUTTONS_CONFIG_TRANSLATED);
  }

  setButtons(x: MappedMenuButtonConfig[]) {
    this.buttons = x;
  }

  getButtonsMenuConfig(config: ButtonMenuConfigFilter): MappedMenuButtonConfig[] {
    let buttons = R.clone(this.buttons)
      .filter(button => button.place === config.place)
      .filter(button =>
        button.gui_unlocks_button === null || config.gui_unlocks[button.gui_unlocks_button]
      ).map(button => button.group_name ? button : {...button, isVisible: true});
    // CORE OVERRIDE
    // filter ranks if this is demo account (#25810)
    if (this.playerService.player?.['client_info']?.ranks_disabled) {
      buttons = buttons.filter(x => x.name !== 'rankings');
    }
    // END CORE OVERRIDE

    buttons = R.sortBy(R.prop('order'))(buttons);
    buttons = buttons.reduce((result, button) => {
      if (button.type === 'group') {
        if (config.isDialog) {
          return result;
        }
        const groupButtons = buttons.filter(btn => btn.group_name === button.name);

        switch (button.group_variant) {
          case 2:
            if (groupButtons.length === 1) {
              result.push({...groupButtons[0], isVisible: true});
            } else if (groupButtons.length > 1) {
              result.push({...button, group_buttons: groupButtons});
            }
            break;
          case 3:
            if (groupButtons.length > 1) {
              result.push({...button, group_buttons: groupButtons});
            }
            break;
          default:
            if (groupButtons.length > 0) {
              result.push({...button, group_buttons: groupButtons});
            }
            break;
        }
      } else if (!button.group_name || config.isDialog) {
        result.push(button);
      }
      return result;
    }, []);
    return buttons;
  }
}

