<ng-container *ngIf="!isDialog">
  <div
    class="main-buttons-container"
    *ngIf="playerService.player"
  >
    <ng-container *ngFor="let menuButton of menuButtons; let i = index">
      <ng-container
        *ngIf="i < 4 || !otherButtonsIsActive"
        [ngTemplateOutlet]="buttonTpl"
        [ngTemplateOutletContext]="{button: menuButton, index: i}"
      ></ng-container>
    </ng-container>

    <!--MORE-->
    <ng-container
      *ngIf="otherButtonsIsActive"
      [ngTemplateOutlet]="moreButtonTpl"
    ></ng-container>
  </div>

  <!-- MORE BUTTON TPL -->
  <ng-template #moreButtonTpl>
    <div class="buttons-vertical">
      <button
        class="main-button"
        matTooltip="Więcej"
        [matTooltipShowDelay]="300"
        [matTooltipPosition]="isDialog ? 'below' : 'right'"
        (click)="openHudMenuMore()"
      >
        <ow-object-svg [svgObject]="assetsService.assetsData['/ui/more.svg']"></ow-object-svg>
      </button>
    </div>
  </ng-template>
</ng-container>

<ng-container *ngIf="isDialog">
  <div
    class="main-buttons-container"
    *ngIf="playerService.player"
  >
    <ng-container *ngFor="let menuButton of menuButtons; let i = index">
      <ng-container
        [ngTemplateOutlet]="buttonTpl"
        [ngTemplateOutletContext]="{button: menuButton, index: i}"
      ></ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template
  #buttonTpl
  let-button="button"
  let-index="index"
>
  <div class="buttons-vertical">
    <button
      class="main-button"
      [class.active]="button.isVisible || isDialog"
      [class.anim]="button.group_name"
      [matTooltip]="button.tooltip"
      [matTooltipPosition]="isDialog ? 'below' : 'right'"
      [matTooltipShowDelay]="300"
      (click)="handleMenuButtonEvent(button)"
    >
      <ow-object-svg [svgObject]="assetsService.assetsData['/ui/' + button.icon]"></ow-object-svg>

      <ng-container *ngIf="button.customNotification as notification">
        <ng-template
          *ngIf="notification.type === 'async' && menuButtonsObservables[index]?.showNotification"
          [ngTemplateOutlet]="notificationTpl"
        ></ng-template>
      </ng-container>

      <div
        *ngIf="isDialog"
        class="button-name"
      >
        {{ button.tooltip }}
      </div>
    </button>

    <ng-container *ngFor="let menuButton of button.group_buttons">
      <ng-container
        [ngTemplateOutlet]="buttonTpl"
        [ngTemplateOutletContext]="{button: menuButton}"
      ></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #notificationTpl>
  <i class="icon notification"></i>
</ng-template>
