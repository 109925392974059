import { Directive, Injectable } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { ApiService } from '../../../../../../core/providers/api.service';
import { ApiOptions } from '../../../../../../core/interfaces/api-options';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { AUTH_ROUTING_ABSOLUTE_PATH } from '../../../../consts/core/routing-auth-absolute.const';
import {Identifier, IdentifierService} from "../../../../../game/services/identifier.service";
import {UtilityActions} from "../../../../../../store/utility";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../store/state";
import {customEmailValidator} from "../../../utils/custom-email-validator";


@Directive()
@Injectable()
export abstract class AbstractRegistrationInstantComponent extends AbstractInjectBaseComponent {
  @OwInject(FormBuilder) fb: FormBuilder;
  @OwInject(Router) router: Router;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(IdentifierService) identifierService;
  @OwInject(ActivatedRoute) activatedRoute;
  @OwInject(Store) store: Store<AppState>;
  identifier: Identifier = null;
  AUTH_ROUTING_ABSOLUTE_PATH = AUTH_ROUTING_ABSOLUTE_PATH;
  form: FormGroup;

  clearForm() {
    this.form = this.fb.group({
      username: this.fb.control(null, [Validators.required, Validators.maxLength(23)],),
      email: this.fb.control(null, [Validators.required, customEmailValidator()]),
      password: this.fb.control(null, [Validators.required, Validators.pattern('^(?=.*?[\\W|_])(?=.*?[0-9])(?=.*?[A-ZĘÓĄŚŁŻŹĆŃ]).{8,}$')]),
    });
  }

  registration() {
    const data: RegistrationRequestData = {
      username: this.form.value.username,
      email: this.form.value.email,
      password: this.form.value.password
    };
    this.registrationRequest(data)
      .subscribe((tokenObject) => {
        this.store.dispatch(new UtilityActions.SetToken(tokenObject));
        this.router.navigate(['/auth/player-choose']);
      });
  }

  registrationRequest(data: RegistrationRequestData) {
    const options: ApiOptions = {
      body: {
        ...data,
        slug: this.activatedRoute.snapshot.params.identifier
      },
      sendRequestWithoutToken: true
    };
    return this.apiService.post('/registration/instant', options);
  }

  getErrors(controlName) {
    return controlName.invalid && controlName.dirty && controlName.errors;
  }
}

interface RegistrationRequestData {
  username: string;
  email: string;
  password: string;
}
