import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractWarehouseDetailsComponent } from '../../../../abstract/core/abstract-warehouse-details.component';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';

@Component({
  selector: 'app-custom-warehouse-details',
  templateUrl: './warehouse-details.component.html',
})
export class WarehouseDetailsComponent extends AbstractWarehouseDetailsComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribeDefaultTradingCurrencyId();
    this.parseProduct();
    this.getProductDetails();
    this.subscribePlayer();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
