import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/providers/api.service';
import { PlayerService } from '../../player/providers/player.service';

@Injectable({providedIn: 'root'})
export class NotificationsService {
  constructor(
    private apiService: ApiService,
    private playerService: PlayerService,
  ) {
  }

  setAllNotificationAsRead() {
    return this.apiService.patch(
        `/notifications/type/`,
        {},
    );
  }

  setNotificationReaded(type) {
    return this.apiService.patch(
      `/notifications/type/${type} `,
      {},
    );
  }

  setItemNotificationReaded(player_notification_id: number) {
    return this.apiService.patch(
      `/notifications/${player_notification_id} `,
      {},
    );
  }

  setItemNotificationReadedSubscribeRequest(player_notification_id: number) {
    this.setItemNotificationReaded(player_notification_id).subscribe();
  }

  getNotificationCountByType(type: string) {
    const notificationCount = this.playerService.player['gui_notifications']?.[type];
    return notificationCount || 0;
  }

  hasNotificationByType(type: string) {
    return this.getNotificationCountByType(type) > 0;
  }

  getNotificationItemByKey(type: string, key: 'task_id' | 'event_id' | 'achievement_id', value: any) {
    let notification = null;

    if (this.hasNotificationByType(type)) {
      const notifications = this.playerService.player['gui_notifications_list']?.[type];

      if (notifications && notifications.length) {
        notification = notifications.find(item => item[key] === value);
      }
    }

    return notification;
  }

  clearNotificationItemByKey(type: string, key: 'task_id' | 'event_id' | 'achievement_id', value: any) {
    const notificationItem = this.getNotificationItemByKey(type, key, value);
    if (notificationItem) {
      this.setItemNotificationReadedSubscribeRequest(notificationItem['player_notification_id']);
    }
  }
}
