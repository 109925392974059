import {Action} from '@ngrx/store';
import {Credentials} from '../../../modules/auth/interfaces/credentials';

export enum ActionTypes {
  AUTH_IDENTIFIER_START = '[auth] start company identifier authentication',
  AUTH_IDENTIFIER_SUCCESS = '[auth] company identifier authentication success',
  AUTH_IDENTIFIER_FAIL = '[auth] company identifier authentication success failed',
  AUTH_START = '[auth] start user authentication',
  AUTH_SUCCESS = '[auth] user authentication success',
  AUTH_FAIL = '[auth] user authentication failed',
  AUTH_CLIENT_START = '[auth] start client user authentication',
  AUTH_CLIENT_SUCCESS = '[auth] client user authentication success',
  AUTH_CLIENT_FAIL = '[auth] client user authentication failed',
  AUTH_LOGOUT = '[auth] start user logout',
  AUTH_LOGOUT_SUCCESS = '[auth] user logout success',
}

// OVERRIDE CORE FILE START
export class AuthIdentifierStart implements Action {
  readonly type = ActionTypes.AUTH_IDENTIFIER_START;

  constructor(public payload: Credentials) {
  }
}

export class AuthIdentifierSuccess implements Action {
  readonly type = ActionTypes.AUTH_IDENTIFIER_SUCCESS;

  constructor() {
  }
}

export class AuthIdentifierFail implements Action {
  readonly type = ActionTypes.AUTH_IDENTIFIER_FAIL;

  constructor(public payload: any) {
  }
}
// OVERRIDE CORE FILE END

export class AuthStart implements Action {
  readonly type = ActionTypes.AUTH_START;

  constructor(public payload: Credentials) {
  }
}

export class AuthSuccess implements Action {
  readonly type = ActionTypes.AUTH_SUCCESS;

  constructor() {
  }
}

export class AuthFail implements Action {
  readonly type = ActionTypes.AUTH_FAIL;

  constructor(public payload: any) {
  }
}

// OVERRIDE CORE FILE START
export class AuthClientStart implements Action {
  readonly type = ActionTypes.AUTH_CLIENT_START;

  constructor(public payload: Credentials) {
  }
}

export class AuthClientSuccess implements Action {
  readonly type = ActionTypes.AUTH_CLIENT_SUCCESS;

  constructor() {
  }
}

export class AuthClientFail implements Action {
  readonly type = ActionTypes.AUTH_CLIENT_FAIL;

  constructor(public payload: any) {
  }
}
// OVERRIDE CORE FILE END

export class AuthLogout implements Action {
  readonly type = ActionTypes.AUTH_LOGOUT;

  constructor() {
  }
}

export class AuthLogoutSuccess implements Action {
  readonly type = ActionTypes.AUTH_LOGOUT_SUCCESS;

  constructor() {
  }
}

export type Actions =
  AuthStart
  | AuthSuccess
  | AuthFail
  | AuthLogout
  | AuthLogoutSuccess
  | AuthIdentifierStart
  | AuthIdentifierSuccess
  | AuthIdentifierFail
  | AuthClientStart
  | AuthClientSuccess
  | AuthClientFail
  ;
