const determineSiteLanguage = () => {
  if (typeof window === 'undefined') {
    return 'pl';
  }

  const prefix = window.location.hostname.split('.')[0];

  switch (prefix) {
    case 'pl':
      return prefix;
    case 'en':
      return prefix;
    default:
      return 'pl';
  }
}
if (typeof module === 'object') module.exports = determineSiteLanguage;
