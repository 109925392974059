<div class="global-dialog">
  <!-- [class.one-category]="categories.length == 1"-->

  <loading [classNames]="['white', 'stroke']" [size]="'big'"></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <div class="buildings-list-container loading-opacity">
    <ng-container *ngIf="buildings && buildings.length">

      <!-- NAVIGATION CENTER -->
      <m-ui-navigation-center
        [hidePrev]="sliderActiveIndex <= 0"
        [hideNext]="sliderActiveIndex >= buildings.length - 1"
        (prevClicked)="prevSlide()"
        (nextClicked)="nextSlide()"
      ></m-ui-navigation-center>

      <!-- SLIDER -->
      <swiper
        #sliderBuildings
        [config]="swiperConfig"
        [(index)]="sliderActiveIndex"
      >
        <div
          *ngFor="let building of buildings; let idx = index"
          (click)="building && openBuildingDetails(building.building_id, idx)"
        >
          <ng-container *ngIf="player.level >= building.required_level; else requiredLevelTpl">
            <!-- GROUP BUILDING -->
            <m-ui-group-count [building]="building" [class.active]="idx == sliderActiveIndex"></m-ui-group-count>
          </ng-container>
          <ng-template #requiredLevelTpl>
            <!-- BUILDING LOCK -->
            <div class="building-lock" [class.active]="idx == sliderActiveIndex">
              <i class="fas fa-lock"></i>
              <span>
                {{ 'building-list.from-level' | myTranslate:[building.required_level] }}
              </span>
            </div>
          </ng-template>
          <div class="ow-dialog window-c primary" [class.active]="idx == sliderActiveIndex">
            <!-- ICON -->
            <div class="icon-container">
              <img alt="" [src]="building | buildingIcon: false"/>
            </div>

            <!-- NAME -->
            <p class="name">{{ building.name }}</p>

            <!-- RESOURCE -->
            <div class="bar-price" *ngIf="building.build_currency_prices.length > 0">
              <m-ui-costs [stockView]="STOCK_VIEW.D" [currencies]="building.build_currency_prices"></m-ui-costs>
            </div>

            <div
              class="category-icon"
              *ngIf="building.category_details"
              [matTooltipPosition]="'above'"
              [matTooltipShowDelay]="300"
              [matTooltip]="building.category_details.name"
              [matTooltipDisabled]="!building.category_details.name"
            >
              <img alt="" [src]="building.category_details?.icon + '.png' | asset: 'ui'" />
            </div>
          </div>
        </div>
      </swiper>
      <m-ui-slider-dots [sliderRef]="sliderBuildings"></m-ui-slider-dots>
    </ng-container>

    <button (click)="openLegend()" class="button-legend" *ngIf="buildings?.length && allBuildingCategories?.length">
      <i class="fas fa-info"></i>
    </button>
  </div>
</div>
