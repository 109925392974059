import { Component, OnInit } from '@angular/core';
import { AbstractMessageDetailsNormalComponent } from '../../../../abstract/core/abstract-message-details-normal.component';
import { KnowledgePillComponent } from '../../../../../hud/custom/dialogs/knowledge-pill/knowledge-pill.component';
import { NotificationsService } from 'src/app/modules/game/services/notifications.service';
import { OwInject } from 'src/app/core/decorators/ow-inject.decorator';
import { unsubscribeObject } from 'src/app/core/utility/unsubscribe-array';
import { MatDialog } from '@angular/material/dialog';
import { QaListViewComponent } from 'src/app/modules/game/game-ui/qa/base/custom/dialogs/qa-list-view/qa-list-view.component';

enum QAType {
  pill = "pill",
  quiz = "quiz",
  survey = "survey"
}
@Component({
  selector: "app-message-details-normal-custom",
  templateUrl: "./message-details-normal.component.html"
})
export class MessageDetailsNormalComponent extends AbstractMessageDetailsNormalComponent implements OnInit {
  @OwInject(NotificationsService) notificationsService: NotificationsService;
  @OwInject(MatDialog) matDialog: MatDialog;
  
  subs = {
    notyficationReadedPills: null
  };

  ngOnInit() {
    this.afterInit();
  }

  openPill(base_id: number, id: number) {
    this.dialogService.open(KnowledgePillComponent, {
      data: {
        base_pill_id: base_id,
        pill_id: id
      }
    });
    this.readNotyfication();
  }

  readNotyfication() {
    const readedNotyficationType = this.message.qa_info.qa_type;
    switch (readedNotyficationType) {
      case QAType.pill:
        this.subs.notyficationReadedPills = this.notificationsService
          .setNotificationReaded("pill")
          .subscribe();
        break;
      case QAType.quiz:
        this.subs.notyficationReadedPills = this.notificationsService
          .setNotificationReaded("qa")
          .subscribe();
        break;
      case QAType.survey:
        this.subs.notyficationReadedPills = this.notificationsService
          .setNotificationReaded("survey")
          .subscribe();
        break;
    }
  }
  setReadMessage(){
    this.setRead().then();
  }

  ngOnDestroy(): void {
    unsubscribeObject(this.subs);
  }
}
