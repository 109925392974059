import { Component, OnInit } from "@angular/core";
import { AbstractQaListComponent } from "../../../../abstract/core/abstract-qa-list.component";
import { EVENT_DIALOGS_NAMES_QA } from "../../../../consts/core/event-dialogs/event-names.const";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { ApiQaCustomService } from "../../../../api/custom/services/api-qa-custom.service";
import { handleMessageDetailsType } from "../../../../../message/helpers/core/message.helper";
import { ApiMessageService } from "../../../../../message/api/core/services/api-message.service";
import { MessageOpenDetailsData } from "../../../../../message/interfaces/core/message-open-details-data.interface";
import { MessageService } from "../../../../../message/services/core/message.service";
import { NotificationsService } from "../../../../../../services/notifications.service";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { NOTIFICATION_TYPES } from "../../../../../hud/const/custom/notification-types.const";
import { map } from "rxjs/operators";
import { ApiPillsServices } from "src/app/modules/game/game-ui/hud/api/custom/api-pills.services";
import { MatDialog } from "@angular/material/dialog";
import { MessageEventFromDetails } from "src/app/modules/game/game-ui/message/interfaces/core/message-callback-from-details.interface";

enum QAType {
  pill = "pill",
  quiz = "quiz",
  survey = "survey"
}

@Component({
  selector: "app-qa-list-view",
  templateUrl: "./qa-list-view.component.html"
})
export class QaListViewComponent
  extends AbstractQaListComponent
  implements OnInit
{
  @OwInject(ApiQaCustomService) apiQaCustomService: ApiQaCustomService;
  @OwInject(ApiMessageService) apiMessageService: ApiMessageService;
  @OwInject(MessageService) messagesService: MessageService;
  @OwInject(NotificationsService) notificationsService: NotificationsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(ApiPillsServices) apiPillsServices: ApiPillsServices;
  @OwInject(MatDialog) matDialog: MatDialog;

  allQa = [];
  isLoading = false;
  activeFilter: { propertyName: string; value: string } = {
    propertyName: "type",
    value: null
  };
  filters = [
    { type: QAType.quiz, label: "qa.qa-list.quizes" },
    { type: QAType.survey, label: "qa.qa-list.polls" },
    { type: QAType.pill, label: "qa.qa-list.knowledge-pills" }
  ];

  ngOnInit() {
    this.data.location = 0;
    this.checkNotification();
  }

  checkNotification() {
    let filter;

    if (
      this.notificationsService.hasNotificationByType(NOTIFICATION_TYPES.QA)
    ) {
      filter = this.filters[0];
    } else if (
      this.notificationsService.hasNotificationByType(NOTIFICATION_TYPES.SURVEY)
    ) {
      filter = this.filters[1];
    } else if (
      this.notificationsService.hasNotificationByType(NOTIFICATION_TYPES.PILL)
    ) {
      filter = this.filters[2];
    } else {
      filter = this.filters[0];
    }

    this.changeFilter(filter);
    this.getAllQaList();
  }

  getAllQaList() {
    this.isLoading = true;
    this.apiQaCustomService
      .getAllQaList()
      .pipe(map(this.allQaToArrayFormat))
      .subscribe(
        (resp) => {
          this.allQa = resp;
          this.afterQaList();
        },
        () => {},
        () => {
          this.clearNotification(this.filters[0].type);
          this.isLoading = false;
        }
      );
  }

  allQaToArrayFormat(element: any) {
    if (Array.isArray(element)) {
      return element;
    }

    const newArray: any[] = [];
    for (const key in element) {
      newArray.push({ ...element[key] });
    }
    return newArray;
  }

  openQaList(qa) {
    switch (qa.type) {
      case QAType.pill:
        this.openMessage({ player_message_id: qa.player_message_id });
        break;

      default:
        this.openQa(qa);
        break;
    }
  }

  openMessage(message) {
    this.apiMessageService
      .getMessageDetails({ playerMessageId: message.player_message_id })
      .subscribe((messageDetails) => {
        const messageType = handleMessageDetailsType(messageDetails);
        const eventDialog =
          this.messagesService.handleMessageDetailsComponent(messageType);
        const messageOpenDetailsData =
          this.getMessageOpenDetailsData(messageDetails);

        this.eventEmitterDialogsService.emitter.emit({
          name: eventDialog,
          config: {
            data: messageOpenDetailsData,
            disableClose: true
          },
          callback: (event: MessageEventFromDetails)=>{
              this.getAllQaList();
            }
        });
      });
  }

  getMessageOpenDetailsData(messageDetails): MessageOpenDetailsData {
    return {
      message: messageDetails,
      fromList: true
    };
  }

  openQa(qa) {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA.QA_LIST,
      config: {
        data: {
          location: 0,
          qa: qa,
          index: this.allQa.indexOf(qa.qa_id),
          activeQaId: qa.qa_id,
          isOpenByQaListView: true,
          isShowBackButton: true
        }
      }
    });
  }

  changeFilter(filter) {
    this.activeFilter.value = filter.type;
    this.clearNotification(filter.type);
  }

  clearNotification(type: QAType) {
    switch (type) {
      case QAType.pill:
        this.notificationsService.setNotificationReaded("pill").subscribe();
        break;
      case QAType.quiz:
        this.notificationsService.setNotificationReaded("qa").subscribe();
        break;
      case QAType.survey:
        this.notificationsService.setNotificationReaded("survey").subscribe();
        break;
    }
  }
}
function findSubscriptionAndRemoveIt() {
  throw new Error("Function not implemented.");
}
