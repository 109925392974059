<div
  class="global-dialog ow-dialog window-a primary no-transparent"
  [ngClass]="'type-' + message?.type"
>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <div class="title" *ngIf="message?.type === MESSAGE_TYPE_ID.NORMAL">
      {{ 'messages.message' | myTranslate }}
    </div>
  </div>

  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="closeOrCloseAll()"
  ></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button
    [preventDefault]="true"
    (clicked)="close()"
    *ngIf="messageOpenDetailsData.fromList"
  ></m-ui-back-button>

  <!-- IF MESSAGE EXIST -->
  <div class="height-100 relative" *ngIf="message">

    <loading></loading>

    <!-- NORMAL MESSAGE -->
    <div class="flex-container loading-opacity">
      <div class="message-info">

        <!-- SENDER IMAGE -->
        <div class="sender-image">
          <img [src]="message.sender_icon + '.png' | asset:'senders/basic'"/>
        </div>

        <div class="message-details">
          <!-- SENDER AND DATE -->
          <p class="sender-and-date">
            {{ message.sender_name }} | {{ message.created_at | date:"y-MM-dd HH:mm" }}
          </p>

          <!-- MESSAGE TITLE -->
          <p class="message-title">
            {{ message.title }}
          </p>
        </div>
      </div>

      <!-- SEPARATOR-->
      <m-ui-separator></m-ui-separator>

      <!-- MESSAGE DESCRIPTION -->
      <perfect-scrollbar scrollGradient>
        <div class="inner-html-description"
             [innerHTML]="message.description | resourceFromString: 'small' | downloadToken | safe:'html'"></div>

        <!-- BUTTONS -->
        <div class="buttons">
          <!-- BUTTON PDF -->
          <a
            *ngIf="REGEX_TAGS.PDF.source"
            [href]="REGEX_TAGS.PDF.source"
            target="_blank"
          >
            <button class="base primary">
              {{ 'messages.message-details-normal.tags.pdf' | myTranslate }}
            </button>
          </a>

          <!-- BUTTON VIDEO -->
          <button
            *ngIf="REGEX_TAGS.YOUTUBE.source || REGEX_TAGS.VIDEO.source"
            class="base primary"
            (click)="openVideo()"
          >
            {{ 'messages.message-details-normal.tags.video' | myTranslate }}
          </button>
        </div>
      </perfect-scrollbar>
    </div>

    <!-- MESSAGE TRASH -->
    <i
      class="fas fa-archive trash-icon"
      *ngIf="!message.deleted && message.deletable"
      (click)="moveToTrash()"
      [matTooltip]="'messages.archives' | myTranslate"
      matTooltipPosition="above"
      matTooltipShowDelay="300"
    ></i>
  </div>

  <ng-container *ngIf="message.qa_info">
    <button-open-qa
      *ngIf="!message['pill_id']"
      [qaInfo]="message.qa_info"
      (click)="setReadMessage()"
    ></button-open-qa>

    <div
      *ngIf="message['pill_id']"
      class="bottom-bar"
    >
      <div class="bar-button base">
        <button
          (click)="openPill(message['base_pill_id'],message['pill_id'])"
          class="base primary"
        >
           {{'message.archives.open-pill' | myTranslate}}
        </button>
      </div>
    </div>
  </ng-container>
</div>
