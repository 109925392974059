<!-- DIALOG EXIT -->
<m-ui-close-button></m-ui-close-button>

<!-- PLAYER BAR -->
<app-hud-player
  [allowHover]="false"
  [changeProfileImage]="true"
  [player]="player"
></app-hud-player>

<!-- EXPERIENCE -->
<span class="experience">
  {{ player.points_balance | NumberWithSpaces }}
  <br>
  ({{'hud.player-level.experience-points' | myTranslate}})
</span>

<div class="name">
  {{ userService.me.username }}
</div>

<div class="separator"></div>

<div class="email">
  {{ playerService.player.email }}
</div>

<div class="buttons">
  <!-- TERMS -->
  <a
    *ngIf="!isDemo"
    [href]="termsUrl"
    target="_blank"
  >
    <button class="base primary">
      {{ 'player-details.terms' | myTranslate }}
    </button>
  </a>

  <!-- CHANGE PASSWORD BUTTON -->
  <m-ui-change-password-button (clicked)="close()"></m-ui-change-password-button>

  <!-- LOGOUT -->
  <span id="logout" (click)="logout()">
    {{ 'player-details.logout' | myTranslate }}
  </span>
</div>
