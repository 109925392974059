import { MenuButtonConfig } from '../interfaces/menu-buttonts-config.interface';

export const CUSTOM_MENU_BUTTONS_CONFIG: MenuButtonConfig[] =
  [
    {
      'type': 'button',
      'place': 'menu-left',
      'order': 1,
      'name': 'missions',
      'gui_notification_type': null,
      'gui_unlocks_button': null,
      'click_event': 'missions',
      'tooltip': 'hud-menu.missions.tooltip',
      'icon': 'mission.svg',
      'show_only_active_me': true,
      'group_name': null,
      'group_variant': null,
      'onlyIsActiveMe': null,
    },
    {
      'type': 'button',
      'place': 'menu-left',
      'order': 2,
      'name': 'messages',
      'gui_notification_type': null,
      'gui_unlocks_button': null,
      'click_event': 'messages',
      'tooltip': 'hud-menu.messages.tooltip',
      'icon': 'message.svg',
      'show_only_active_me': true,
      'group_name': null,
      'group_variant': null,
      'onlyIsActiveMe': null,
    },
    {
      'type': 'button',
      'place': 'menu-left',
      'order': 3,
      'name': 'warehouse',
      'gui_notification_type': null,
      'gui_unlocks_button': null,
      'click_event': 'warehouse',
      'tooltip': 'hud-menu.warehouse.tooltip',
      'icon': 'warehouse.svg',
      'show_only_active_me': true,
      'group_name': null,
      'group_variant': null,
      'onlyIsActiveMe': null,
    },
    {
      'type': 'button',
      'place': 'menu-left',
      'order': 4,
      'name': 'rankings',
      'gui_notification_type': null,
      'gui_unlocks_button': null,
      'click_event': 'rankings',
      'tooltip': 'hud-menu.rankings.tooltip',
      'icon': 'ranking.svg',
      'show_only_active_me': true,
      'group_name': null,
      'group_variant': null,
      'onlyIsActiveMe': null,
    },
  ];
