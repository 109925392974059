import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../core/providers/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiBuildingService {

  constructor(
    private apiService: ApiService,
  ) {
  }

  getBuildingCategories() {
    return this.apiService.get(`building-categories`);
  }
}
