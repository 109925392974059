import { Component, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';

@Component({
  selector: 'hud-tabs',
  templateUrl: './hud-tabs.component.html',
})
export class HudTabsComponent extends AbstractInjectBaseComponent implements OnInit {
  TABS = {
    PLAYER_PROFILE: 'player_profile',
    MISSION_STARS: 'mission_stars',
  };
  activeTab: string;

  ngOnInit() {
    this.setInitTab();
  }

  setInitTab() {
    this.changeTab(this.TABS.MISSION_STARS);
  }

  changeTab(tab: string) {
    this.activeTab = tab;
  }
}
