<div class="global-dialog">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <app-custom-hud-menu
    [isDialog]="true"
    (click)="close()"
  ></app-custom-hud-menu>
</div>
