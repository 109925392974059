import { Component, OnInit } from '@angular/core';
import { AbstractQaFinishComponent } from '../../../../abstract/core/abstract-qa-finish.component';
import { EVENT_DIALOGS_NAMES_QA_CUSTOM } from '../../../../consts/custom/event-dialogs/event-names.const';

@Component({
  selector: 'app-m-qa-finish-custom',
  templateUrl: '../../../core/dialogs/qa-finish/qa-finish.component.html',
})
export class MQaFinishComponent extends AbstractQaFinishComponent implements OnInit {
  ngOnInit() {
    this.setQa();
  }

  openQaList() {
    setTimeout(() => {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_QA_CUSTOM.QA_LIST_CUSTOM,
        config: {
          data: {
            qa: this.qa,
            prevState: this.data.prevState,
            eachQaId: this.data.prevState.eachQaId,
          }
        }
      });
    });
  }
}
