import { Injectable } from '@angular/core';
import { Credentials } from '../interfaces/credentials';
import { ApiService } from '../../../core/providers/api.service';
import { ApiOptions } from '../../../core/interfaces/api-options';
import { ChangePassword } from '../interfaces/change-password';
import { ResetPasswordConfirm, ResetPasswordRequest } from '../interfaces/reset-password';
import { Registration, RegistrationConfirm } from '../interfaces/registration';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state';
import { TokenObject } from '../interfaces/token-object';
import { UtilityActions } from '../../../store/utility';
import { TokenExchangeService } from '../../../core/providers/token-exchange.service';
import {IdentifierService} from '../../game/services/identifier.service';

@Injectable()
export class AuthService {
  logoutUrl: string;

  constructor(
    private apiService: ApiService,
    private store: Store<AppState>,
    // OVERRIDE CORE FILE START
    private identifierService: IdentifierService
    // OVERRIDE CORE FILE END
  ) {
  }

  logout() {
    this.apiService.removeToken();
  }

  doLogin(loginData: Credentials) {
    const options: ApiOptions = {
      body: {
        ...loginData
      },
      sendRequestWithoutToken: true
    };

    return this.apiService.post('auth/login', options);
  }

  changePassword(data: ChangePassword, token?: string) {
    let options: ApiOptions = {
      body: {
        ...data,
      },
    };

    if (token) {
      options = {...options, ...{headers: {'x-token': token}}};
    }

    return this.apiService.post('user/change-password', options);
  }

  resetPasswordRequest(data: ResetPasswordRequest) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true
    };

    return this.apiService.post('password/reset', options);
  }

  resetPasswordConfirm(data: ResetPasswordConfirm) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true
    };

    return this.apiService.post('password/reset/confirm', options);
  }

  registration(data: Registration) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true
    };

    return this.apiService.post('registration', options);
  }

  registrationWrongData(data: Registration) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true
    };

    return this.apiService.post('registration/wrong-data-notification', options);
  }

  registrationConfirm(data: RegistrationConfirm) {
    const options: ApiOptions = {
      body: {
        ...data,
      },
      sendRequestWithoutToken: true
    };

    return this.apiService.post('registration/confirm', options);
  }

  logoutApi() {
    TokenExchangeService.exchangeTimeoutStop();

    return this.apiService.delete('api-token/invalidate')
      .pipe(
        tap(({logout_url}: { logout_url: string }) => {
          this.logoutUrl = logout_url;
        })
      );
  }

  tempTokenExchange({temp_token}): Promise<any> {
    const options = {
      body: {
        temp_token
      },
      sendRequestWithoutToken: true
    };
    return this.apiService.post('auth/temp-token-exchange', options).toPromise();
  }

  reauthorize() {
    return this.apiService.post('auth/reauthorize')
      .pipe(
        tap((tokenObject: TokenObject) => {
          this.store.dispatch(new UtilityActions.SetToken(tokenObject));
        })
      );
  }

  validate() {
    return this.apiService.get('api-token/validate')
      .pipe(
        tap((tokenObject: TokenObject) => {
          this.store.dispatch(new UtilityActions.SetToken(tokenObject));
        })
      );
  }

  // OVERRIDE CORE FILE START
  doClientLogin(loginData: Credentials) {
    const options: ApiOptions = {
      body: {
        ...loginData,
        slug: this.identifierService.getSlug()
      },
      sendRequestWithoutToken: true
    };

    return this.apiService.post('auth/client-login', options);
  }

  removeCompanyIdentifier() {
    localStorage.removeItem('owClientIdentifier');
  }

  checkCompanyIdentifier(identifier: string) {
    if (localStorage.getItem('owClientIdentifier')) {
      const parsed = JSON.parse(localStorage.getItem('owClientIdentifier'));
      return this.store.dispatch(new UtilityActions.SetIdentifier(parsed));
    }
  }
  // OVERRIDE CORE FILE END
}

