import { BoardTileCore } from '../core/BoardTileCore.class';
import { getTileHoverAnchor, getTileHoverTextureName, getTileTypePolygon } from '../../utils/board.helper';
import {
  BOARD_TILE_ALPHA, BOARD_TILE_WIDTH,
  TILE_TYPE_POLYGON,
} from '../../../constants';
import { ISO_OBJECT_DEPTH_VALUE_FACTOR } from '../../../constants/iso.constants';

export class BoardTile extends BoardTileCore {

  enableInteractivity() {
    if (this.hasBuilding && this.playerBuildingData.pixel_perfect) {
      return this.baseSprite.setInteractive({
        cursor: 'pointer',
        pixelPerfect: true
      });
    }

    const polygonForTileType = this.hasBuilding
      ? getTileTypePolygon(this.tileData.tile_type)
      : TILE_TYPE_POLYGON['1x1'];
    const interactiveShape = new Phaser.Geom.Polygon(polygonForTileType as any);
    this.setInteractive({
      cursor: 'pointer',
      hitArea: interactiveShape,
      hitAreaCallback: Phaser.Geom.Polygon.Contains
    });
  }

  createActiveTile() {
    const emptyTileIcon = this.tileData.empty_icon;
    const tileTextureFrame = emptyTileIcon ? emptyTileIcon : getTileHoverTextureName(this.tileData.tile_type);
    this.baseSprite = this.scene.add.image(0, 0, 'map-atlas', tileTextureFrame);
    this.add(this.baseSprite);
    const tileAnchor = getTileHoverAnchor(this.tileData.tile_type);
    if (this.hasBuilding) {
      this.baseSprite.setScale(this.tileData.tile_type.width, this.tileData.tile_type.height);
    }
    this.baseSprite.setOrigin(tileAnchor.x, tileAnchor.y);
    this.baseSprite.alpha = BOARD_TILE_ALPHA;
    this.handleInput();
  }

  calculateDepth() {
    if (this.baseSprite) {
      let correction = 0;
      switch (this.tileData.tile_id) {
        case 124:
          correction = BOARD_TILE_WIDTH * ISO_OBJECT_DEPTH_VALUE_FACTOR;
          break;

        case 146:
          correction = -ISO_OBJECT_DEPTH_VALUE_FACTOR;
          break;

        case 4:
          correction = -400;
          break;

        case 122:
        case 123:
          correction = 100;
          break;
      }

      this.setDepth(this.depthIndex + (this.y + correction) * ISO_OBJECT_DEPTH_VALUE_FACTOR);
    }
  }
}
