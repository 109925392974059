<!-- LOGO -->
<ng-container *ngIf="!guiService.isSplashShow.value">
  <loading></loading>

  <ng-container [ngSwitch]="showTemplate">
    <ng-container *ngSwitchCase="IdentifierTemplates.LOGIN" [ngTemplateOutlet]="loginForm"></ng-container>
    <ng-container *ngSwitchCase="IdentifierTemplates.UNKNOWN" [ngTemplateOutlet]="unknown"></ng-container>
    <ng-container *ngSwitchCase="IdentifierTemplates.CONGRATULATIONS"
                  [ngTemplateOutlet]="congratulations"></ng-container>
  </ng-container>

</ng-container>


<ng-template #loginForm>
  <div class="container identifier loading-opacity" *ngIf="form">
    <div class="logo">
    </div>

    <h5>{{ 'auth.identifier.description' | myTranslate }}</h5>
    <form [formGroup]="form" autocomplete="off">
      <!-- LOGIN FORM -->
      <div
        [class.error]="form.get('identifier').invalid && form.get('identifier').dirty && form.get('identifier').errors || invalidData"
        class="control">
        <label>{{ 'auth.identifier.name' | myTranslate }}</label>
        <input autocomplete="off" class="control-input no-ow-input-directive" formControlName="identifier"
               type="text"/>
      </div>

      <p class="offer-text">{{ 'auth.identifier.offer' | myTranslate }} <a href="https://greentown.oskarwegner.pl/">{{ 'auth.identifier.link' | myTranslate }}</a></p>

      <div class="bottom-bar">
        <div class="bar-button base">
          <button
            type="button"
            (click)="checkIdentifier()"
            [disabled]="form.invalid || isProcessing"
            class="base primary"
          >
            {{ 'auth.identifier.submit' | myTranslate }}
          </button>
        </div>

      </div>
    </form>
  </div>
</ng-template>

<ng-template #unknown>
  <div class="container not-found">
    <div class="logo"></div>
    <p class="title">{{ 'auth.identifier.not-found-title' | myTranslate }}</p>
    <p class="description">{{ 'auth.identifier.not-found-description' | myTranslate }}</p>
    <button class="base primary" (click)="changeTemplate(IdentifierTemplates.LOGIN)">Spróbuj ponownie</button>
  </div>
</ng-template>

<ng-template #congratulations>
  <div class="container congratulations">
    <div class="logo"></div>
    <p>{{ 'auth.identifier.congratulations-title' | myTranslate }}<br>{{ 'auth.identifier.congratulation-description' | myTranslate }}</p>
  </div>
</ng-template>
