export const CUSTOM_TILE_WIDTH = 384;
export const CUSTOM_TILE_HEIGHT = 222;
export const CUSTOM_TILE_MAP_WIDTH = 94;
export const CUSTOM_TILE_MAP_HEIGHT = 94;
export const CUSTOM_BOARD_TILE_SIZE = 1;
export const CUSTOM_BOARD_TILE_WIDTH = CUSTOM_TILE_WIDTH * CUSTOM_BOARD_TILE_SIZE;
export const CUSTOM_BOARD_TILE_HEIGHT = CUSTOM_TILE_HEIGHT * CUSTOM_BOARD_TILE_SIZE;

export const CUSTOM_TILE_TOOLTIP_OFFSET = {};
export const CUSTOM_TILE_HOVER_TYPES = {
  '1x1': 'tile-hover-1.png',
  '2x2': 'tile-hover-1.png',
  '3x2': 'tile-hover-1.png',
  '1x3': 'tile-hover-1.png',
  '3x1': 'tile-hover-1.png',
};
export const CUSTOM_TILE_HOVER_TYPE_ANCHORS = {
  '4x4': {x: 0.5, y: 1}
};
export const CUSTOM_TILE_TYPE_POLYGON = {
  '1x1': `0 0 ${-CUSTOM_BOARD_TILE_WIDTH / 2} ${-CUSTOM_BOARD_TILE_HEIGHT / 2} 0 ${-CUSTOM_BOARD_TILE_HEIGHT} ${CUSTOM_BOARD_TILE_WIDTH / 2} -${CUSTOM_BOARD_TILE_HEIGHT / 2}`,
  '1x3': `0 0 ${-CUSTOM_BOARD_TILE_WIDTH / 2} ${-CUSTOM_BOARD_TILE_HEIGHT / 2} ${CUSTOM_BOARD_TILE_WIDTH} ${-CUSTOM_BOARD_TILE_HEIGHT * 2} ${CUSTOM_BOARD_TILE_WIDTH * 1.5} -${CUSTOM_BOARD_TILE_HEIGHT * 1.5}`,
  '2x2': `0 0 ${-CUSTOM_BOARD_TILE_WIDTH} ${-CUSTOM_BOARD_TILE_HEIGHT} 0 ${-CUSTOM_BOARD_TILE_HEIGHT * 2} ${CUSTOM_BOARD_TILE_WIDTH} -${CUSTOM_BOARD_TILE_HEIGHT}`,
  '3x1': `0 0 ${-CUSTOM_BOARD_TILE_WIDTH * 1.5} ${-CUSTOM_BOARD_TILE_HEIGHT * 1.5} ${-CUSTOM_BOARD_TILE_WIDTH} ${-CUSTOM_BOARD_TILE_HEIGHT * 2} ${CUSTOM_BOARD_TILE_WIDTH / 2} -${CUSTOM_BOARD_TILE_HEIGHT / 2}`,
  '3x2': `0 0 ${-CUSTOM_BOARD_TILE_WIDTH * 1.5} ${-CUSTOM_BOARD_TILE_HEIGHT * 1.5} ${-CUSTOM_BOARD_TILE_WIDTH / 2} ${-CUSTOM_BOARD_TILE_HEIGHT * 2.5} ${CUSTOM_BOARD_TILE_WIDTH} -${CUSTOM_BOARD_TILE_HEIGHT}`,
  '2x1': `0 0 ${-CUSTOM_BOARD_TILE_WIDTH} ${-CUSTOM_BOARD_TILE_HEIGHT} ${-CUSTOM_BOARD_TILE_WIDTH / 2} ${-CUSTOM_BOARD_TILE_HEIGHT * 1.5} ${CUSTOM_BOARD_TILE_WIDTH / 2} -${CUSTOM_BOARD_TILE_HEIGHT / 2}`,
};
export const CUSTOM_TILE_MENU_POSITION_OFFSETS = {};
export const CUSTOM_BUILDINGS_LEVEL_COLOR = {};
export const CUSTOM_CAMP_SCENE_CONFIG_KEY = 'camp';
