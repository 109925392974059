import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './core/guards/auth.guard';
import { PrimaryGuard } from './core/guards/primary.guard';
import { PlayerChooseComponent } from './modules/auth/basic/components/player-choose/player-choose.component';
import { SocketGuard } from './core/guards/socket.guard';
import { ConfirmationDeliveryGuard } from './guards/confirmation-delivery.guard';
import { ShopRestrictedGuard } from './guards/shop-restricted.guard';
import { APP_ROUTING_PATH } from './consts/routing-app.const';
import { CUSTOM_ROUTING_APP_CHILDREN } from './consts/custom/routing-app-children.const';
import { SyncGuard } from './core/guards/sync.guard';
import { ReauthorizeGuard } from './modules/auth/guards/core/reauthorize.guard';
import { InitGuard } from './core/guards/init.guard';
import { MockDialogListComponent } from './modules/mock-dialogs/components/mock-dialog-list/mock-dialog-list.component';
import {AUTH_ROUTING_PATH} from './modules/auth/consts/core/routing-auth.const';

const appRoutes: Routes = [
  {
    path: APP_ROUTING_PATH.AUTH,
    canActivate: [SyncGuard],
    data: {
      syncGuards: [
        InitGuard,
        AuthGuard,
        ReauthorizeGuard,
      ]
    },
    children: [
      ...CUSTOM_ROUTING_APP_CHILDREN,
      {
        path: APP_ROUTING_PATH.PLAYER_CHOOSE,
        component: PlayerChooseComponent,
      },
      {
        path: APP_ROUTING_PATH.GAME,
        canActivate: [SyncGuard],
        data: {
          syncGuards: [
            PrimaryGuard,
            SocketGuard,
            ConfirmationDeliveryGuard,
            ShopRestrictedGuard,
          ]
        },
        loadChildren: () => import('./modules/new-game/module/core/game.module').then(m => m.GameModule),
      },
    ]
  },
  {
    path: '',
    data: {
      syncGuards: [
        InitGuard,
      ]
    },
    pathMatch: 'full',
    redirectTo: AUTH_ROUTING_PATH.CLIENT_IDENTIFIER
  },
  {
    path: 'dialog-list',
    canActivate: [],
    component: MockDialogListComponent
  },
  // OVERRIDE CORE FILE START
  {
    path: '**', redirectTo: AUTH_ROUTING_PATH.CLIENT_IDENTIFIER
  }
  // OVERRIDE CORE FILE END
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
    paramsInheritanceStrategy: 'always',
    enableTracing: false,
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
