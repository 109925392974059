import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { UserService } from '../../modules/user/providers/user.service';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { User } from '../../modules/user/interfaces/user';
import {
  ActionTypes,
  FetchUser,
  FetchUserFailure,
  FetchUserSuccess,
  NullAction,
  UpdateUser,
  UpdateUserCurrenciesBalances,
  UpdateUserDiscount,
  UpdateUserSuccess,
} from './actions';
import { UtilityActions } from '../utility';
import { CurrencyBalanceDetails } from '../../core/interfaces/currency';
import { CurrencyService } from '../../core/providers/currency.service';
import { AppState } from '../state';
import * as R from 'ramda';
import { omitErrorResponseHelper } from '../../core/helpers/omit-error-response.helper';
import {IdentifierService} from '../../modules/game/services/identifier.service';

@Injectable()
export class UserEffects {

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private userService: UserService,
    private currencyService: CurrencyService,
    private identifierService: IdentifierService
  ) {
  }

  @Effect()
  $fetchBoard: Observable<any | Action> = this.actions$
    .pipe(
      ofType(ActionTypes.FETCH_USER, ActionTypes.UPDATE_USER),
      mergeMap((action: FetchUser | UpdateUser) => {
        if (action.payload) {
          this.handlerUserBeforeSave(<User>action.payload);
          return of();
        } else {
          return this.fetchUser();
        }
      })
    );

  fetchUser() {
    return this.userService.getMe()
      .pipe(
        tap((user: User) => {
          this.handlerUserBeforeSave(user);
        }),
        mergeMap((user: User) => {
          return [
            new UtilityActions.UpdateMePlayerId(
              {
                playerId: user.selected_player_id
              }
            ),
            new FetchUserSuccess(user),
          ]
        }),
        catchError((error: any) => {
          return of(new FetchUserFailure(omitErrorResponseHelper(error)));
        })
      );
  }

  @Effect()
  $updateUserCurrenciesBalances: Observable<Action> = this.actions$
    .pipe(
      ofType(ActionTypes.UPDATE_USER_CURRENCY_BALANCES),
      mergeMap((action: UpdateUserCurrenciesBalances) => {
        return this.updateUserCurrenciesBalances(action.payload);
      })
    );

  updateUserCurrenciesBalances(balances) {
    return of(this.userService.me)
      .pipe(
        tap((user: User) => {
          this.handlerUserBeforeSave({
            ...user,
            currency_balances: balances
          });
        }),
        mergeMap(() => {
          return of(new NullAction());
        })
      );
  }

  handlerUserBeforeSave(user: User) {
    window['storeUser'] = user;
    let userClone = R.clone({ ...user });
    userClone.currency_balances = <CurrencyBalanceDetails[]>this.currencyService.getCurrencyDefinitions(userClone.currency_balances);
    this.userService.me = userClone;
    // OVERRIDE CORE FILE START
    if (userClone?.client_info) {
      this.identifierService.setIdentifierToLocalStorage(user?.client_info);
    }
    // OVERRIDE CORE FILE END
    this.store.dispatch(new UpdateUserSuccess(userClone));
  }

  @Effect()
  $updateUserDiscount: Observable<Action> = this.actions$
    .pipe(
      ofType(ActionTypes.UPDATE_USER_DISCOUNT),
      mergeMap((action: UpdateUserDiscount) => {
        return this.updateUserDiscount(action.payload);
      })
    );

  updateUserDiscount(currentDiscountValue: number) {
    return of(this.userService.me)
      .pipe(
        tap((user: User) => {
          this.handlerUserBeforeSave({
            ...user,
            current_discount_value: currentDiscountValue,
          });
        }),
        mergeMap(() => {
          return of(new NullAction());
        })
      );
  }
}
