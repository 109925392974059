import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractBuildingsListComponent } from '../../../../abstract/core/abstract-buildings-list.component';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';
import { BuildingConfig } from '../../../../../../game-engine/interfaces/building-config';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { ApiBuildingService } from '../../../../api/custom/services/api-building.service';
import { select } from '@ngrx/store';
import { selectGameBoardTile } from '../../../../../../../../store/game/selectors';
import { filter, take } from 'rxjs/operators';
import * as R from 'ramda';
import { BuildingCategoriesInfoComponent } from '../building-categories-info/building-categories-info.component';

@Component({
  selector: 'app-m-buildings-list-custom',
  templateUrl: './m-buildings-list.component.html',

})
export class MBuildingsListComponent extends AbstractBuildingsListComponent implements OnInit, OnDestroy {
  @OwInject(ApiBuildingService) apiBuildingService: ApiBuildingService;

  allBuildingCategories: any[];
  buildings: BuildingExtend[];

  ORDERS = {
    DEFAULT: 1,
    CATEGORY: 2,
    PLAYER_LEVEL: 3,
    GROUP_LIMIT: 4,
  };

  ngOnInit() {
    this.getCategories();
    this.subscribePlayer();
    this.subscribeBoardTile();
  }

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameBoardTile, {playerTileId: this.data.playerTileId}),
        filter(state => !!state),
        take(1),
      )
      .subscribe((tile) => {
        this.tile = tile;
        this.getCategories();
      });
  }

  getBuildingsByPlayerTileId() {
    this.clearSliderActiveIndex();
    this.buildingsService.getBuildingsByPlayerTileId(this.data.playerTileId)
      .subscribe((buildings: BuildingConfig[]) => {
        this.buildings = buildings.map((building) => {
          building = this.setOrderGroup(building);
          building = this.getCurrenciesBalance(building);
          building = this.setBuildPricesDisplay(building);
          building['category_details'] = this.getCategoryDetails(building.category_id);
          return building;
        });
        this.setOrders();
        this.setSecondItem();
        this.checkEmpty();
      });
  }

  setOrderGroup(building: BuildingConfig): BuildingConfig {
    building.orderInList = this.ORDERS.CATEGORY;

    if (building.required_level && building.required_level > this.player.level) {
      building.orderInList = this.ORDERS.PLAYER_LEVEL;
    }

    if (building.group_count >= building.group_limit) {
      building.orderInList = this.ORDERS.GROUP_LIMIT;
    }

    return building;
  }

  setOrders() {
    const sortedBuildings = [];

    Object.keys(this.ORDERS).forEach((key) => {
      const order = this.ORDERS[key];

      const tmpBuildings = this.buildings
        .filter((building) => building.orderInList === order)
        .sort((a, b) => b.name.localeCompare(a.name));

      switch (order) {
        case this.ORDERS.CATEGORY:
          tmpBuildings
            .sort((a, b) => a['category_details'].id > b['category_details'].id ? 1 : -1);
          break;

        case this.ORDERS.PLAYER_LEVEL:
          tmpBuildings
            .sort((a, b) => a.required_level > b.required_level ? 1 : -1);
          break;
      }
      // sorting by category_id
      sortedBuildings.push(...tmpBuildings);
    });
    sortedBuildings.sort((first, second) => {
      if (first.category_id > second.category_id) {
        return 1;
      }
      if (first.category_id < second.category_id) {
        return -1;
      }
        return 0;
    });
    // next, sorting by name
    sortedBuildings.sort((first, second) => {
      if (first.category_id !== second.category_id) {
        return 0;
      }
      const secondName = second.name.split(' ').join('');
      const firstName = first.name.split(' ').join('');
      return firstName.localeCompare(secondName);
    });
    this.buildings = sortedBuildings;
  }

  getCategories() {
    this.apiBuildingService.getBuildingCategories()
      .subscribe((r) => {
        this.allBuildingCategories = r;
        this.getBuildingsByPlayerTileId();
      });
  }

  getCategoryDetails(categoryId) {
    const cat = this.allBuildingCategories.find(c => c.id == categoryId);
    return cat || null;
  }

  openLegend() {
   const items = R.clone(this.allBuildingCategories);

   items.map((c) => {
     c.path = 'ui';
     c.icon = c.icon + '.png';
   });

    this.dialogService.open(BuildingCategoriesInfoComponent, {
      data: {
        items: items,
      }
    });
  }

  ngOnDestroy(): void {
    unsubscribeObject(this.subs);
  }
}

export interface BuildingExtend extends BuildingConfig {
  category_details?: {
    icon?: string;
    id?: string;
    name?: string;
    order?: number;
  };
}
