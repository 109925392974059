import { Component } from '@angular/core';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { AssetsService } from '../../../../../../../core/providers/assets.service';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { DialogService } from '../../../../../../shared/providers/dialog.service';
import { EventEmitterDialogsService } from '../../../../../../../core/services/core/event-emitter-dialogs.service';
import { EVENT_DIALOGS_NAMES_QA_CUSTOM } from '../../../../qa/consts/custom/event-dialogs/event-names.const';
import { PlayerService } from '../../../../../../player/providers/player.service';
import { NotificationsService } from '../../../../../services/notifications.service';
import { NOTIFICATION_TYPES } from '../../../const/custom/notification-types.const';

@Component({
  selector: 'app-hud-knowledge',
  templateUrl: './hud-knowledge.component.html',
})
export class HudKnowledgeComponent extends AbstractInjectBaseComponent {
  @OwInject(AssetsService) assetsService: AssetsService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(NotificationsService) notificationsService: NotificationsService;

  NOTIFICATION_TYPES = NOTIFICATION_TYPES;

  openQaList() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA_CUSTOM.QA_LIST_CUSTOM,
    });
  }
}
