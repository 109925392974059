<ng-container *ngIf="player && user">

  <ng-container *ngFor="let guiItem of this.guiItems">
    <ng-container
      *ngIf="guiItem.player_island_id === gameService.lastPlayerIslandId || guiItem.player_island_id === null">
      <ng-container
        *ngIf="guiItem.currency_id && (guiItem.type === 'user' ? playerService.isActiveMe : true)"
        [ngTemplateOutletContext]="{guiItem: guiItem}"
        [ngTemplateOutlet]="currencyTpl"
      ></ng-container>
      <ng-container
        *ngIf="guiItem.product_id && (guiItem.type === 'user' ? playerService.isActiveMe : true)"
        [ngTemplateOutletContext]="{guiItem: guiItem}"
        [ngTemplateOutlet]="productTpl"
      ></ng-container>
    </ng-container>

    <!--CURRENCY-->
    <ng-template
      #currencyTpl
      let-guiItem="guiItem">
      <button
        #tooltip="ngbTooltip"
        (click)="openHistoryTransaction(guiItem.currency, tooltip)"
        (mousedown)="tooltip.open()"
        [ngbTooltip]="guiItem.tooltip && tooltipTpl"
        [class.initial-cursor]="!guiItem?.show_currency_history"
        class="bar"
        container="body"
        placement="left"
        tooltipClass="delayed"
      >
        <div
          [style.background-image]="'url('+(guiItem.icon + '.png' | asset:'currency/hud') +')'"
          class="icon"
        ></div>
        {{ guiItem.resourceBalance | NumberWithSpaces }}
      </button>
    </ng-template>

    <!--PRODUCTS-->
    <ng-template
      #productTpl
      let-guiItem="guiItem"
    >
      <button
        #tooltip="ngbTooltip"
        (mousedown)="tooltip.open()"
        [ngbTooltip]="guiItem.tooltip && tooltipTpl"
        [class.initial-cursor]="true"
        class="bar"
        container="body"
        placement="left"
        tooltipClass="delayed"
      >
        <div
          [style.background-image]="'url('+(guiItem.icon + '.png' | asset:'product/hud') +')'"
          class="icon"
        ></div>
        {{ guiItem?.resourceBalance | NumberWithSpaces }}
      </button>
    </ng-template>

    <ng-template #tooltipTpl>
      <div
        style="font-weight: normal"
        [innerHTML]="guiItem.tooltip"
      ></div>
    </ng-template>
  </ng-container>
</ng-container>
