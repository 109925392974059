<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <div class="title">
      {{'hud.neighbourhood.title' | myTranslate}}
    </div>
  </div>

  <ng-container *ngIf="buildingDetails">
    <div class="parameters-container loading-opacity">
      <table *ngIf="buildingDetails['neighbourhood']?.length">
        <thead>
        <tr>
          <th></th>
          <th></th>
          <th>{{'hud.neighbourhood.buildings' | myTranslate}}</th>
          <th>{{'hud.neighbourhood.welfare' | myTranslate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let parameter of buildingDetails['neighbourhood']">
          <td>
            <img class="icon" [src]="parameter.category_icon + '.png' | asset:'ui'" />
          </td>
          <td>
            {{ parameter.category_name }}
          </td>
          <td>
            <div
              class="count-buildings"
              [class.max]="parameter.current_buildings === parameter.max_buildings"
            >
              {{ parameter.current_buildings }}/{{ parameter.max_buildings }}
            </div>
          </td>
          <td>
            {{ parameter.current_bonus }}/{{ parameter.max_bonus }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td>{{'hud.neighbourhood.summary' | myTranslate}}</td>
          <td></td>
          <td>
            {{ neighbourhoodSums.current_bonus }}/{{ neighbourhoodSums.max_bonus }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="neighbourhoodSums" class="bottom-description">
      <ng-container *ngIf="neighbourhoodSums.current_bonus === neighbourhoodSums.max_bonus; else notMaxTpl">
        {{'hud.neighbourhood.congratulations' | myTranslate}}
      </ng-container>
      <ng-template #notMaxTpl>
        {{'hud.neighbourhood.hint' | myTranslate}}
      </ng-template>
    </div>
  </ng-container>
</div>
