import { Component, OnInit } from '@angular/core';
import {AbstractInjectBaseComponent} from '../../../../../../core/abstracts/abstract-inject-base.component';
import {OwInject} from '../../../../../../core/decorators/ow-inject.decorator';
import {GuiService} from '../../../../../game/services/gui.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/state';
import {UtilityActions} from '../../../../../../store/utility';
import {PlayerService} from '../../../../../player/providers/player.service';
import {Player} from '../../../../../player/interfaces/player';
import {ApiService} from '../../../../../../core/providers/api.service';
import {HttpClient} from '@angular/common/http';
import {getCookie, removeCookie, setCookie} from '../../../../../../core/utility/cookie';
import {SetIdentifier} from '../../../../../../store/utility/actions';
import {Router} from '@angular/router';
import {getToken} from '../../../../../../core/utility/token';
import {APP_ROUTING_PATH} from '../../../../../../consts/routing-app.const';
import {Identifier, IdentifierService} from '../../../../../game/services/identifier.service';

enum IdentifierTemplates {
  LOGIN,
  UNKNOWN,
  CONGRATULATIONS
}

@Component({
  selector: 'app-identifier',
  templateUrl: './identifier.component.html',

})
export class IdentifierComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(GuiService) guiService: GuiService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(HttpClient) httpClient: HttpClient;
  @OwInject(Router) router: Router;
  @OwInject(IdentifierService) identifierService;
  form: FormGroup = null;
  isProcessing = false;
  invalidData = false;
  showTemplate: IdentifierTemplates = 0;

  ngOnInit() {
    this.determineIdentifierAction();

    this.form = new FormGroup({
      identifier: new FormControl('', [Validators.required])
    });
  }

  determineIdentifierAction() {
    const token = getToken().token;
    const identifier = this.identifierService.getIdentifierFromLocalStorage();
    // authorize when there's token only(admin panel case)
    if (token) {
      this.router.navigate(['/auth/player-choose']);
      return;
    }

    // case when someone logged out, but we can figure out the client's slug
    if (!token && identifier) {
      this.router.navigate([`/k/${identifier}`]);
      return;
    }

    // no token and no identifier
    this.identifierService.removeIdentifierFromLocalStorage();
  }

  changeTemplate(templateId: IdentifierTemplates) {
    this.showTemplate = templateId;
  }

  checkIdentifier() {
    this.form.updateValueAndValidity();
    this.invalidData = false;
    this.isProcessing = true;

    this.identifierService.getClientIdentifier(`${this.form.get('identifier').value}`).subscribe((res: Identifier) => {
      if (res.is_game_finished) {
        this.changeTemplate(IdentifierTemplates.CONGRATULATIONS);
        return;
      }

      this.identifierService.setIdentifierToLocalStorage(res);
      this.store.dispatch(new SetIdentifier(JSON.stringify(res)));
      this.router.navigate([`/k/${res.slug}`]);

      this.isProcessing = false;
    }, err => {
      this.invalidData = true;
      this.isProcessing = false;
      switch (err.status) {
        case 404: {
          this.changeTemplate(IdentifierTemplates.UNKNOWN);
          break;
        }
      }
    });
  }

  protected readonly IdentifierTemplates = IdentifierTemplates;
}
