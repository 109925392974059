import {LoginComponent} from '../../custom/basic/components/login/login.component';
import {AUTH_ROUTING_PATH} from '../core/routing-auth.const';
import {ForgotPasswordComponent} from '../../custom/basic/components/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from '../../custom/basic/components/reset-password/reset-password.component';
import {AUTH_ROUTING_ABSOLUTE_PATH} from '../core/routing-auth-absolute.const';
import {RegistrationConfirmComponent} from '../../custom/basic/components/registration-confirm/registration-confirm.component';
import {
  RegistrationInstantComponent
} from "../../custom/basic/components/registration/variant-instant/registration-instant.component";

export const CUSTOM_ROUTING_AUTH_CHILDREN = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: AUTH_ROUTING_ABSOLUTE_PATH.CONFIRM_REGISTRATION, component: RegistrationConfirmComponent,
  },
  {
    path: AUTH_ROUTING_PATH.FORGOT_PASSWORD, component: ForgotPasswordComponent,
  },
  {
    path: AUTH_ROUTING_PATH.PASSWORD_RESET, component: ResetPasswordComponent,
  },
  {
    path: AUTH_ROUTING_PATH.REGISTRATION_INSTANT, component: RegistrationInstantComponent
  }
];
