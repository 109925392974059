import { Component } from '@angular/core';
import { AbstractHudParametersComponent } from '../../../abstract/core/abstract-hud-parameters.component';
import * as R from 'ramda';
import { CITY_CHARACTER_PARAMETERS_ID } from '../../../const/custom/city-character-parameters.const';
import { RankGame } from '../../../../rankings/interfaces/custom/rank-game.interface';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { ApiCoreGameRanksService } from '../../../../rankings/api/custom/services/api-game-ranks.service';
import { EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM } from '../../../../rankings/consts/custom/event-dialogs/event-names.const';
import { DialogService } from '../../../../../../shared/providers/dialog.service';
import { EventEmitterDialogsService } from '../../../../../../../core/services/core/event-emitter-dialogs.service';
import { translate } from '../../../../../../../core/helpers/translate.helper';

@Component({
  selector: 'app-city-character-custom',
  templateUrl: './city-character.component.html',
})
export class CityCharacterComponent extends AbstractHudParametersComponent {
  @OwInject(ApiCoreGameRanksService) apiCoreGameRanksService: ApiCoreGameRanksService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;

  rankGameList: any[];

  ngOnInit() {
    super.ngOnInit();
    this.gameRanks();
  }

  setHudParameters() {
    super.setHudParameters();
    this.filterCityCharacterParameters();
    this.mapParameterToRanks();
  }

  filterCityCharacterParameters() {
    this.parameterBalances = this.parameterBalances.filter((parameter) => {
      return R.contains(parameter.parameter_id, CITY_CHARACTER_PARAMETERS_ID);
    });
  }

  mapParameterToRanks() {
    this.parameterBalances.forEach((parameter) => {
      if (this.rankGameList?.length) {
        parameter['rank'] = this.rankGameList.find(rank => rank.parameters?.character_city_parameter_id === parameter.parameter_id);
      } else {
        parameter['rank'] = null;
      }
    });
  }

  gameRanks() {
    this.apiCoreGameRanksService.getGameRanks({location: 2})
      .subscribe((resp: RankGame[]) => {
        this.rankGameList = resp;
        this.filterCityCharacterRank();
        this.mapParameterToRanks();
      }, () => {
        this.rankGameList = [];
      });
  }

  filterCityCharacterRank() {
    this.rankGameList = this.rankGameList.filter((rank) => {
      return rank.parameters?.character_city_parameter_id;
    });
  }

  openRankDetailsGame(rank: RankGame) {
    if (!rank.last_calculated_at) {
      this.dialogService.openAlert({
        description: translate('hud.city-character.not-ready')
      });
      return;
    }

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_DETAILS_GAME,
      config: {
        data: {
          rankId: rank.rank_edition_id,
        }
      }
    });
  }
}
