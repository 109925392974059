<div
  class="ow-dialog window-d primary no-transparent"
  [ngStyle]="{
    maxWidth: data.style?.maxWidth,
    maxHeight: data.style?.maxHeight,
    height: data.style?.scrollHeight
  }"
>
  <!-- DIALOG EXIT -->
  <m-ui-close-button
    [preventDefault]="true"
    (clicked)="close()"
    *ngIf="!data.buttonClose"
  ></m-ui-close-button>

  <!-- TITLE -->
  <p class="title" *ngIf="data.title">
    {{ data.title }}
  </p>

  <perfect-scrollbar *ngIf="data.isScrollbarOn; else descriptionTpl">
    <ng-container [ngTemplateOutlet]="descriptionTpl"></ng-container>
  </perfect-scrollbar>

  <!-- BUTTON CLOSE -->
  <div class="bottom-bar" *ngIf="data.buttonClose">
    <div class="bar-button base">
      <button class="base primary" (click)="matDialogRef.close(false)">
        {{ data.buttonClose.label }}
      </button>
    </div>
  </div>
</div>

<!-- DESCRIPTION TPL -->
<ng-template #descriptionTpl>
  <p
    class="description"
    [style.text-align]="data.style?.textAlign"
    [style.max-height]="data.style?.descMaxHeight"
    *ngIf="data.description"
    [innerHTML]="data.description | safe:'html'"
  ></p>
</ng-template>
