import { SceneConfigMap } from '../interfaces/scene-config.interface';
import * as R from 'ramda';
import { BASIC_SCENE_BASE_CONFIG } from './basic-scene.base-config';

export const BASIC_SCENE_CUSTOM_CONFIG: SceneConfigMap = {
  default: {
    ...R.clone(BASIC_SCENE_BASE_CONFIG),
    tiledStartOffset: {
      x: 0,
      y: 0
    },
    tiledJsonPrefix: null,
    backgroundImages: [
      {
        filename: 'map-0-0.jpg',
        x: 0,
        y: 0,
        key: 'map-0-0',
        displayWidth: 4096,
        displayHeight: 4096,
      },
      {
        filename: 'map-1-0.jpg',
        x: 4096,
        y: 0,
        key: 'map-1-0',
        displayWidth: 4096,
        displayHeight: 4096,
      },
      {
        filename: 'map-2-0.jpg',
        x: 8192,
        y: 0,
        key: 'map-2-0',
        displayWidth: 1808,
        displayHeight: 4096,
      },
      {
        filename: 'map-0-1.jpg',
        x: 0,
        y: 4096,
        key: 'map-0-1',
        displayWidth: 4096,
        displayHeight: 904,
      },
      {
        filename: 'map-1-1.jpg',
        x: 4096,
        y: 4096,
        key: 'map-1-1',
        displayWidth: 4096,
        displayHeight: 904,
      },
      {
        filename: 'map-2-1.jpg',
        x: 8192,
        y: 4096,
        key: 'map-2-1',
        displayWidth: 1808,
        displayHeight: 904,
      },
    ],
    yAxisSign: 1,
    mapDirectoryName: 'map',
    tiledMapFileName: null,
    islandGroupIdForBoard: 'A',
    boardStartOffset: {
      x: 0,
      y: 0
    },
    tileMenuAtlasDirectory: 'tile-menu',
    cameraBounds: {
      x: 0,
      y: 0,
      width: 10000,
      height: 5000
    },
    other: {
      sceneZoom: {
        max: 0.45,
        min: 0.70
      },
      sceneSize: {
        width: 10000,
        height: 5000
      },
    }
  },
};
