import { Component, OnInit } from '@angular/core';
import { BuildVersionService } from './core/providers/build-version.service';
import { RegisterEventDialogsService } from './core/services/core/register-event-dialogs.service';
import { RoutingService } from './core/services/core/routing.service';
import { ReauthorizeService } from './modules/auth/services/core/reauthorize.service';
import { AndroidService } from './core/providers/android.service';
import {Title} from '@angular/platform-browser';
import {determineSiteLanguage} from './helpers/common.helpers';
import {GAME_CONFIG} from './core/config/custom/_parsed-game.config';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',

})
export class AppComponent implements OnInit {
  constructor(
    private buildVersionService: BuildVersionService,
    private registerEventDialogsService: RegisterEventDialogsService,
    private routingService: RoutingService,
    private reauthorizeService: ReauthorizeService,
    private androidService: AndroidService,
    private title: Title
  ) {
  }

  ngOnInit() {
    this.setSiteTitle();
  }

  // overwrite lang set while building application
  setSiteTitle() {
    const lang = determineSiteLanguage();
    this.title.setTitle(GAME_CONFIG[lang].INDEX.TITLE);
  }
}
