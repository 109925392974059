import { TryUpdatePlayer } from '../../actions';
import { UtilityPlayer } from '../../../utility/interfaces/utility-player';

export function customHandleHasMissionsToCollect(
  {
    action, state, coreValueHasMissionToCollect
  }: { action: TryUpdatePlayer, state: UtilityPlayer, coreValueHasMissionToCollect: boolean }) {
  return state.hasMissionsToCollect ||
    action.payload.missions_to_collect.filter(mission => mission.mission_type === 1 && mission.mission_slot_id !== 1).length > 0;
}
