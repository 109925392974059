import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PlayerSelectors } from '../../../../../../../store/player';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { AppState } from '../../../../../../../store/state';
import { Player } from '../../../../../../player/interfaces/player';
import { HudTabsComponent } from '../../dialogs/hud-tabs/hud-tabs.component';
import { DialogService } from '../../../../../../shared/providers/dialog.service';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { unsubscribeObject } from '../../../../../../../core/utility/unsubscribe-array';

@Component({
  selector: 'app-hud-player-level',
  templateUrl: './hud-player-level.component.html',
})
export class HudPlayerLevelComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(Store) store: Store<AppState>;
  @OwInject(DialogService) dialogService: DialogService;
  @Input() disabled: boolean;
  strokeDashOffset: number;
  valuePerOnePercent = 4.66;

  player: Player;
  subs = {
    player: null,
  };

  ngOnInit() {
    this.subscribePlayer();
  }

  subscribePlayer() {
    this.subs.player = this.store
      .pipe(
        select(PlayerSelectors.selectPlayer),
      )
      .subscribe((player) => {
        this.player = player;
        this.calcPlayerLevelProgress();
      });
  }

  openHudTabs() {
    this.dialogService.open(HudTabsComponent);
  }

  calcPlayerLevelProgress() {
    let strokeDashOffset = 0;
    const levelPercent = this.player.level_percent;
    if (Number.isInteger(levelPercent)) {
      strokeDashOffset = 578 - (levelPercent * this.valuePerOnePercent);
    }
    this.strokeDashOffset = strokeDashOffset;
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
