import { Component } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Alert } from '../../../../../../../../core/interfaces/alert';

@Component({
  selector: 'app-building-categories-info',
  templateUrl: './building-categories-info.component.html',
})
export class BuildingCategoriesInfoComponent extends AbstractInjectBaseComponent {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<BuildingCategoriesInfoComponent>;
  @OwInject(MAT_DIALOG_DATA) data: Categories;

  close(confirm?: boolean) {
    this.matDialogRef.close(confirm);
  }
}

export interface Categories {
  items: Array<{
    icon?: string;
    path?: string;
    name?: string;
  }>;
}
