import { FormGroup } from '@angular/forms';

export function checkPasswordTheSame(group: FormGroup) {
  const password = group.controls.password.value;
  const passwordRepeat = group.controls.passwordRepeat.value;

  if (!(password === passwordRepeat)) {
    group.controls.passwordRepeat.setErrors({ notSame: true });
  }

  return password === passwordRepeat ? null : {notSame: true};
}
