import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { select } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import * as R from 'ramda';
import { Router } from '@angular/router';
import { AbstractHudMenuComponent } from '../../../abstract/core/abstract-hud-menu.component';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { MenuButtonsConfigService } from '../../../../../services/menu-buttons-config.service';
import { GlobalService } from '../../../../../../../core/providers/global.service';
import { GameService } from '../../../../../services/game.service';
import { BoardService } from '../../../../../services/board.service';
import { MappedMenuButtonConfig, MenuButtonConfig } from '../../../../../interfaces/menu-buttonts-config.interface';
import { PlayerSelectors } from '../../../../../../../store/player';
import { unsubscribeObject } from '../../../../../../../core/utility/unsubscribe-array';
import { HudMenuMoreComponent } from '../../dialogs/hud-menu-more/hud-menu-more.component';
import { KnowledgePillComponent } from '../../dialogs/knowledge-pill/knowledge-pill.component';
import { EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM } from '../../../../rankings/consts/custom/event-dialogs/event-names.const';
import {Observable, Subscription} from 'rxjs';
import {UserService} from '../../../../../../user/providers/user.service';

@Component({
  selector: 'app-custom-hud-menu',
  templateUrl: './hud-menu.component.html',
})
export class HudMenuComponent extends AbstractHudMenuComponent implements OnInit, OnDestroy {
  @OwInject(MenuButtonsConfigService) menuButtonsConfigService: MenuButtonsConfigService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(GameService) gameService: GameService;
  @OwInject(BoardService) boardService: BoardService;
  @OwInject(Router) router: Router;
  @OwInject(UserService) userService: UserService;
  @Input() isDialog: boolean;

  menuButtons: MappedMenuButtonConfig[] = [];
  // quick-access for observable && notification (exclamation mark next to the icons)
  menuButtonsObservables: {[key: number]: {subscription: Subscription, showNotification: boolean}} = {};
  snapShotButtons: MappedMenuButtonConfig[] = [];
  otherButtonsIsActive = false;
  groups = [];

  subs = {
    player: null,
  };

  ngOnInit() {
    this.subscribePlayer();
  }

  subscribePlayer() {
    this.subs.player = this.store
      .pipe(
        select(PlayerSelectors.selectPlayer),
      )
      .subscribe(() => {
        this.afterSubscribePlayer();
      });
  }

  setMenu() {
    let buttons = this.menuButtonsConfigService.getButtonsMenuConfig({
        gui_unlocks: this.playerService.player.gui_unlocks,
        place: 'menu-left',
        isDialog: this.isDialog
      }
    );

    buttons = buttons.filter((button) => {
      return button.onlyIsActiveMe ? this.playerService.isActiveMe : true;
    });

    if (this.userService.me?.client_info?.ranks_disabled) {
      buttons = buttons.filter((btn) => {
        return btn['name'].toLowerCase() !== 'rankings'
      })
    }

    if (R.equals(this.snapShotButtons, buttons)) {
      return;
    }
    this.snapShotButtons = buttons;
    this.menuButtons = R.clone(this.snapShotButtons);
    this.setNotification();
    this.otherButtonsIsActive = this.menuButtons.length > 5 && !this.isDialog;
  }

  setNotification() {
    // exclamation mark logic(those next to the icons)
    for (const [index, button] of Object.entries(this.menuButtons)) {
      switch (button.name) {
        case 'missions':
          button['customNotification'] = {
            type: 'async',
          };
          this.menuButtonsObservables[index] = {subscription: null, showNotification: false};
          this.menuButtonsObservables[index].subscription = this.hasMissionsToCollect.subscribe(res => {
            this.menuButtonsObservables[index].showNotification = res;
          });
          break;

        case 'messages':
          button['customNotification'] = {
            type: 'async',
          };
          this.menuButtonsObservables[index] = {subscription: null, showNotification: false};
          this.menuButtonsObservables[index].subscription = this.hasNewMessagesToRead.subscribe(res => {
            this.menuButtonsObservables[index].showNotification = res;
          });
          break;

        case 'warehouse':
          button['customNotification'] = {
            type: 'async',
          };
          this.menuButtonsObservables[index] = {subscription: null, showNotification: false};
          this.menuButtonsObservables[index].subscription = this.newProductsInStorage.subscribe(res => {
            this.menuButtonsObservables[index].showNotification = !!res.length;
          });
          break;
      }
    }
  }

  afterSubscribePlayer() {
    this.setMenu();
  }


  handleMenuButtonEvent(button: MenuButtonConfig) {
    if (button.type === 'group') {
      this.toggleGroupButtons(button);
    } else if (button.type === 'button') {
      switch (button.click_event) {
        case 'messages':
          this.openMessages();
          break;

        case 'missions':
          this.openMissions();
          break;

        case 'warehouse':
          this.openWarehouse();
          break;

        case 'rankings':
          this.openRankList();
          break;
      }
    }
  }

  openRankList() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_LIST,
      config: {
        data: {
          location: 1,
        }
      }
    });
  }

  openPill() {
    this.dialogService.open(KnowledgePillComponent, {
      data: {
        pill_id: 1,
      }
    });
  }

  private toggleGroupButtons(button: MappedMenuButtonConfig) {
    button.group_buttons.forEach(groupBtn => groupBtn.isVisible = !groupBtn.isVisible);
  }

  openHudMenuMore() {
    this.dialogService.open(HudMenuMoreComponent);
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);

    // remove subscription to menuButtonsObservables(for instance - unread_messages, warehouse items, missions etc)
    if (Object.keys(this.menuButtonsObservables).length) {
      for (const btn of Object.values(this.menuButtonsObservables)) {
        btn.subscription.unsubscribe();
      }
    }
  }
}
