<button
  (click)="openQaList()"
  [matTooltipPosition]="'above'"
  [matTooltipShowDelay]="300"
  [matTooltip]="'hud.knowledge.tooltip' | myTranslate"
>
  <ow-object-svg [svgObject]="assetsService.assetsData['/ui/knowledge.svg']"></ow-object-svg>

  <div
    class="icon notification"
    *ngIf="
      notificationsService.hasNotificationByType(NOTIFICATION_TYPES.PILL)
      || notificationsService.hasNotificationByType(NOTIFICATION_TYPES.QA)
      || notificationsService.hasNotificationByType(NOTIFICATION_TYPES.SURVEY)
    "
  ></div>
</button>
