<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <ng-container *ngIf="rank">
    <!-- TOP BAR BASE -->
    <div class="top-bar base">
      <!-- RANK NAME -->
      <div class="title">
        {{ rank.name }}
      </div>
    </div>

    <!-- RANK DETAILS -->
    <div
      class="rank-details-container loading-opacity"
      *ngIf="rankResults"
    >
      <ng-container *ngIf="rankResults.results.length; else noItemsTpl">
        <div class="header">
          <perfect-scrollbar class="height-auto">
            <ranking-game-table
              [columns]="RANK_CONFIG.columns"
              [rankResults]="rankResults"
              [rank]="rank"
              [show]="'header'"
            ></ranking-game-table>
          </perfect-scrollbar>
        </div>

        <!-- RANK RESULTS -->
        <perfect-scrollbar [scrollGradient]="rankResults">
          <ranking-game-table
            [columns]="RANK_CONFIG.columns"
            [rankResults]="rankResults"
            [rank]="rank"
            [show]="'results'"
          ></ranking-game-table>
        </perfect-scrollbar>

        <!-- MY POSITION -->
        <div *ngIf="rankResults.my_position" class="my-position-container">

          <!-- LABEL -->
          <label>{{ 'ranking.details.your-score' | myTranslate }}</label>

          <perfect-scrollbar class="height-auto my-position">
            <ranking-game-table
              [columns]="RANK_CONFIG.columns"
              [rankResults]="rankResults"
              [rank]="rank"
              [show]="'myPosition'"
            ></ranking-game-table>
          </perfect-scrollbar>
        </div>
      </ng-container>
      <ng-template #noItemsTpl>
        <div class="flex-center no-items">
          {{ 'ranking.details.players-not-found' | myTranslate }}
        </div>
      </ng-template>
    </div>
  </ng-container>
</div>
