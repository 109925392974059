import { ISO_OBJECT_DEPTH_VALUE_FACTOR } from '../../../constants/iso.constants';
import { GameService } from '../../../services/game.service';
import { MyScene } from '../core/MyScene';
import { MyGame } from '../core/MyGame';
import {
  isAssetLoadedToPhaserCache,
  isNonInteractiveBuilding,
  isPointerOnCanvas,
  isPointerupOnCanvas,
  isTileBuyable
} from '../../utils/game.helper';
import {
  filterRoadsSides,
  getBuildingAnchor,
  getTileHoverAnchor,
  getTileHoverTextureName,
  getTileTypePolygon,
  hasRoadOnSide
} from '../../utils/board.helper';
import { BoardTileConfig, CrossRoads, RoadSide } from '../../interfaces/board-tile.config';
import { BoardContainer } from '../core/BoardContainer';
import { BoardTileState } from '../../../../../store/game/interfaces/board-tile.state';
import { PlayerBuilding } from '../../interfaces/player-building.config';
import * as R from 'ramda';
import {
  BOARD_TILE_ACTIVE,
  BOARD_TILE_ALPHA,
  BOARD_TILE_BUYABLE,
  BOARD_TILE_WIDTH,
  BOARD_TILE_WITH_BUILDING_ALPHA,
  GAME_EVENTS,
  MAP_ATLAS_NAME,
  PLAYER_BUILDING_STATUS_BUILD_IN_PROGRESS,
  PLAYER_BUILDING_STATUS_BUILT,
  ProductionStatus,
  TILE_MAP_HEIGHT,
  TILE_MAP_WIDTH,
  TileTooltipType
} from '../../../constants';
import {
  extractBuildingImageParametersFromAssetPath,
  isBuildingSpecialIncrease,
  isBuildingWithProbability,
  isSpecial
} from '../../../game-gui/helpers/buildings.helper';
import { IsoSprite } from '../core/IsoSprite';
import { screenToIso } from '../../utils/utils';
import { BASE_ROADS_HEIGHT, BASE_ROADS_WIDTH } from '../../scenes-main/main.constants';
import { TileTooltip } from '../tile-tooltip/custom/TileTooltip';
import { TileHover } from '../tile-hover/custom/TileHover';
import { TileMenu } from '../tile-menu/custom/TileMenu';
import { BuildingLevelLayerCore } from './BuildingLevelLayerCore';
import { GAME_CONFIG } from '../../../../../core/config/custom/_parsed-game.config';
import { GameFeaturesMap } from '../../../constants/game-features';
import {determineSiteLanguage} from '../../../../../helpers/common.helpers';

export class BoardTileCore extends BoardContainer {

  baseSprite: Phaser.GameObjects.Sprite | Phaser.GameObjects.Image;
  game: MyGame;
  gameService: GameService;
  myScene: MyScene;
  playerBuildingData: PlayerBuilding;
  tileData: BoardTileState;
  tileMenu: TileMenu;
  tileHover: TileHover;
  tooltip: TileTooltip;
  baseShader: Phaser.GameObjects.Shader;
  movedToTile: BoardTileCore;
  interiorView: boolean;
  buildingLevelLayer: BuildingLevelLayerCore;
  hasBuilding: boolean;
  roads = [];
  buildingParameters;

  debugGraphics: Phaser.GameObjects.Graphics | Phaser.GameObjects.Sprite;
  debugContainer: Phaser.GameObjects.Container;
  hoverTween: Phaser.Tweens.Tween;

  requirementsTooltip: Phaser.GameObjects.Text;

  constructor(params: BoardTileConfig) {
    super(params);

    if (params.viewMode) {
      this.viewMode = params.viewMode;
    }
    this.myScene = params.scene as MyScene;
    this.game = this.myScene.sys.game as MyGame;
    this.gameService = this.myScene.gameService;
    this.tileData = params.tileData;
    this.playerBuildingData = this.getPlayerBuilding();
    if (this.playerBuildingData) {
      this.hasBuilding = true;
    }
    this.beforeCreate();

    if (this.playerBuildingData) {
      this.handlePlayerBuilding();
    } else {
      this.handleEmptyTile(this.tileData);
    }
    this.processHideObjects();
    this.processShowObjects();
  }

  beforeCreate() {

  }

  openTileMenu() {
    if (this.tileMenu && !this.tileMenu.isDestroyed) {
      this.closeTileMenu();
    } else {
      const menuConfig = R.clone(this.myScene.sceneConfig.tileMenuConfig);
      this.tileMenu = new TileMenu(this, menuConfig);
      this.myScene.topLayer.add(this.tileMenu);
      if (this.myScene.board.isVisibleBuildingsLevelLayer) {
        this.buildingLevelLayer.hide();
      }
    }
    this.myScene.board.tileMenuRef = this.tileMenu;
  }

  closeTileMenu() {
    if (this.tileMenu) {
      if (this.buildingLevelLayer && this.myScene.board.isVisibleBuildingsLevelLayer) {
        this.buildingLevelLayer.show();
      }
      this.tileMenu.destructor();
      this.tileMenu = null;
      this.myScene.board.tileMenuRef = null;
    }

    if (this.tooltip) {
      this.tooltip.show();
    }
  }

  /**
   * Handle tile without building.
   * @param tileData
   */
  handleEmptyTile(tileData: BoardTileState) {
    switch (tileData.state) {
      case BOARD_TILE_ACTIVE:
        this.createActiveTile();
        break;
      case BOARD_TILE_BUYABLE:
        this.createBuyableTile();
        break;
    }
  }

  /**
   * Create tile with active state and.
   */
  createActiveTile() {
    const emptyTileIcon = this.tileData.empty_icon;
    const tileTextureFrame = emptyTileIcon ? emptyTileIcon : getTileHoverTextureName(this.tileData.tile_type);
    this.baseSprite = this.scene.add.image(0, 0, 'map-atlas', tileTextureFrame);
    this.add(this.baseSprite);
    const tileAnchor = getTileHoverAnchor(this.tileData.tile_type);
    this.baseSprite.setScale(this.tileData.tile_type.width, this.tileData.tile_type.height);
    this.baseSprite.setOrigin(tileAnchor.x, tileAnchor.y);
    this.baseSprite.alpha = BOARD_TILE_ALPHA;
    this.handleInput();
  }

  createBuyableTile() {
    this.baseSprite = this.scene.add.image(0, 0, 'map-atlas', 'buyable.png');
    this.add(this.baseSprite);
    const tileAnchor = getTileHoverAnchor(this.tileData.tile_type);
    this.baseSprite.setScale(this.tileData.tile_type.width, this.tileData.tile_type.height);
    this.baseSprite.setOrigin(tileAnchor.x, tileAnchor.y);
    this.baseSprite.alpha = BOARD_TILE_WITH_BUILDING_ALPHA;

    this.handleInput();
  }

  createDebugTile() {
    const tileTextureFrame = getTileHoverTextureName(this.tileData.tile_type);
    this.baseSprite = this.scene.add.image(0, 0, 'map-atlas', tileTextureFrame);
    this.add(this.baseSprite);
    const tileAnchor = getTileHoverAnchor(this.tileData.tile_type);
    this.baseSprite.setOrigin(tileAnchor.x, tileAnchor.y);
    this.baseSprite.alpha = 0.1;
    const text = this.scene.add.text(0, -50, `${this.tileData.state}`);
    this.add(text);
    text.setOrigin(0.5, 1);
  }

  /**
   * Handle tile with player building.
   * Search for building extensions.
   */
  handlePlayerBuilding() {
    if (!this.myScene.sceneConfig.isOrhto) {
      this.processRoads();
    }

    this.baseSprite = this.scene.add.sprite(0, 0, '');
    this.baseSprite.setOrigin(0.5, 1);
    this.add(this.baseSprite);

    let buildingImage = this.gameService.buildingsService.getBuildingImage(this.playerBuildingData, true);
    const buildingImageAsset = this.gameService.buildingsService.getBuildingImageAssetObject(this.playerBuildingData);

    const buildingExtensionsImage = this.gameService.buildingsService.getBuildingExtensions(this.playerBuildingData);
    if (buildingExtensionsImage) {
      const buildingImageParameters = extractBuildingImageParametersFromAssetPath(buildingExtensionsImage.path);

      const sprite = this.scene.add.sprite(-buildingImageParameters.x, -buildingImageParameters.y, null);
      if (buildingExtensionsImage.path.includes('infrastructure_warehouse')) {
        sprite.x = buildingImageParameters.x;
        sprite.y = -buildingImageParameters.y;
      }
      this.add(sprite);
      sprite.setOrigin(0);
      // sprite.setBlendMode(Phaser.BlendModes.COLOR_BURN);
      sprite.alpha = 0;

      // this.scene.add.tween({
      //   targets: sprite,
      //   duration: 600,
      //   alpha: 1,
      //   ease: 'Power2',
      //   yoyo: true,
      //   hold: 500,
      //   repeat: -1
      // });

      switch (buildingImageParameters.animation) {
        case 'glow':
          setInterval(() => {
            let targetAlpha = 0;
            if (sprite.alpha === 0) {
              targetAlpha = 1;
            }

            this.scene.add.tween({
              targets: sprite,
              duration: 200,
              alpha: targetAlpha,
              ease: 'Power2',
            });
          }, 1000);
          break;

        case 'up':
          const rideElevator = (y: number, delay: number) => {
            this.scene.add.tween({
              targets: sprite,
              y: -y,
              duration: 5000 * (y / 180),
              ease: 'Expo.easeInOut',
              onComplete: () => {
                setTimeout(() => {
                  rideElevator(Math.random() * 4 * (180 - 139) / 5 + 139, Math.random() * 2);
                }, delay);
              }
            })
            ;
          };

          rideElevator(180, 1000);
          break;
      }

      this.gameService.loadGameImages(this.myScene, [buildingExtensionsImage.path])
        .subscribe(() => {
          setTimeout(() => {
            sprite.setTexture(buildingExtensionsImage.path);
            sprite.alpha = BOARD_TILE_WITH_BUILDING_ALPHA;
            // (this.scene as MainScene).customPipeline.setFloat2('u_resolution', this.baseSprite.width, this.baseSprite.height);
            // this.baseSprite.setPipeline(PIPELINE_NAME);
          }, 300);
        });

    }

    const constructionImage = this.handleConstructionImage(this.playerBuildingData);
    buildingImage = constructionImage ? constructionImage : buildingImage;

    if (buildingImage) {
      this.setTileImage(buildingImage);
      let buildingAnchor = getBuildingAnchor(this.playerBuildingData.icon, this.playerBuildingData.level);
      if (!buildingAnchor) {
        buildingAnchor = getTileHoverAnchor(this.tileData.tile_type);
      }
      this.baseSprite.setOrigin(buildingAnchor.x, buildingAnchor.y);
    }
    this.handleInput();
    this.handleBuildingStatus();
  }

  /**
   * Handle construction image type.
   * @param buildingData
   */
  handleConstructionImage(buildingData) {
    let constructionImage;

    if (this.isBuildInProgress() && buildingData.level === 1) {
      const upgradingFromBuilding = buildingData.upgrading_from_building;
      if (upgradingFromBuilding) {
        constructionImage = this.gameService.buildingsService.getBuildingImage(upgradingFromBuilding, true);
      } else {
        constructionImage = 'construction';
      }
    }
    return constructionImage;
  }

  handleGlobalEvents() {
    this.gameService.globalService.globalEvents.subscribe(event => {
      if (this && this.myScene.board.isVisibleBuildingsLevelLayer) {
        switch (event.name) {
          case GAME_EVENTS.BUILDING_MOVE:
            if (this.tileData.player_tile_id === event.value && this.buildingLevelLayer) {
              this.buildingLevelLayer.hide();
            }
            break;
          case GAME_EVENTS.BUILDING_INFO:
            if (this.tileData.player_tile_id === event.value && this.buildingLevelLayer) {
              this.buildingLevelLayer.show();
            }
            break;
          case GAME_EVENTS.BUILDING_UPGRADE:
            if (this.tileData.player_tile_id === event.value && this.buildingLevelLayer) {
              this.buildingLevelLayer.show();
            }
            break;
          case GAME_EVENTS.BUILDING_SPECIAL_UPGRADE:
            if (this.tileData.player_tile_id === event.value && this.buildingLevelLayer) {
              this.buildingLevelLayer.show();
            }
            break;
          case GAME_EVENTS.BUILDING_UPGRADE_SPECIAL_INCREASE:
            if (this.tileData.player_tile_id === event.value) {
              this.buildingLevelLayer.show();
            }
            break;
          case GAME_EVENTS.BUILDING_UPGRADE_WITH_PROBABILITY:
            if (this.tileData.player_tile_id === event.value) {
              this.buildingLevelLayer.show();
            }
            break;
        }
      }
    });
  }

  isBuildInProgress() {
    return this.hasBuilding && this.playerBuildingData.status === PLAYER_BUILDING_STATUS_BUILD_IN_PROGRESS;
  }

  hasMission() {
    return Boolean(this.playerBuildingData.player_mission);
  }

  /**
   * Check for building versions in building images and return random if exists.
   * @param buildingImage
   */
  handleBuildingVersions(buildingImage: string) {
    const buildingImageVersions = this.gameService.buildingsService.getBuildingImageVersions(
      extractBuildingImageParametersFromAssetPath(buildingImage)
    );
    if (buildingImageVersions.length > 1) {
      return buildingImageVersions[Math.floor(Math.random() * buildingImageVersions.length)];
    }
  }

  destroyTooltip() {
    this.tooltip.niceDestroy();
    this.tooltip = null;
  }

  destroyMenu() {
    if (this.tileMenu) {
      this.tileMenu.destructor();
      this.tileMenu = null;
    }
  }

  destroyTile() {
    if (this.buildingLevelLayer && !this.buildingLevelLayer.isDestroyed) {
      this.buildingLevelLayer.destructor();
      this.buildingLevelLayer = null;
    }
    this.tooltip && this.tooltip.niceDestroy();
    this.destroyRoads();
    this.processHideObjects(true);
    // this.menu && this.menu.niceDestroy();
    // this.waterAttractionSprite && this.waterAttractionSprite.destroy();
    this.destroy();
  }

  isBuyable() {
    return isTileBuyable(this.tileData.state);
  }

  enableInteractivity() {
    if (this.hasBuilding && this.playerBuildingData.pixel_perfect) {
      return this.baseSprite.setInteractive({
        cursor: 'pointer',
        pixelPerfect: true
      });
    } else if (!this.hasBuilding && this.tileData['empty_icon_pixel_perfect']) {
      return this.baseSprite.setInteractive({
        cursor: 'pointer',
        pixelPerfect: true
      });
    }

    const polygonForTileType = getTileTypePolygon(this.tileData.tile_type);
    const interactiveShape = new Phaser.Geom.Polygon(polygonForTileType as any);
    this.setInteractive({
      cursor: 'pointer',
      hitArea: interactiveShape,
      hitAreaCallback: Phaser.Geom.Polygon.Contains
    });
  }

  /**
   * Enable interactive sprite and create hitArea shape.
   */
  handleInput() {
    if (!this.gameService.playerService.isActiveMe || !GameFeaturesMap.boardTileInput) {
      return;
    }

    this.enableInteractivity();
    const sprite =
      (this.hasBuilding && this.playerBuildingData.pixel_perfect)
      || (!this.hasBuilding && this.tileData['empty_icon_pixel_perfect'])
        ? this.baseSprite
        : this;
    sprite.on('pointerover', (pointer) => {

      const isMobile: boolean = this.gameService.deviceDetector.isMobile();
      if (!isPointerOnCanvas(pointer, this.myScene)) {
        return;
      }

      // fix for the bug that was firing pointerover event after clicked on a tile covered by a dialog
      if (pointer.isDown && !isPointerupOnCanvas(pointer, this.myScene)) {
        return;
      }

      if (this.hasBuilding || isTileBuyable(this.tileData.state)) {
        if (pointer.event.altKey && this.game.debug) {
          this.showInterior();
        } else {
          if (!this.myScene.board.movingTile) {
            this.setTint();
            if (!this.tileMenu || !(this.tileMenu && this.tileMenu.visible)) {
              if (!this.tooltip) {
                !isMobile && this.myScene.board.tileHover.show(this);
                if (this.buildingLevelLayer && this.myScene.board.isVisibleBuildingsLevelLayer) {
                  this.buildingLevelLayer.hide();
                }
              }
            }
          }
        }
      } else {
        if (this.myScene.board.movingTile) {
          this.myScene.board.moveBuildingPreviewIfPossible(this);
        } else if (!this.hoverTween || (this.hoverTween && !this.hoverTween.isPlaying())) {
          this.baseSprite.alpha = BOARD_TILE_ALPHA + 0.2;
          this.hoverTween = this.scene.add.tween({
            targets: this.baseSprite,
            y: -10,
            duration: 300,
            ease: 'Power2',
            yoyo: true
          });
        }
      }
      if ((!this.tileMenu || !this.tileMenu.visible) && this.buildingLevelLayer && this.myScene.board.isVisibleBuildingsLevelLayer) {
        this.buildingLevelLayer.hide();
      }
    });

    sprite.on('pointerout', () => {
      if (this.hasBuilding || isTileBuyable(this.tileData.state)) {
        this.baseSprite.clearTint();
        this.myScene.board.tileHover.hide();
        if (this.interiorView) {
          this.showInterior();
        }
      } else {
        this.baseSprite.alpha = BOARD_TILE_ALPHA;
      }
      if (this.requirementsTooltip) {
        this.requirementsTooltip.destroy();
      }
      if ((!this.tileMenu || !this.tileMenu.visible) && this.buildingLevelLayer && this.myScene.board.isVisibleBuildingsLevelLayer) {
        this.buildingLevelLayer.show();
      }
    });

    sprite.on('pointerup', (pointer: Phaser.Input.Pointer) => {

      if (!isPointerupOnCanvas(pointer, this.myScene)) {
        return;
      }

      // This method will execute and if return anything else than undefined then stop Core flow.
      if (this.beforeClickHandle(pointer) !== undefined) {
        return;
      }

      if (this.hasBuilding && isNonInteractiveBuilding(this.playerBuildingData.group_type)) {
        return;
      }

      console.log(this.tileData);
      console.log(this);

      if (!this.myScene.shouldCancelClickEvent(pointer.position)) {
        if (this.myScene.board.movingTile) {
          // this.handleBuildingMoveConfirm(); moved to BuildingMovePreview menu
        } else if (this.tooltip && this.tooltip.isType(TileTooltipType.COLLECT)) {
          this.autoprodCollect();
        } else if (this.tooltip && this.tooltip.isType(TileTooltipType.PRODUCTION_FINISHED)) {
          this.productionCollect();
        } else if (this.tooltip && this.tooltip.isType(TileTooltipType.UPGRADE)) {
          return;
        } else if (this.tooltip && this.tooltip.isType(TileTooltipType.MISSION)) {
          this.gameService.emitGameEvent({
            name: GAME_EVENTS.OPEN_PLAYER_MISSION,
            value: this.tileData.player_building.player_mission
          });
        } else {
          if (this.playerBuildingData) {
            if (this.isBuildInProgress() && this.playerBuildingData.level === 1) {
              return true;
            }

            if (isBuildingSpecialIncrease(this.playerBuildingData.group_type)) {
              if (this.isBuildInProgress()) { // return if building in progress and is special type
                return true;
              }
              this.gameService.emitGameEvent({
                name: GAME_EVENTS.BUILDING_UPGRADE_SPECIAL_INCREASE,
                value: this.tileData.player_tile_id
              });
            } else if (isBuildingWithProbability(this.playerBuildingData.group_type)) {
              if (this.isBuildInProgress()) { // return if building in progress and is special type
                return true;
              }
              this.gameService.emitGameEvent({
                name: GAME_EVENTS.BUILDING_UPGRADE_WITH_PROBABILITY,
                value: this.tileData.player_tile_id
              });
            } else if (isSpecial(this.playerBuildingData.group_type)) {
              if (this.isBuildInProgress()) { // return if building in progress and is special type
                return true;
              }
              this.gameService.emitGameEvent({
                name: GAME_EVENTS.BUILDING_SPECIAL_UPGRADE,
                value: this.tileData.player_tile_id
              });
            } else {
              this.myScene.board.tileHover.hide();
              if (this.tooltip) {
                this.tooltip.hide();
              }
              this.gameService.emitGameEvent({
                name: GAME_EVENTS.OPEN_TILE_MENU,
                value: this
              });
            }
          } else {
            if (this.tileData.state) {
              this.gameService.emitGameEvent({
                name: GAME_EVENTS.TILE_CLICK,
                value: this.tileData.tile_id
              });
            }
          }
        }
      }
    });
  }

  /**
   * Set tile image.
   * If texture not found in cache set sprite alpha to 0 to avoid showing missing texture and set to 1 after texture loaded and set.
   * @param buildingImage
   */
  setTileImage(buildingImage: string) {
    return new Promise((resolve, reject) => {
      if (isAssetLoadedToPhaserCache(buildingImage, this.game.textures.getTextureKeys())) {
        this.baseSprite.setTexture(buildingImage);
        resolve(buildingImage);
      } else {
        this.visible = false;
        this.gameService.loadGameImages(this.myScene, [buildingImage])
          .subscribe(() => {
            setTimeout(() => {
              this.baseSprite.setTexture(buildingImage); // Timeout fix to wait till Phaser prepare texture from image.
              this.visible = true;
              resolve(buildingImage);
              this.calculateDepth();
            }, 500);
          });
      }
    });
  }

  getPlayerBuilding(): PlayerBuilding {
    return R.path(['tileData', 'player_building'], this);
  }

  /**
   * Calculate depth for isometric sorting.
   * Depth value is a sum of depthIndex (received from layer depthIndex: 1, 2, 3... 99 etc) and Y position (reduced to not exceed 1).
   * Correction is used (if tile image is wider than base tile width) to move tile back in sorting order.
   */
  calculateDepth() {
    const zPos = this.tileData.z_pos;
    if (zPos) {
      this.setDepth(zPos);
    } else {
      if (this.baseSprite) {
        const correction = this.baseSprite.width > BOARD_TILE_WIDTH ? this.baseSprite.width / 4 : 0;
        this.setDepth(this.depthIndex + (this.y - correction) * ISO_OBJECT_DEPTH_VALUE_FACTOR);
      }
    }
  }

  /**
   * Render tile hitArea shape for debug.
   */
  debugTileInput() {
    console.log(this.tileData.state);
    if (this.debugGraphics) {
      this.debugGraphics.destroy();
      this.debugGraphics = null;
    } else {
      if (this.hasBuilding && this.playerBuildingData.pixel_perfect) {
        this.createDebugBaseSpriteTileInput();
      } else if (!this.hasBuilding && this.tileData['empty_icon_pixel_perfect']) {
        this.createDebugBaseSpriteTileInput(true);
      } else {
        this.createDebugTileInput();
      }
    }
  }

  createDebugTileInput() {
    if (!this.input) {
      return;
    }
    const graphics = this.scene.add.graphics({fillStyle: {color: 0xff0000}});
    graphics.alpha = 0.5;
    this.add(graphics);
    graphics.fillPoints(this.input.hitArea.points);
    this.debugGraphics = graphics;
  }

  createDebugBaseSpriteTileInput(emptyIcon: boolean = false) {
    if (!this.baseSprite?.input) {
      return;
    }
    const mask = emptyIcon
      ? this.scene.add.sprite(0, 0, MAP_ATLAS_NAME, this.tileData.empty_icon).setTint(0xff0000).setOrigin(0.5, 1)
      : this.scene.add.sprite(0, 0, this.baseSprite.texture.key).setTint(0xff0000).setOrigin(0.5, 1);
    mask.alpha = 0.5;
    this.add(mask);
    this.debugGraphics = mask;
  }

  /**
   * Render some tile information for debug.
   */
  debugTile() {
    if (this.debugContainer) {
      this.debugContainer.destroy();
      this.debugContainer = null;
    } else {
      const container = this.scene.add.container(this.x, this.y);

      let text = `${this.tileData.x_pos} | ${this.tileData.y_pos} \n`;
      if (this.hasBuilding) {
        text += `${this.playerBuildingData.name}(${this.playerBuildingData.level}) \n`;
        text += `id: ${this.playerBuildingData.building_id} pid: ${this.playerBuildingData.player_building_id}\n`;
      }
      text += `type_id: ${this.tileData.tile_type.type_id}\n`;
      text += `szer: ${this.tileData.tile_type.width}, wys: ${this.tileData.tile_type.height}`;

      const buildingText = this.scene.add.text(-100, -200, text, {
        fontSize: '20px',
        align: 'left',
        fontStyle: 'bold'
      });
      buildingText.setOrigin(0, 0);
      buildingText.setStroke(`#000000`, 3);

      container.add(buildingText);
      container.setDepth(99999);
      this.gameService.game.currentScene.topLayer.add(container);
      this.debugContainer = container;
    }
  }

  showInterior() {
    if (this.interiorView) {
      this.setTileImage(this.gameService.buildingsService.getBuildingImage(this.playerBuildingData, true));
      this.interiorView = false;
      return;
    }

    if (this.tileData.tile_type.width !== 1 && this.tileData.tile_type.height !== 1) {
      return;
    }

    this.baseSprite.setTexture('interior');
    this.interiorView = true;
  }

  isBuildingGroup(group: string) {
    return this.playerBuildingData && this.playerBuildingData.group === group;
  }

  autoprodCollect() {
    this.disableInteractive();
    this.gameService.productionService.autoProductionCollect(this.tileData.player_building.player_building_id)
      .subscribe(() => {
          this.tooltip.niceDestroy();
          this.tooltip = null;
          try {
            this.setInteractive();
          } catch (error) {
            console.log(error);
            console.log(this);
          }
        },
        () => {
          this.setInteractive();
        });
  }

  productionCollect() {
    this.disableInteractive();
    this.gameService.productionService.productionCollect(this.tileData.production.player_building_production_id)
      .subscribe(() => {
          this.tooltip.niceDestroy();
        },
        () => {
          this.setInteractive();
        });
  }


  createTooltip(type: TileTooltipType) {
    if (GameFeaturesMap.tooltip) {
      this.tooltip = new TileTooltip(this.myScene, this, type);
    }
  }

  handleBuildingStatus() {
    if (!this.gameService.playerService.isActiveMe) {
      return;
    }

    if (this.hasMission()) {
      this.createTooltip(TileTooltipType.MISSION);
    } else if (this.hasBuilding && this.playerBuildingData.auto_production_amount) {
      this.createTooltip(TileTooltipType.COLLECT);
    } else if (this.hasBuilding && this.isBuildInProgress()) {
      switch (this.playerBuildingData.status) {
        case PLAYER_BUILDING_STATUS_BUILD_IN_PROGRESS:
          this.createTooltip(TileTooltipType.UPGRADE);
          break;
      }
    } else if (this.tileData.production) {
      switch (this.tileData.production.status) {
        case ProductionStatus.InProgress:
          this.createTooltip(TileTooltipType.PRODUCTION);
          break;
        case ProductionStatus.Finished:
          this.createTooltip(TileTooltipType.PRODUCTION_FINISHED);
          break;
      }
    }

    if (
      this.hasBuilding
      && this.playerBuildingData.status === PLAYER_BUILDING_STATUS_BUILT
      && this.playerBuildingData.hide_level_information_on_front === false
      && GAME_CONFIG[determineSiteLanguage()].BUILDING_LEVELS
    ) {
      this.buildingLevelLayer = new BuildingLevelLayerCore(this.myScene, this);
    }
  }

  updateState(data: BoardTileState) {
    this.tileData = data;
    this.playerBuildingData = this.getPlayerBuilding();

    this.processShowObjects();
    this.processHideObjects();
    // Silent update for autoproduction value refresh without tile re-render.
    if (!this.tileData.player_building.auto_production_amount) {
      return;
    }
    if (!this.tooltip) {
      this.createTooltip(TileTooltipType.COLLECT);
    } else {
      this.tooltip.refreshAutoproductionValue(this.tileData.player_building.auto_production_amount);
    }
  }

  processRoads(instant?) {

    // If tile have building and roads were not generated
    if (this.hasBuilding && this.myScene.roadsLayer) {
      this.destroyRoads();

      this.roads = [];

      // const isoZ = instant ? 0 : -20;
      // const targetLayer = this.myScene.roadsLayer;

      const roadsW = BASE_ROADS_WIDTH * this.tileData.tile_type.width;
      const roadsH = BASE_ROADS_HEIGHT * this.tileData.tile_type.height;

      if (!roadsW || !roadsH) {
        return;
      }
      const isoPos = screenToIso(this.x, this.y);

      // Prepare crossroads positions
      const crossroadsPositions: CrossRoads = {
        northwest: {
          x: isoPos.x - (TILE_MAP_WIDTH) * roadsW,
          y: isoPos.y - TILE_MAP_HEIGHT * roadsH,
          show: false,
          icon: 'road_c.png'
        },
        northeast: {
          x: isoPos.x,
          y: isoPos.y - TILE_MAP_HEIGHT * roadsH,
          show: false,
          icon: 'road_c.png'
        },
        southwest: {
          x: isoPos.x - TILE_MAP_WIDTH * roadsW,
          y: isoPos.y,
          show: false,
          icon: 'road_c.png'
        },
        southeast: {
          x: isoPos.x,
          y: isoPos.y,
          show: false,
          icon: 'road_c.png'
        },
      };

      const snapshotRoadsSides = filterRoadsSides(this.tileData.border_bitmask);
      const roadsSides = snapshotRoadsSides.map(road => road.split('-')[0] as RoadSide);

      const regex = /[a-z]*-2/;
      const isOtherSouth = snapshotRoadsSides.find(road => road === 'south-2');
      const isOtherEast = snapshotRoadsSides.find(road => road === 'east-2');
      const isOtherWest = snapshotRoadsSides.find(road => road === 'west-2');
      const isOtherNorth = snapshotRoadsSides.find(road => road === 'north-2');
      const roadDepth = this.myScene.roadsLayer.depthIndex;

      if (hasRoadOnSide('south', roadsSides) && hasRoadOnSide('east', roadsSides)) {
        crossroadsPositions.southeast.show = true;
        crossroadsPositions.southeast.icon = isOtherSouth && isOtherEast ? 'road_in_c.png' : 'road_c.png';
      }

      if (hasRoadOnSide('north', roadsSides) && hasRoadOnSide('east', roadsSides)) {
        crossroadsPositions.northeast.show = true;
        crossroadsPositions.northeast.icon = isOtherNorth && isOtherEast ? 'road_in_c.png' : 'road_c.png';
      }

      if (hasRoadOnSide('north', roadsSides) && hasRoadOnSide('west', roadsSides)) {
        crossroadsPositions.northwest.show = true;
        crossroadsPositions.northwest.icon = isOtherNorth && isOtherWest ? 'road_in_c.png' : 'road_c.png';
      }

      if (hasRoadOnSide('south', roadsSides) && hasRoadOnSide('west', roadsSides)) {
        crossroadsPositions.southwest.show = true;
        crossroadsPositions.southwest.icon = isOtherSouth && isOtherWest ? 'road_in_c.png' : 'road_c.png';
      }


      if (hasRoadOnSide('south', roadsSides)) {
        const roadFound = regex.test(isOtherSouth);
        const roadLAsset = roadFound ? 'road_in_l.png' : 'road_l.png';

        for (let i = 1; i < roadsW; i++) {
          const lt = new IsoSprite(
            {
              scene: this.myScene,
              x: isoPos.x - TILE_MAP_WIDTH * i,
              y: isoPos.y,
              key: 'map-atlas',
              frame: roadLAsset,
            }
          );
          lt.setOrigin(0.5, 0);
          this.roads.push(lt);
          lt.setDepth(roadFound ? roadDepth + 0.001 : roadDepth);
          this.myScene.roadsLayer.add(lt);
        }
      }

      if (hasRoadOnSide('north', roadsSides)) {
        const roadFound = regex.test(isOtherNorth);
        const roadLAsset = roadFound ? 'road_in_l.png' : 'road_l.png';

        for (let i = 1; i < roadsW; i++) {
          const lt = new IsoSprite(
            {
              scene: this.myScene,
              x: isoPos.x - TILE_MAP_WIDTH * i,
              y: isoPos.y - roadsH * TILE_MAP_HEIGHT,
              key: MAP_ATLAS_NAME,
              frame: roadLAsset,
            }
          );
          lt.setOrigin(0.5, 0);
          lt.setDepth(roadFound ? roadDepth + 0.001 : roadDepth);
          this.roads.push(lt);
          this.myScene.roadsLayer.add(lt);
        }
      }

      if (hasRoadOnSide('east', roadsSides)) {
        const roadFound = regex.test(isOtherEast);
        const roadRAsset = roadFound ? 'road_in_r.png' : 'road_r.png';

        for (let i = 1; i < roadsH; i++) {
          const lt = new IsoSprite(
            {
              scene: this.myScene,
              x: isoPos.x,
              y: isoPos.y - TILE_MAP_HEIGHT * i,
              key: 'map-atlas',
              frame: roadRAsset,
            }
          );
          lt.setOrigin(0.5, 0);
          lt.setDepth(roadFound ? roadDepth + 0.001 : roadDepth);
          this.roads.push(lt);
          this.myScene.roadsLayer.add(lt);
        }
      }

      if (hasRoadOnSide('west', roadsSides)) {
        const roadFound = regex.test(isOtherWest);
        const roadRAsset = roadFound ? 'road_in_r.png' : 'road_r.png';

        for (let i = 1; i < roadsH; i++) {
          const lt = new IsoSprite(
            {
              scene: this.myScene,
              x: isoPos.x - TILE_MAP_WIDTH * roadsW,
              y: isoPos.y - TILE_MAP_HEIGHT * i,
              key: MAP_ATLAS_NAME,
              frame: roadRAsset,
            }
          );
          lt.setOrigin(0.5, 0);
          lt.setDepth(roadFound ? roadDepth + 0.001 : roadDepth);
          this.roads.push(lt);
          this.myScene.roadsLayer.add(lt);
        }
      }

      /** Render crossroads */
      Object.entries(crossroadsPositions)
        .map(entry => entry[1])
        .filter(crossroad => crossroad.show)
        .forEach(pos => {
          const lt = new IsoSprite(
            {
              scene: this.myScene,
              x: pos.x - 1,
              y: pos.y - 1,
              key: MAP_ATLAS_NAME,
              frame: pos.icon,
            }
          );
          lt.setOrigin(0.5, 0);
          lt.setDepth(pos.icon === 'road_in_c.png' ? roadDepth + 0.002 : roadDepth + 0.001);
          this.roads.push(lt);
          this.myScene.roadsLayer.add(lt);
        });
    } else {
      this.destroyRoads();
    }
  }

  destroyRoads() {
    this.roads.forEach(sprite => {
      sprite.destroy();
    });
    this.roads = [];
  }

  setTilePosition() {
  }

  setTint() {
    this.baseSprite.tint = 0x999999;
  }

  setTileVisibility(visible = true) {
    this.visible = visible;
    if (this.tooltip) {
      this.tooltip.visible = visible;
    }
    const mask = this.scene.add.sprite(0, 0, this.baseSprite.texture.key).setTint(0xff0000).setOrigin(0.5, 1);
    mask.alpha = 0.5;
    this.add(mask);
    this.debugGraphics = mask;
  }

  /**
   * This method is used to add custom logic to tile click.
   * If method will return anything else than undefined will stop Core flow.
   */
  beforeClickHandle(pointer: Phaser.Input.Pointer): any {
    return undefined;
  }

  processHideObjects(unhide?: boolean) {
    if (!this.playerBuildingData || !this.myScene.isoMap) {
      return;
    }
    this.myScene.isoMap.hideByBuildingObjects.forEach(object => {
      const property = object.getData('properties');
      const hideByBuilding = property.hideByBuildingObjects.includes(`${this.playerBuildingData.icon}-${this.playerBuildingData.level}`);
      if (hideByBuilding) {
        object.alpha = unhide ? 1 : 0;
      }
    });
  }

  processShowObjects() {
    if (!this.playerBuildingData || !this.myScene.isoMap) {
      return;
    }
    this.myScene.isoMap.showByBuildingObjects.forEach(object => {
      const property = object.getData('properties');
      const showByBuilding = property.showByBuilding.includes(`${this.playerBuildingData.icon}-${this.playerBuildingData.level}`);
      if (showByBuilding) {
        object.alpha = 1;
      }
    });
  }
}
