import { Component, OnInit } from '@angular/core';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { ApiService } from '../../../../../../../../core/providers/api.service';
import { AbstractQaListComponent } from '../../../../abstract/core/abstract-qa-list.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Qa } from '../../../../interfaces/core/qa.interface';
import { EVENT_DIALOGS_NAMES_QA } from '../../../../consts/core/event-dialogs/event-names.const';

@Component({
  selector: "app-qa-list-custom",
  templateUrl: "./qa-list.component.html"
})
export class QaListComponent extends AbstractQaListComponent implements OnInit {
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(MAT_DIALOG_DATA) data: {
    location?: number;
    qa?: Qa;
    type?: string;
    eachQaId?: number;
    activeQaId: number;
    isShowBackButton?: boolean;
    isOpenFromMiniGame: boolean;
  };
  
  ngOnInit() {
    this.baseInit();
  }

  afterQaList() {
    super.afterQaList();
    if (this.data["isOpenByQaListView"]) {
      console.log(this.data.activeQaId);

      this.setQaById(this.data.activeQaId);
    }
  }

  setQaById(id) {
    const qa = this.allQa.find((q) => q.qa_id == id);
    console.log(qa);
    if (qa) {
      this.changeActiveQa(qa);
    }
  }

  openQaDetails() {
    if (this.data.isOpenFromMiniGame) {
      this.close();
    }

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA.QA_DETAILS,
      config: {
        disableClose: true,
        data: {
          qa: this.activeQa,
          prevState: {
            location: this.data.location,
            type: this.data.type,
            eachQaId: this.data.eachQaId
          }
        }
      }
    });
  }
}
