import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractLoginComponent } from '../../../../abstract/abstract-login.component';
import { orientationModes } from '../../../../../../core/consts/core/orientation-modes.enum';
import { unsubscribeObject } from '../../../../../../core/utility/unsubscribe-array';
import {LoginActions} from '../../../../../../store/auth/login';
import {ofType} from '@ngrx/effects';
import {tap} from 'rxjs/operators';
import {UserActions} from '../../../../../../store/user';
import {OwInject} from '../../../../../../core/decorators/ow-inject.decorator';
import {Identifier, IdentifierService} from '../../../../../game/services/identifier.service';
import {ActivatedRoute, Router} from '@angular/router';
import {determineSiteLanguage} from '../../../../../../helpers/common.helpers';
import {CUSTOM_PRIVACY_POLICY} from '../../../../consts/custom/privacy-policy.const';

@Component({
  selector: 'app-custom-login',
  templateUrl: './login.component.html',

})
export class LoginComponent extends AbstractLoginComponent implements OnInit, OnDestroy {
  @OwInject(IdentifierService) identifierService;
  @OwInject(ActivatedRoute) activatedRoute;
  identifier: Identifier = null;
  externalLink: string;

  ngOnInit() {
    this.determinePrivacyPolicy();
    const slug = this.identifierService.getSlug();
    const routeSlug = this.activatedRoute.snapshot.params.identifier;

    if (!routeSlug && !slug) {
      this.identifierService.removeIdentifierFromLocalStorage();
      this.identifierService.redirectToIdentifierPage();
      return;
    }

    this.identifierService.getClientIdentifier(routeSlug || slug).subscribe(res => {
      this.identifier = res;
      this.identifierService.setIdentifierToLocalStorage(this.identifier);

      const isGameFinished = this.identifierService.checkIfGameIsFinished(this.identifier);
      if (isGameFinished) {
        this.identifierService.removeIdentifierFromLocalStorage();
        this.identifierService.redirectToIdentifierPage();
      }

      this.androidService.setNativeAppOrientation(orientationModes.Auto);
      this.subscribeAuthSuccess();
      this.subscribeUserSuccess();
    }, err => {
      this.identifierService.removeIdentifierFromLocalStorage();
      this.identifierService.redirectToIdentifierPage();
    });
  }

  login() {
    const data = this.prepareData();
    this.store.dispatch(new LoginActions.AuthClientStart(data));
  }

  subscribeAuthSuccess() {
    this.subs.actionAuthSuccess = this.actions$
      .pipe(
        ofType(LoginActions.ActionTypes.AUTH_CLIENT_SUCCESS),
        tap(() => {
          this.store.dispatch(new UserActions.FetchUser());
        })
      )
      .subscribe();
  }
determinePrivacyPolicy() {
    const language = determineSiteLanguage();
    switch (language) {
      case 'pl':
        this.externalLink = CUSTOM_PRIVACY_POLICY.PL;
        break;
      case 'en':
        this.externalLink = CUSTOM_PRIVACY_POLICY.EN;
        break;
      default:
        this.externalLink = CUSTOM_PRIVACY_POLICY.EN;
    }

}

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
