<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- BUTTON EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <div class="title">
      {{'qa.qa-list-view.title' | myTranslate}}
    </div>
  </div>

  <ng-container *ngIf="allQa">
    <!-- FILTERS -->
    <div class="container-filter">
      <ng-container *ngFor="let filter of filters">
        <div
          class="each-filter"
          (click)="changeFilter(filter)"
        >
          <!-- CHECKBOX -->
          <button
            class="checkbox"
            [class.active]="filter.type == activeFilter.value"
          ></button>

          <!-- LABEL -->
          <span>{{ filter.label | myTranslate}}</span>
        </div>
      </ng-container>
    </div>

    <div class="container loading-opacity">
      <perfect-scrollbar scrollGradient>
        <table>
          <tbody *ngIf="!isLoading">
          <tr
            *ngFor="let qa of allQa | filterPipe:activeFilter.propertyName:activeFilter.value"
            (click)="!qa['noItem'] && openQaList(qa)"
            [class.opacity]="(qa.type == 'pill' && qa['current_progress'] == qa['max_progress']) || (qa.type !== 'pill' && qa.last_attempt && qa.last_attempt.finished_at)"
            [class.disabled]="qa['noItem']"
          >
            <td class="td-name">
              <span class="name">{{ (qa.name !== "qa.qa-list.empty") ? qa.name  :  qa.name | myTranslate }}</span>
            </td>
            <td class="td-prize" *ngIf="!qa['noItem']">
              <div class="prizes-container">
                <span>{{ qa['current_progress'] || 0 }}/{{ qa['max_progress'] }}</span>
                <img alt="" [src]="'money.png' | asset:'currency/basic'"/>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </perfect-scrollbar>
    </div>
  </ng-container>
</div>
