import { Component } from '@angular/core';
import { AbstractHudParametersComponent } from '../../../abstract/core/abstract-hud-parameters.component';
import * as R from 'ramda';
import { CITY_CHARACTER_PARAMETERS_ID } from '../../../const/custom/city-character-parameters.const';
import { PlayerParameterBalanceHud } from '../../../interfaces/core/player-parameter-balance-hud.interface';
import { PlayerParameterBalance } from '../../../../../interfaces/parameters';
import {translate} from '../../../../../../../core/helpers/translate.helper';

@Component({
  selector: 'app-hud-parameters-custom',
  templateUrl: './hud-parameters.component.html',
})
export class HudParametersComponent extends AbstractHudParametersComponent {
  setHudParameters() {
    super.setHudParameters();
    this.filterWithoutCityCharacterParameters();
  }

  filterWithoutCityCharacterParameters() {
    this.parameterBalances = this.parameterBalances.filter((parameter) => {
      return !R.contains(parameter.parameter_id, CITY_CHARACTER_PARAMETERS_ID);
    });
  }

  setTooltip(parameter: PlayerParameterBalanceHud) {
    if (parameter.tooltip) {
      Object.keys(this.PLACEHOLDERS).forEach((key) => {
        const placeholderObject: { placeholder: string, getValue: (parameter?: PlayerParameterBalance) => any } = this.PLACEHOLDERS[key];

        if (placeholderObject.placeholder === '%current_percentage_value') {
          placeholderObject.getValue = () => `${parameter.displayHud}%`;
        }

        if (placeholderObject.placeholder === '%current_income_value') {
          placeholderObject.getValue = () => `${parameter.income}`;
        }

        if (placeholderObject.placeholder === '%current_outcome_value') {
          placeholderObject.getValue = () => `${parameter.outcome}`;
        }

        parameter.tooltip = parameter.tooltip.replace(placeholderObject.placeholder, placeholderObject.getValue(parameter));
      });

      if (parameter.parameter_id === 7) {
        parameter.tooltip = parameter.income > 0 ? parameter.tooltip : translate('hud.parameters.neutral-emission');
      }
    }
  }

  handleMobileTouchEvent(e, tooltip) {
    e.preventDefault();
    tooltip.close();
  }
}
