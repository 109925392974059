<!-- LOGO -->
<div class="logo"></div>
<h4 class="client-title" *ngIf="!identifier?.logo_file_name">{{identifier?.company}}</h4>

  <loading></loading>

  <div class="container loading-opacity">
    <p class="title">{{ 'auth.registration-confirm-a.title' | myTranslate }}:</p>

    <form [formGroup]="form" *ngIf="form">
      <!-- USERNAME -->
      <ng-container *ngIf="form.get('username') as username">
        <div class="control" [class.error]="getErrors(username)">
          <div class="label-flex">
            <label>{{ 'auth.registration-confirm-a.username' | myTranslate }}</label>
          </div>
          <input class="control-input" formControlName="username" type="text" (paste)="onPaste($event)"/>
          <div class="alert alert-danger" *ngIf="getErrors(username)">
            <span *ngIf="username.errors.required">{{ 'auth.registration-confirm-a.username-error-required' | myTranslate }}</span>
          </div>
          <div class="text-hint">
            <span>{{ 'auth.registration-confirm-a.hint-top' | myTranslate }} <br> {{ 'auth.registration-confirm-a.hint-bottom' | myTranslate }}</span>
          </div>
        </div>
      </ng-container>

      <!-- PASSWORD -->
      <ng-container *ngIf="form.get('password') as password">
        <div class="control" [class.error]="getErrors(password)">
          <div class="label-flex">
            <label>{{ 'auth.registration-confirm-a.password' | myTranslate }}</label>
          </div>
          <input class="control-input" formControlName="password" type="password"/>
          <div class="alert alert-danger" *ngIf="getErrors(password)">
            <span *ngIf="password.errors.required">{{ 'auth.registration-confirm-a.password-error-required' | myTranslate }}</span>
            <span *ngIf="password.errors.pattern">{{ 'auth.registration-confirm-a.password-error-pattern-top' | myTranslate }} <br> {{ 'auth.registration-confirm-a.password-error-pattern-bottom' | myTranslate }} </span>
          </div>
        </div>
      </ng-container>

      <!-- PASSWORD REPEAT -->
      <ng-container *ngIf="form.get('passwordRepeat') as passwordRepeat">
        <div class="control" [class.error]="getErrors(passwordRepeat)">
          <div class="label-flex">
            <label>{{ 'auth.registration-confirm-a.password_repeat' | myTranslate }}</label>
          </div>
          <input class="control-input" formControlName="passwordRepeat" type="password"/>
          <div class="alert alert-danger" *ngIf="getErrors(passwordRepeat)">
            <span *ngIf="passwordRepeat.errors.required">{{ 'auth.registration-confirm-a.password_repeat-error-required' | myTranslate }}</span>
          </div>
          <p
            class="alert alert-danger"
            *ngIf="form.get('password').value && form.get('passwordRepeat').value && form.hasError('notSame')"
          >
            {{ 'auth.registration-confirm-a.password_repeat-error-not-same' | myTranslate }}
          </p>
        </div>
      </ng-container>

      <div class="confirmations">
        <div class="control control-checkbox">
          <div class="custom-checkbox">
            <input type="checkbox" formControlName="confirm" id="confirm" required/>
            <span class="custom-checkbox-fill">
               <i class="fas fa-check"></i>
            </span>
          </div>

          <label for="confirm">
            {{ 'auth.registration-confirm-a.terms-top' | myTranslate }}
            <a [href]="termsUrl" target="_blank">{{ 'auth.registration-confirm-a.terms-filename' | myTranslate }}</a>
          </label>
        </div>
      </div>
    </form>

    <div class="buttons" *ngIf="form">
      <button
        class="base primary"
        (click)="registrationConfirm()"
        [disabled]="form.invalid"
      >
        {{ 'auth.registration-confirm-a.form.button-send' | myTranslate }}
      </button>
    </div>
  </div>
<!--<div class="client-logo">-->
<!--  <img [src]="identifier?.logo_url" />-->
<!--</div>-->
