<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <div class="container">
    <perfect-scrollbar [scrollGradient]="rankGameList.length">
      <table>
        <tbody>
        <tr *ngFor="let rank of rankGameList">
          <td>{{ rank.name }}</td>
          <td>
          <span class="status-rank" [ngClass]="rank.cleared ? 'is-cleared' : 'is-active'">
            {{ (rank.cleared ? 'ranking.list.ranking-status-closed' : 'ranking.list.ranking-status-active') | myTranslate }}
          </span>
          </td>
          <td></td>
          <td>
            <div class="buttons-container">
              <button
                *ngIf="rank.description"
                (click)="openRankDescription(rank)"
                class="square small primary"
              >
                <i class="fas fa-info"></i>
              </button>

              <button
                (click)="openRankDetails(rank)"
                class="square small primary"
              >
                <i class="fas fa-search"></i>
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </perfect-scrollbar>
  </div>
</div>
