import { Component, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlayerBuilding } from '../../../../../game-engine/interfaces/player-building.config';
import { BuildingsService } from '../../../../../services/buildings.service';
import { BuildingDetailsConfig } from '../../../../../game-engine/interfaces/building-details-config';

@Component({
  selector: 'app-neighbourhood-custom',
  templateUrl: './neighbourhood.component.html',
})
export class NeighbourhoodComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(MAT_DIALOG_DATA) data: {
    playerBuilding: PlayerBuilding;
  };

  buildingDetails: BuildingDetailsConfig;

  neighbourhoodSums: {
    current_bonus: number;
    max_bonus: number;
  };

  ngOnInit() {
    this.getPlayerBuildingDetails(this.data.playerBuilding.player_building_id);
  }

  getPlayerBuildingDetails(playerBuildingId: number) {
    this.buildingsService.getPlayerBuildingDetails(playerBuildingId)
      .subscribe((buildingDetails) => {
        this.buildingDetails = buildingDetails;
        this.setNeighbourhoodSums();
      });
  }

  setNeighbourhoodSums() {
    if (this.buildingDetails['neighbourhood']?.length) {
      this.neighbourhoodSums = {
        current_bonus: 0,
        max_bonus: 0,
      };

      this.buildingDetails['neighbourhood'].forEach((item) => {
        this.neighbourhoodSums.current_bonus += item.current_bonus;
        this.neighbourhoodSums.max_bonus += item.max_bonus;
      });
    }
  }
}
