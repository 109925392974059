import { Component, OnInit } from '@angular/core';
import {OwInject} from '../../../../../../../../core/decorators/ow-inject.decorator';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BuildingMoveData} from '../../../../interfaces/core/dialogs/building-move-data.interface';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../../../store/state';
import {DialogService} from '../../../../../../../shared/providers/dialog.service';
import {GameService} from '../../../../../../services/game.service';
import {MyGame} from '../../../../../../game-engine/classes/core/MyGame';
import {translate} from '../../../../../../../../core/helpers/translate.helper';
import {AbstractInjectBaseComponent} from '../../../../../../../../core/abstracts/abstract-inject-base.component';

@Component({
  selector: 'app-m-building-move-custom',
  templateUrl: '../../../core/dialogs/building-move/building-move.component.html'
})
export class MBuildingMoveComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(MAT_DIALOG_DATA) data: BuildingMoveData;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(GameService) gameService: GameService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<any>;

  ngOnInit() {
    this.openDialogConfirm();
  }

  confirmBuildingMove() {
    (this.gameService.game as MyGame).currentScene.board.startBuildingMoveMode(this.data.tileId);
  }

  openDialogConfirm() {
    this.dialogService.openConfirm({
      title: translate('building-move.title'),
      description: translate('building-move.description'),
    }, (confirm) => {
      if (confirm) {
        this.confirmBuildingMove();
      }

      this.close();
    });
  }

  close() {
    this.matDialogRef.close();
  }
}
