import { Component } from '@angular/core';
import { AbstractWarehouseComponent } from '../../../../abstract/core/abstract-warehouse.component';
import { StorageBase } from '../../../../interfaces/core/storage.interface';
import { StorageWithCategories } from '../../../../interfaces/core/storage-with-categories.interface';
import { generateEachPages } from '../../../../../../../shared/helpers/generate-pages.helper';

@Component({
  selector: 'app-custom-warehouse',
  templateUrl: './warehouse.component.html',
})
export class WarehouseComponent extends AbstractWarehouseComponent {
  noItems = false;
  isLoading = false;

  getStorages() {
    this.isLoading = true;
    this.storages = [];

    this.apiStorageService.storage()
      .subscribe((resp: StorageBase[]) => {
        this.storages = <StorageWithCategories[]>resp
          .filter((storage) => {
            if (this.data.storageId) {
              return storage.storage_id === this.data.storageId;
            } else {
              return true;
            }
          })
          .map((storage) => {
            return {...storage, categories: []};
          });

        this.getAllCategories();
        this.changeActiveStorage(this.storages[0]);
      });
  }

  getProductsByCategory(category_id) {
    this.productPlayerService.productsPlayer({category: category_id})
      .subscribe((resp) => {
        this.parseProducts(resp, true);
        this.isLoading = false;
      });
  }

  parseProducts(products, isResp?: boolean) {
    if (isResp) {
      setTimeout(() => {
        if (!products.length) {
          this.noItems = true;
        }
      }, 100);
    }

    this.productsSlider.pages = [];
    this.productsSlider.items = this.setProducts(products);
    this.productsSlider = generateEachPages(this.productsSlider);
    this.calculateItemsCapacity();
  }
}
