import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPipe'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], propertyName: string, value: string | boolean | number): any {
    if (!items || !propertyName || value == null) {
      return items;
    }
    let val = items.filter(item => item[propertyName] === value);
    if (!val.length) {
      val = [{ name: "qa.qa-list.empty", noItem: true }];
    }
    return val;
  }
}
