import { Component, OnInit } from '@angular/core';
import {
  AbstractRegistrationInstantComponent
} from "../../../../abstract/registration/variant-instant/abstract-registration-instant.component";
import {OwInject} from "../../../../../../../core/decorators/ow-inject.decorator";
import {Identifier, IdentifierService} from "../../../../../../game/services/identifier.service";

@Component({
  selector: 'app-custom-registration-instant',
  templateUrl: './registration-instant.component.html',

})
export class RegistrationInstantComponent extends AbstractRegistrationInstantComponent implements OnInit {
  @OwInject(IdentifierService) identifierService;
  ngOnInit() {
    const slug = this.identifierService.determineSlugFromUrl();
    this.identifierService.getClientIdentifier(slug).subscribe((res: Identifier) => {
      this.identifier = res;
      const isInstantRegistration = this.identifierService.isInstantRegistration(res)
      if(!isInstantRegistration) {
        this.identifierService.redirectToLoginPage(res);
        return;
      }

      const isGameFinished = this.identifierService.checkIfGameIsFinished(res);
      if (isGameFinished) {
        this.identifierService.removeIdentifierFromLocalStorage();
        this.identifierService.redirectToIdentifierPage();
      }
    });
    this.clearForm();
  }

}
