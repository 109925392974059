import { Component, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { ApiMissionGroupService } from '../../../../mission/api/core/services/api-mission-group.service';
import {
  GroupMissionDetailsInterface,
  GroupMissionDetailsMissionInterface,
  GroupMissionInterface,
  MissionDetails
} from '../../../../mission/interfaces/core';
import { ApiMissionService } from '../../../../mission/api/core/services/api-mission.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../../store/state';
import { UtilityActions } from '../../../../../../../store/utility';
import { MISSION_CONDITION_VALUE_TYPE } from '../../../../mission/consts/core';

@Component({
  selector: 'hud-star-missions',
  templateUrl: './hud-star-missions.component.html',
})
export class HudStarMissionsComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(ApiMissionGroupService) apiMissionGroupService: ApiMissionGroupService;
  @OwInject(ApiMissionService) apiMissionService: ApiMissionService;
  @OwInject(Store) store: Store<AppState>;

  MISSION_CONDITION_VALUE_TYPE = MISSION_CONDITION_VALUE_TYPE;

  groupMissions: GroupMissionInterface[] = [];
  activeGroupMission: GroupMissionInterface;
  activeGroupMissionDetails: GroupMissionDetailsInterface;
  activeMission: GroupMissionDetailsMissionInterface;
  activeMissionDetails: MissionDetails;
  areConditionsComplete: boolean;
  isLoading: boolean;
  allStarsCollected: boolean;

  ngOnInit() {
    this.getGroupMissions();
  }

  getGroupMissions() {
    this.isLoading = true;

    this.apiMissionGroupService.getMissionGroups().subscribe(groupMissions => {
      this.groupMissions = groupMissions;
      this.allStarsCollected = groupMissions.every(m => m.is_reward_collected);
      this.activeGroupMission = this.groupMissions.find(groupMission => !groupMission.is_reward_collected);
      if (this.activeGroupMission) {
        if (this.activeGroupMission.completed_missions === this.activeGroupMission.total_missions) {
          this.apiMissionGroupService.getMissionGroupReward(this.activeGroupMission.mission_group_id).subscribe(() => {
            this.getGroupMissions();
            this.isLoading = false;
          }, () => {
            this.isLoading = false;
          });
        } else {
          this.checkActiveGroupMission();
        }
      } else {
        this.isLoading = false;
      }
    }, () => {
      this.isLoading = false;
    });
  }

  checkActiveGroupMission() {
    this.apiMissionGroupService.getMissionGroupsDetails(this.activeGroupMission.mission_group_id).subscribe(groupMissionDetails => {
      this.activeGroupMissionDetails = groupMissionDetails;
      this.activeMission = this.activeGroupMissionDetails.missions.find(mission => !mission.is_collected);
      this.apiMissionService.getMissionDetails(this.activeMission.player_mission_id).subscribe(mission => {
        this.activeMissionDetails = mission;
        this.activeMissionDetails.conditions.sort(this.sortMissionByCompletion)
        this.areConditionsComplete = this.activeMissionDetails.conditions.every(condition => condition.completed_at);
        this.dispatchRemoveMissionToOpen();
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      });
    });
  }

  dispatchRemoveMissionToOpen() {
    this.store
      .dispatch(new UtilityActions.RemoveMissionToOpen({
        player_mission_id: this.activeMissionDetails.player_mission_id
      }));
  }

  changeMission(mission: GroupMissionDetailsMissionInterface) {
    this.activeMission = mission;
  }

  completeStage() {
    this.apiMissionService.missionCollect(this.activeMissionDetails.player_mission_id).subscribe(() => {
      this.getGroupMissions();
    });
  }

  // Sorting Missions in, Completed Missions will be on the end of array
  sortMissionByCompletion(FirstMission,NextMission) {
    if (FirstMission.completed_at) {
      return 1;
    }
    else if(FirstMission.completed_at == NextMission.completed_at){
      return 0;
    }
    else {
      return -1;
    }
  }
}
