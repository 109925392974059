import { Component, OnInit } from '@angular/core';
import { AbstractHudComponent } from '../../../abstract/core/abstract-hud.component';
import { GlobalEvent } from '../../../../../../../core/interfaces/shared.interfaces';
import { CityCharacterComponent } from '../../dialogs/city-character/city-character.component';
import { ADDITIONAL_BOARD_DATA } from '../../../../../game-engine/config/additional-board-data.config';
import { MISSION_TYPE } from '../../../../mission/consts/core';
import { UtilityActions } from '../../../../../../../store/utility';
import { NeighbourhoodComponent } from '../../dialogs/neighbourhood/neighbourhood.component';

@Component({
  selector: 'app-hud-custom',
  templateUrl: './hud.component.html',
})
export class HudComponent extends AbstractHudComponent implements OnInit {
  ngOnInit() {
    this.subscribePlayer();
    this.subscribeUser();
    this.subs.globalEmitter = this.globalService.globalEvents.subscribe(this.handleGameEvents.bind(this));
    this.setDynamicsComponents();
  }

  openFunctional(event: GlobalEvent) {
    super.openFunctional(event);
    this.openCharacterCity(event);
    this.openNeighbourhood(event);
  }

  openCharacterCity(event) {
    if (event.data.player_building.additional_board_data.hasOwnProperty(ADDITIONAL_BOARD_DATA.OPEN_CHARACTER_CITY)) {
      this.dialogService.open(CityCharacterComponent);
    }
  }

  openNeighbourhood(event) {
    if (event.data.player_building.additional_board_data.hasOwnProperty(ADDITIONAL_BOARD_DATA.OPEN_NEIGHBOURHOOD)) {
      this.dialogService.open(NeighbourhoodComponent, {
        data: {
          playerBuilding: event.data.player_building,
        }
      });
    }
  }

  missionCompleted(event: GlobalEvent) {
    if (event.value.mission_type === MISSION_TYPE.ONE && event.value.mission_slot_id !== 1) {
      this.store.dispatch(new UtilityActions.SetHasMissionsToCollect(true));
    }

    if (event.value.mission_type === MISSION_TYPE.ONE) {
      this.store.dispatch(new UtilityActions.AddMissionToOpen({
        player_mission_id: event.value.player_mission_id,
        slot: {
          mission_slot_id: event.value.mission_slot_id,
          type: event.value.mission_type,
        }
      }));
    }
  }
}
