export const RANK_CONFIG = {
  DEFAULT: {
    columns: [
      {header: null, value: 'position'},
      {header: null, custom: true, template: 'avatar'},
      {header: 'Nick', value: 'username'},
      {columnFromApi: 1, textAlign: 'center'},
      {columnFromApi: 2, textAlign: 'center'},
      {columnFromApi: 3, textAlign: 'center'},
    ]
  }
};
