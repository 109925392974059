import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GAME_CONFIG } from '../config/custom/_parsed-game.config';
import { OrientationManager } from '../helpers/orientation-manager.helper';
import { AndroidService } from './android.service';
import {determineSiteLanguage} from '../../helpers/common.helpers';

const MobileDetect = require('mobile-detect');

export const mobileDetect = new MobileDetect(window.navigator.userAgent);
export let mobileGui = false;
enum GUI {
  mobile,
  tablet,
  desktop,
}

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  gui: GUI;
  isMobile: boolean;
  resizeTimer: number;
  windowScale = 1;
  windowMinHeightPx = 534;
  externalBarPx = 60;
  dialogScale = 1;
  isAllowOrientation: boolean;
  allowOrientation = GAME_CONFIG[determineSiteLanguage()].DEVICE_ALLOW_ORIENTATION;

  constructor(
    private router: Router,
    private androidService: AndroidService,
  ) {
    this.checkAndSetMobileDevice();
    this.checkAndSetGui();
  }

  checkAndSetMobileDevice() {
    this.isMobile = mobileDetect.mobile() || this.checkSafariOnIpad();
  }

  checkAndSetGui() {
    this.gui = GUI.desktop;

    if (this.isMobile) {
      this.gui = GUI.mobile;

      const size = 1000;
      const someSizeMoreThan = window.innerHeight >= size || window.innerWidth >= size;
      const everySizeMoreThan = window.innerHeight >= size && window.innerWidth >= size;

      if (someSizeMoreThan) {
        this.gui = GUI.tablet;
      }

      if (everySizeMoreThan) {
        this.gui = GUI.desktop;
      }
    }

    this.handlerChange();
    this.startOrientationManager();
  }

  checkSafariOnIpad() {
    return mobileDetect['ua'].toLowerCase().includes('safari') && window.orientation !== undefined;
  }

  setBodyClass() {
    document.body.classList.remove('mobile');
    document.body.classList.remove('tablet');
    document.body.classList.remove('desktop');

    switch (this.gui) {
      case GUI.mobile:
        document.body.classList.add('mobile');
        break;

      case GUI.tablet:
        document.body.classList.add('tablet');
        break;

      case GUI.desktop:
        document.body.classList.add('desktop');
        break;
    }
  }

  setMaxHeightDialog() {
    const prevStyles = Array.from(document.querySelectorAll('style[maxHeight="style"]'));
    prevStyles.forEach(element => {
      element.remove();
    });

    this.calcScale();

    if (this.windowScale < 1) {
      const sheet = document.createElement('style');
      sheet.setAttribute('maxHeight', 'style');
      sheet.innerHTML = `
      .global-dialog.dialog-mobile {
        max-height: ${window.innerHeight}px !important
      }
    `;
      document.body.appendChild(sheet);
    }
  }

  disableMobileGui() {
    this.gui = GUI.desktop;
    this.refreshRouter();
  }

  activeMobileGui() {
    this.gui = GUI.mobile;
    this.refreshRouter();
  }

  refreshRouter() {
    this.handlerChange();
    this.router.navigateByUrl(this.router.url);
  }

  handlerChange() {
    this.setGlobalGui();
    this.setBodyClass();
    this.setMaxHeightDialog();
    this.setMobileWindowMaxHeightAndWidthClass();
    this.setOrientation();
  }

  setOrientation() {
    if (this.isMobile) {
      if (this.allowOrientation === 'landscape') {
        this.isAllowOrientation = window.orientation === -90 || window.orientation === 90;
      }

      if (this.allowOrientation === 'portrait') {
        this.isAllowOrientation = window.orientation === 0 || window.orientation === 180;
      }
    }

    if (this.androidService.nativeInterface) {
      this.isAllowOrientation = true;
    }
  }

  setGlobalGui() {
    mobileGui = this.gui !== GUI.desktop;
  }

  calcScale() {
    const tempScale = window.innerHeight / (this.windowMinHeightPx + this.externalBarPx);
    this.windowScale = tempScale >= 1 ? 1 : tempScale;
  }

  calcDialogScale() {
    const tempScale = window.innerHeight / 320;
    this.dialogScale = tempScale >= 1 ? 1 : window.innerHeight / 320;
  }

  startOrientationManager() {
    window.addEventListener(OrientationManager.orientationEvent,
      (() => {
        setTimeout(() => {
          if(OrientationManager.orientationChanged()) {
            this.handlerChange()
          }
        }, 50)
      })
    )
  }

  setMobileWindowMaxHeightAndWidthClass() {
    setTimeout(() => {
      const prevStyles = Array.from(document.querySelectorAll('style[mobile="style"]'));
      prevStyles.forEach(element => {
        element.remove();
      });
    }, 0);

    setTimeout(() => {
      if (this.gui !== GUI.desktop) {
        this.calcDialogScale();
        const sheet = document.createElement('style');
        sheet.setAttribute('mobile', 'style');
        sheet.innerHTML = `
          .mobile div:not(.no-dynamic-scale) > mat-dialog-container,
          .tablet div:not(.no-dynamic-scale) > mat-dialog-container {
            transform: scale(${this.dialogScale}) !important;
          }
        `;
        document.body.appendChild(sheet);
      }
    }, 150);
  }
}
