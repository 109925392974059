import { Component, OnInit } from "@angular/core";
import { AbstractInjectBaseComponent } from "../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../core/decorators/ow-inject.decorator";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogService } from "../../../../../../shared/providers/dialog.service";
import { ApiPillsServices } from "../../../api/custom/api-pills.services";
import * as R from "ramda";
import { finalize } from "rxjs/operators";
import { STOCK_VIEW } from "../../../../shared-ui/mobile/consts/stock-view.const";
import { translate } from "../../../../../../../core/helpers/translate.helper";
import { EventEmitterDialogsService } from "src/app/core/services/core/event-emitter-dialogs.service";
import { Qa } from "../../../../qa/interfaces/core/qa.interface";

@Component({
  selector: "app-knowledge-pill",
  templateUrl: "./knowledge-pill.component.html"
})
export class KnowledgePillComponent
  extends AbstractInjectBaseComponent
  implements OnInit
{
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<KnowledgePillComponent>;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(ApiPillsServices) apiPillsServices: ApiPillsServices;
  @OwInject(EventEmitterDialogsService)
  eventEmitterDialogsService: EventEmitterDialogsService;

  @OwInject(MAT_DIALOG_DATA) data: {
    base_pill_id: number;
    pill_id: number;
  };
  STOCK_VIEW = STOCK_VIEW;

  isLoadingUserSteps: boolean;
  currentStep: number;
  activePill: any;

  get pills() {
    return this.pillDetails.data.pills;
  }

  pillDetails: PillDetails;
  isPillDev = new URLSearchParams(location.search).get("pill_dev") === "1";

  ngOnInit() {
    this.getPillDetails();
  }

  setPills() {
    if (this.pills && this.pills.length) {
      if (this.currentStep) {
        this.changePillByStep(this.currentStep);
      } else {
        this.setFirstPill();
      }
    } else {
      this.dialogService.openAlert(
        {
          description:
            translate("hud.knowledge.pill-not-found-top") +
            ` ${this.data.base_pill_id} ` +
            translate("hud.knowledge.pill-not-found-bottom")
        },
        () => {
          this.close();
        }
      );
    }
  }

  changeActivePill(pill) {
    let blockSetPillApi = false;
    this.activePill = pill;

    if (this.activePill.condition) {
      if (this.activePill.condition.steps_id) {
        const userStepsId = this.pillDetails.user_steps.map(
          (userStep) => userStep.step_id
        );

        const conditionValue: boolean =
          this.activePill.condition.steps_id.every((stepId) => {
            return R.contains(stepId, userStepsId);
          });

        if (conditionValue) {
          if (this.activePill.condition.action.name === "go-to") {
            blockSetPillApi = true;
          }

          this.makeAction(this.activePill.condition.action);
        }
      }
    }

    if (this.activePill.step_id && !blockSetPillApi) {
      this.setPillStepApi(this.activePill.step_id);
    }
  }

  setPillStepApi(step_id: number) {
    this.apiPillsServices
      .setStepPill({
        pill_id: this.data.pill_id,
        step_id
      })
      .subscribe((resp) => {
        this.pillDetails = resp;
        this.currentStep = step_id;
      });
  }

  setStepPillExtraData({ step_id, extra_data, callback }) {
    this.apiPillsServices
      .setStepPillExtraData({
        pill_id: this.data.pill_id,
        step_id,
        extra_data
      })
      .subscribe(() => {
        callback();
      });
  }

  getPillByName(name: string) {
    return this.pills.find((pill) => pill.name === name);
  }

  changePillByName(name: string) {
    const pill = this.getPillByName(name);

    if (pill) {
      this.changeActivePill(pill);
    }
  }

  changePillByStep(stepId: number) {
    const pill = this.pills.find((pill) => pill.step_id === stepId);

    if (pill) {
      this.changeActivePill(pill);
    }
  }

  setFirstPill() {
    this.changeActivePill(this.pills[0]);
  }

  clickElement(boxClicked) {
    this.makeAction(boxClicked.click);
  }

  swipeLeftElement(boxClicked) {
    this.makeAction(boxClicked.swipeleft);
  }

  swipeRightElement(boxClicked) {
    this.makeAction(boxClicked.swiperight);
  }

  makeAction(action: { name: string; value: any; callback: any }) {
    if (!action) {
      return;
    }

    switch (action.name) {
      case "go-to":
        this.changePillByName(action.value);
        break;

      case "close-all":
        this.close();
        break;

      case "send-event-data":
        this.setStepPillExtraData({
          step_id: this.currentStep,
          extra_data: action.value,
          callback: () => {
            if (action.callback) {
              this.makeAction(action.callback);
            }
          }
        });
        break;

      case "show-alert":
        this.dialogService.openAlert(
          {
            description: action.value
          },
          () => {
            if (action.callback) {
              this.makeAction(action.callback);
            }
          }
        );
        break;

      default:
        this.dialogService.openAlert({
          description: translate("hud.knowledge.ation-not-found") + action.name
        });
    }
  }

  getPillDetails() {
    this.isLoadingUserSteps = true;
    this.apiPillsServices
      .getPillDetails({ pill_id: this.data.pill_id })
      .pipe(
        finalize(() => {
          this.isLoadingUserSteps = false;
        })
      )
      .subscribe((resp) => {
        this.pillDetails = resp;
        console.log("pillsDetails: ", this.pillDetails);
        this.currentStep = R.path(
          ["0", "step_id"],
          this.pillDetails.user_steps
        );
        this.setPills();
      });
  }

  getDevDetailsView() {
    return localStorage.getItem("isPillDevDetailsView") === "1";
  }

  toggleDevDetailsView() {
    const nextValue =
      localStorage.getItem("isPillDevDetailsView") === "1" ? "0" : "1";
    localStorage.setItem("isPillDevDetailsView", nextValue);
  }

  close() {
    this.matDialogRef.close();
  }

}

export interface CurrencyPill {
  currency_id: number;
  currency_type: "player" | "user";
  amount: number;
}

export interface UserStep {
  step_id: number;
  extra_data: any;
  viewed_at: string;
}

export interface PillDetails {
  pill_id: number;
  max_prize: CurrencyPill;
  current_prize: CurrencyPill;
  user_steps: UserStep[];
  data: {
    pills: any[];
  };
}
