import { Injectable } from '@angular/core';
import {Actions, Effect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import {ActionTypes, FetchNewTokenFinish, GetIdentifier, SetIdentifier, SetToken, UpdateActivePlayerId, UpdateMePlayerId} from './actions';
import { PlayerService } from '../../modules/player/providers/player.service';
import { ApiService } from '../../core/providers/api.service';
import { TokenExchangeService } from '../../core/providers/token-exchange.service';
import { TokenObject } from '../../modules/auth/interfaces/token-object';
import { removeToken } from '../../core/utility/token';
import { Router } from '@angular/router';
import { UtilityActions } from './index';

@Injectable()
export class UtilityEffects {

  constructor(
    private actions$: Actions,
    private playerService: PlayerService,
    private apiService: ApiService,
    private tokenExchangeService: TokenExchangeService,
    private store: Store<AppendMode>,
    private router: Router
  ) {
  }

  @Effect()
  $updateMePlayerId = this.actions$
    .pipe(
      ofType(ActionTypes.UPDATE_ME_PLAYER_ID),
      tap((action: UpdateMePlayerId) => {
        this.playerService.setMePlayerId(action.payload.playerId);
        if (action.payload.playerId) {
          this.tokenExchangeService.exchangeTimeoutStart();
        }
      }),
      map((action: UpdateMePlayerId) => {
        return new UpdateActivePlayerId(
          {
            playerId: action.payload.playerId
          }
        )
      })
    );

  @Effect({dispatch: false})
  $updateActivePlayerId: Observable<void | Action> = this.actions$
    .pipe(
      ofType(ActionTypes.UPDATE_ACTIVE_PLAYER_ID),
      tap((action: UpdateActivePlayerId) => {
        this.playerService.setActivePlayerId(action.payload.playerId)
      })
    );

  @Effect({dispatch: false})
  setToken$ = this.actions$
    .pipe(
      ofType(ActionTypes.SET_TOKEN),
      tap((action: SetToken) => {
        this.apiService.setToken(action.payload);
        this.tokenExchangeService.exchangeTimeoutStart();
        this.store.dispatch(new UtilityActions.GetIdentifier());
      })
    );

  @Effect({dispatch: false})
  removeToken$ = this.actions$
    .pipe(
      ofType(ActionTypes.REMOVE_TOKEN),
      tap(() => {
        removeToken();
      })
    );

  @Effect({dispatch: false})
  fetchNewTokenStart$ = this.actions$
    .pipe(
      ofType(ActionTypes.FETCH_NEW_TOKEN_START),
      tap(() => {
        this.tokenExchangeService.exchangeToken()
          .subscribe((tokenObject: TokenObject) => {
            this.store.dispatch(new FetchNewTokenFinish());
            this.store.dispatch(new SetToken(tokenObject));
          });
      })
    );

  // OVERRIDE CORE FILE START
  @Effect({dispatch: false})
  getCompanyIdentifier$ = this.actions$.pipe(
      ofType(ActionTypes.GET_IDENTIFIER),
      tap((action: GetIdentifier) => {
          if (localStorage.getItem('owClientIdentifier')) {
              const parsed = localStorage.getItem('owClientIdentifier') || null;
              return this.store.dispatch(new UtilityActions.SetIdentifier(parsed));
          }
      }),
  )
  // OVERRIDE CORE FILE END
}
