import { MyGameConfig } from '../interfaces/my-game-config';
import { CustomInitialScene } from '../scene-initial/CustomInitial.scene';
import { CustomBasicBootScene } from '../scenes-basic/custom/CustomBasic.boot.scene';

export const customGameConfig: Partial<MyGameConfig> = {
  scene: [
    CustomInitialScene,
    CustomBasicBootScene,
  ]
};
