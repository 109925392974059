<loading
  *ngIf="isLoadingUserSteps"
  [classNames]="['white', 'stroke']"
  [size]="'big'"
></loading>
<div
  *ngIf="pillDetails"
  class="container"
  [class.dev]="isPillDev"
>
  <div
    *ngIf="isPillDev"
    class="dev-info"
    [class.details-show]="getDevDetailsView()"
    (click)="toggleDevDetailsView()"
  >
    <b>CurrentStep:</b> {{ currentStep }}<br/>
    <b>PillDetails:</b>
    <code>{{ activePill | json }}</code>
  </div>


  <div
    *ngFor="let pill of pills"
    class="pill"
    [class.active]="activePill.name === pill.name"
  >
    <div class="prize-container">
      <m-ui-currency
        [item]="pillDetails.current_prize"
        [stockView]="STOCK_VIEW.G"
        [lack]="false"
      ></m-ui-currency>
      <p class="prize-container-separator">/</p>
      <m-ui-currency
        [item]="pillDetails.max_prize"
        [stockView]="STOCK_VIEW.G"
        [lack]="false"
      ></m-ui-currency>
    </div>

    <img [src]="pill.image | asset:'knowledge_pills/pill_' + pillDetails.base_pill_id">

    <ng-container
      *ngIf="pill.boxes_clicked?.length"
    >
      <ng-container *ngFor="let boxClicked of pill.boxes_clicked">
        <div
          class="box-clicked"
          [style.width]="boxClicked.width"
          [style.height]="boxClicked.height"
          [style.top]="boxClicked.top"
          [style.left]="boxClicked.left"
          (click)="clickElement(boxClicked)"
          (swipeleft)="swipeLeftElement(boxClicked)"
          (swiperight)="swipeRightElement(boxClicked)"
          [disableTooltip]="!isPillDev"
          [ngbTooltip]="tooltipTpl"
        >
        </div>
        <ng-template #tooltipTpl>
          <small class="tooltip-container">{{ boxClicked | json }}</small>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</div>
