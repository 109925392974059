<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- TOP BAR BASE -->
  <div class="top-bar base" *ngIf="activeStorage">
    <div class="absolute-left"
         [matTooltip]="'warehouse.tooltip' | myTranslate"
    >
      <i class="fas fa-warehouse-alt"></i>
      <span>{{ activeStorage.capacity | NumberWithSpaces }}</span>
    </div>

    <!-- CATEGORY NAME; avoid text flickering/jumping -->
    <div class="title" [innerHTML]="activeCategory?.name || '&nbsp;'">
    </div>
  </div>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <ng-container *ngIf="!productDetailsView">
    <!-- NAVIGATION CENTER -->
    <m-ui-navigation-center
      [hidePrev]="sliderIndex <= 0"
      [hideNext]="sliderIndex >= productsSlider.pages.length - 1"
      (prevClicked)="prevSlide()"
      (nextClicked)="nextSlide()"
    ></m-ui-navigation-center>
  </ng-container>

  <!-- BACK BAR -->
  <m-ui-back-button *ngIf="productDetailsView"></m-ui-back-button>

  <!-- PRODUCTS -->
  <div class="products-container loading-opacity">
    <div *ngIf="!isLoading && noItems" class="no-item">{{ 'warehouse.products-not-found' | myTranslate }}</div>

    <swiper #sliderProductsRef [(index)]="sliderIndex" *ngIf="!isLoading && !noItems">
      <div class="products-wrapper" *ngFor="let page of productsSlider.pages">
        <div class="product"
             *ngFor="let item of page"
             (click)="item?.product_id && openTradeDialog(item)"
             [ngClass]="{
               'empty-placeholder': item.product_id == null
             }"
        >
          <ng-container *ngIf="item.product_id">
            <div class="product-name">
              <h4>{{ item.name }}</h4>
            </div>

            <img class="product-icon" [src]="item.iconUrlBig"/>
            <p class="product-balance">{{ item.balance | NumberWithSpaces }}</p>
          </ng-container>
        </div>
      </div>
    </swiper>
  </div>

  <!-- CATEGORIES -->
  <div class="categories" *ngIf="storages.length > 0">
    <ng-container *ngFor="let storage of storages">
      <div class="category"
           *ngFor="let category of storage.categories"
           [ngClass]="{
             'active': category === activeCategory,
             'new-products': category.hasNewProducts
           }"
           (click)="changeCategory(category)">
        <p>{{ category.name }}</p>
      </div>
    </ng-container>
  </div>
</div>
