import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractBuildingUpgradeComponent } from '../../../../abstract/core/abstract-building-upgrade.component';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';
import { upgradeDifferenceParameters } from '../../../../helpers/core/upgrade.helpers';
import { generateEachPages } from '../../../../../../../shared/helpers/generate-pages.helper';
import {translate} from '../../../../../../../../core/helpers/translate.helper';

@Component({
  selector: 'app-building-upgrade-custom',
  templateUrl: './m-building-upgrade.component.html',
})
export class MBuildingUpgradeComponent extends AbstractBuildingUpgradeComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.subscribeBoardTile();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }

  getUpgradeDifferenceParameters() {
    const neighbourhoodParameters = this.mapNeighbourhood(this.buildingDetails['neighbourhood']);
    const neighbourhoodUpgradeParameters = this.mapNeighbourhood(this.upgradeBuildingDetails['neighbourhood']);

    this.diffParametersSlider.items = [];
    this.diffParametersSlider.pages = [];
    this.diffParametersSlider.items = upgradeDifferenceParameters(
      [...this.buildingDetails.parameters, ...neighbourhoodParameters],
      [...this.upgradeBuildingDetails.parameters, ...neighbourhoodUpgradeParameters]
    );
    this.diffParametersSlider = generateEachPages(this.diffParametersSlider);
    this.afterGetDataRequests();
  }

  mapNeighbourhood(neighbourhoodArray: any[]) {
    if (!neighbourhoodArray || neighbourhoodArray.length === 0) {
      return [];
    }

    const param = [{
      income: 0,
      parameter_id: 'wellbeing_bonus',
      name: translate('buildings.upgrade.bonus'),
      icon: 'wellbeing_bonus.png',
    }];

    neighbourhoodArray.map((item) => {
      param[0].income += item.max_bonus;
    });

    return param;
  }
}
