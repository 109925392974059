import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../core/providers/api.service';
import { ApiOptions } from '../../../../../../core/interfaces/api-options';

@Injectable({
  providedIn: 'root'
})
export class ApiPillsServices {
  constructor(
    public apiService: ApiService,
  ) {
  }

  setStepPill({pill_id, step_id}: { pill_id: number, step_id: number }) {
    return this.apiService.patch(`pill/step/${pill_id}/${step_id}`);
  }

  setStepPillExtraData({pill_id, step_id, extra_data}: { pill_id: number, step_id: number, extra_data: any }) {
    const options: ApiOptions = {
      body: {
        extra_data
      }
    };

    return this.apiService.patch(`pill/step/${pill_id}/${step_id}/extra-data`, options);
  }

  getPillDetails({pill_id}: { pill_id: number }) {
    return this.apiService.get(`pill/${pill_id}/details`);
  }
}
