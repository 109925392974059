import { Component, OnInit } from '@angular/core';
import {AbstractResetPasswordComponent} from '../../../../abstract/abstract-reset-password.component';
import {OwInject} from '../../../../../../core/decorators/ow-inject.decorator';
import {Identifier, IdentifierService} from '../../../../../game/services/identifier.service';
import {ResetPasswordConfirm} from '../../../../interfaces/reset-password';
import {translate} from '../../../../../../core/helpers/translate.helper';
import {AUTH_ROUTING_ABSOLUTE_PATH} from '../../../../consts/core/routing-auth-absolute.const';

@Component({
  selector: 'custom-auth-reset-password',
  templateUrl: './reset-password.component.html',

})
export class ResetPasswordComponent extends AbstractResetPasswordComponent implements OnInit {
  @OwInject(IdentifierService) identifierService;
  identifier: Identifier = null;

  ngOnInit() {
    const slug = this.identifierService.determineSlugFromUrl();
    this.identifierService.getClientIdentifier(slug).subscribe((res: Identifier) => {
      this.identifier = res;
      this.identifierService.setIdentifierToLocalStorage(this.identifier);

      const isGameFinished = this.identifierService.checkIfGameIsFinished(res);
      if (isGameFinished) {
        this.identifierService.removeIdentifierFromLocalStorage();
        this.identifierService.redirectToIdentifierPage();
      }
    });
    this.subscribeRoutingParams();
  }

  onFormSubmit() {
    const data: ResetPasswordConfirm = {
      token: this.form.value.token,
      password: this.form.value.password,
      slug: this.identifier.slug
    };

    this.authService.resetPasswordConfirm(data)
      .subscribe(() => {
        this.dialogService.openAlert({
          title: translate('auth.reset-password.alert-success.title'),
          description: translate('auth.reset-password.alert-success.description'),
        });
        this.router.navigate([AUTH_ROUTING_ABSOLUTE_PATH.LOGIN]);
      });
  }
}
