<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <!-- PRODUCT DETAILS NAME -->
    <div class="title">
      {{ product.name }}
    </div>
  </div>

  <div class="product-details loading-opacity" *ngIf="product && transactionBuyForm && transactionSellForm">
    <div class="product-info">
      <div class="product-in-glow-box">

        <!-- PRODUCT ICON -->
        <img class="product-icon" [src]="product.iconUrlBig"/>

        <!-- PRODUCT BALANCE -->
        <span class="product-balance">
            {{ product.balance | NumberWithSpaces }}
        </span>
        <div class="clip-path"></div>
      </div>

      <!-- PRODUCT DESCRIPTION -->
      <p
        class="description"
        [innerHTML]="product.description"
      ></p>
    </div>
  </div>
</div>
