import { TileMenuButtonConfig } from '../../interfaces/tile-menu-config.interface';
import { GAME_EVENTS } from '../../../constants';
import { getAdditionalBoardData } from '../../../game-gui/helpers/buildings.helper';
import { ADDITIONAL_BOARD_DATA } from '../../config/additional-board-data.config';

export const CUSTOM_BUTTONS_CONFIG: { [buttonName: string]: TileMenuButtonConfig } = {
  character_city: {
    x: 0,
    y: -135,
    name: 'tile-menu.character-city',
    frame: 'city_character.png',
    hoverFrame: 'city_character_h.png',
    activeFrame: 'city_character_a.png',
    eventName: GAME_EVENTS.OPEN_FUNCTIONAL,
    activeCheckFn: function () {
      return getAdditionalBoardData(ADDITIONAL_BOARD_DATA.OPEN_CHARACTER_CITY, this.building);
    }
  },
  neighbourhood: {
    x: 0,
    y: -135,
    name: 'tile-menu.neighbourhood',
    frame: 'wellbeing_bonus.png',
    hoverFrame: 'wellbeing_bonus_h.png',
    activeFrame: 'wellbeing_bonus_a.png',
    eventName: GAME_EVENTS.OPEN_FUNCTIONAL,
    activeCheckFn: function () {
      return getAdditionalBoardData(ADDITIONAL_BOARD_DATA.OPEN_NEIGHBOURHOOD, this.building);
    }
  },
};
