import { EventDialog } from '../../../../../../../core/interfaces/event-dialog.interface';
import { EVENT_DIALOGS_NAMES_BUILDINGS } from '../../core/event-dialogs/event-names.const';
import { MBuildingInfoComponent } from '../../../base/custom/dialogs/building-info/m-building-info.component';
import { MBuildingUpgradeComponent } from '../../../base/custom/dialogs/building-upgrade/m-building-upgrade.component';
import { MBuildingsListComponent } from '../../../base/custom/dialogs/buildings-list/m-buildings-list.component';
import { EVENT_DIALOGS_NAMES_BUILDINGS_CUSTOM } from './event-names.const';
import { BuildingCategoriesInfoComponent } from '../../../base/custom/dialogs/building-categories-info/building-categories-info.component';
import { MBuildingMoveComponent } from '../../../base/custom/dialogs/building-move/building-move.component';

export const EVENT_DIALOGS_BUILDINGS_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_INFO]: MBuildingInfoComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_UPGRADE]: MBuildingUpgradeComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_LIST]: MBuildingsListComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS_CUSTOM.BUILDING_CATEGORIES_INFO]: BuildingCategoriesInfoComponent,
  [EVENT_DIALOGS_NAMES_BUILDINGS.BUILDING_MOVE]: MBuildingMoveComponent
};
