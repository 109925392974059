import { Component } from '@angular/core';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { PlayerService } from '../../../../../../player/providers/player.service';
import { DialogService } from '../../../../../../shared/providers/dialog.service';
import { AuthService } from '../../../../../../auth/providers/auth.service';
import { GuiService } from '../../../../../services/gui.service';
import { AppState } from '../../../../../../../store/state';
import { select, Store } from '@ngrx/store';
import { LoginActions } from '../../../../../../../store/auth/login';
import { PlayerSelectors } from '../../../../../../../store/player';
import { UserService } from '../../../../../../user/providers/user.service';
import { IdentifierService } from '../../../../../services/identifier.service';
import {UserSelectors} from '../../../../../../../store/user';
import {take, withLatestFrom} from 'rxjs/operators';
import {ApiService} from "../../../../../../../core/providers/api.service";

@Component({
  selector: 'hud-player-profile-details',
  templateUrl: './hud-player-profile-details.component.html',
})
export class HudPlayerProfileDetailsComponent extends AbstractInjectBaseComponent {
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(UserService) userService: UserService;
  @OwInject(ApiService) apiService: ApiService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(GuiService) guiService: GuiService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(IdentifierService) identifierService;
  isDemo = false;
  termsUrl = '';
  player;
  subs = {
    player: null,
  };

  ngOnInit(): void {
    this.subscribePlayer();
    this.isDemoUser();
    this.getTermsFileUrl();
  }

  subscribePlayer() {
    this.subs.player = this.store
      .pipe(
        select(PlayerSelectors.selectPlayer)
      )
      .subscribe((player) => {
        this.player = player;
      });
  }

  close() {
    this.dialogService.closeAll();
  }

  getTermsFileUrl() {
    this.store.pipe(select(UserSelectors.selectState)).pipe(take(1)).subscribe(res => {
      const clientInfo = res.me?.client_info;
      if (clientInfo) {
        this.termsUrl = `${window.location.origin}/api/files/download/${clientInfo['terms_file'].uuid}/${clientInfo['terms_file'].filename}`;
      }
    });
  }

  isDemoUser() {
    const sub = this.store.pipe(select(UserSelectors.selectUser)).subscribe(user => {
      this.isDemo = user.client_info.demo;
    },
        () => {
      sub.unsubscribe();
        },
        () => {
      sub.unsubscribe();
        });
  }

  logout() {
    this.authService.logoutApi()
      .subscribe(() => {
        this.apiService.removeToken()
        this.dialogService.closeAll();
        this.guiService.isSplashShow.next(true);
        this.store.dispatch(new LoginActions.AuthLogout());
      });
  }
}
