<!-- LOGO -->
<div class="logo"></div>

<loading></loading>

<div class="container loading-opacity">
  <p class="title">{{ 'auth.registration-confirm-a.title' | myTranslate }}:</p>

  <form [formGroup]="form" *ngIf="form">

    <!-- USERNAME -->
      <ng-container *ngIf="form.get('username') as username">
        <div class="control" [class.error]="getErrors(username)">
          <div class="label-flex">
            <label>{{ 'auth.registration-confirm-a.username' | myTranslate }}</label>
          </div>
          <input class="control-input" formControlName="username" type="text" (paste)="onPaste($event)" />
          <div class="alert alert-danger" *ngIf="getErrors(username)">
            <span *ngIf="username.errors.required">{{ 'auth.registration-confirm-a.username-error-required' | myTranslate
              }}</span>
          </div>
          <div class="text-hint">
            <span>{{ 'auth.registration-confirm-a.hint-top' | myTranslate }} <br> {{ 'auth.registration-confirm-a.hint-bottom'
              | myTranslate }}</span>
          </div>
        </div>
      </ng-container>

    <!-- EMAIL -->
    <div class="control">
      <div class="label-flex">
        <label>{{ 'auth.registration-instant.label.email' | myTranslate }}:</label>
      </div>
      <input class="control-input" formControlName="email" type="text"/>
    </div>

    <!-- PASSWORD -->
    <ng-container *ngIf="form.get('password') as password">
      <div class="control" [class.error]="getErrors(password)">
        <div class="label-flex">
          <label>{{ 'auth.registration-instant.label.password' | myTranslate }}</label>
        </div>
        <input class="control-input" formControlName="password" type="password"/>
        <div class="alert alert-danger" *ngIf="getErrors(password)">
          <span *ngIf="password.errors.required">{{ 'auth.registration-confirm-a.password-error-required' | myTranslate }}</span>
          <span *ngIf="password.errors.pattern">{{ 'auth.registration-confirm-a.password-error-pattern-top' | myTranslate }} <br> {{ 'auth.registration-confirm-a.password-error-pattern-bottom' | myTranslate }} </span>
        </div>
      </div>
    </ng-container>

  </form>

  <div class="buttons">
    <!-- REGISTRATION BUTTON -->
    <button
      class="base primary"
      (click)="registration()"
      [disabled]="form.invalid"
    >
      {{ 'auth.registration-instant.form.button-send' | myTranslate }}
    </button>
  </div>
</div>
