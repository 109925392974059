import { BoardCore } from '../core/BoardCore.class';
import {BoardTileState} from '../../../../../store/game/interfaces/board-tile.state';
import {BoardViewMode} from '../../../constants';
import {TileHover} from '../tile-hover/custom/TileHover';
import {select} from '@ngrx/store';
import {selectLastUpdateTile} from '../../../../../store/game/selectors';
import {filter} from 'rxjs/operators';

export class Board extends BoardCore {
  createBoard(boardData: BoardTileState[], viewMode = BoardViewMode.Isometric) {
    this.viewMode = viewMode;
    this.tileHover = new TileHover(this.myScene);
    boardData.forEach(tileData => this.createTile(tileData));

    if (this.phaserGameService.lastUpdatedTile$) {
      this.phaserGameService.lastUpdatedTile$.unsubscribe();
    }

    this.phaserGameService.lastUpdatedTile$ = this
      .gameService
      .store
      .pipe(
        select(selectLastUpdateTile),
        filter(state => !!state),
      )
      .subscribe((updatedTile: BoardTileState) => {
        this.updateTile(updatedTile);

        if (updatedTile.player_building?.group === 'management') {{
          this.checkRoads(boardData, updatedTile);
        }}
      });
  }

  checkRoads(boardData: BoardTileState[], management: BoardTileState) {
    const clonedBoardData = JSON.parse(JSON.stringify(boardData));
    const roads = clonedBoardData.filter(x => x.player_building?.group.includes('road'));

    for (const [index, road] of roads.entries()) {
      road.player_building.level = management.player_building?.level;

      setTimeout(() => {
        this.updateTile(road);
      }, (index * 20));
    }
  }
}
