import { EventDialog } from '../../../../../../../core/interfaces/event-dialog.interface';
import { EVENT_DIALOGS_NAMES_QA } from '../../core/event-dialogs/event-names.const';
import { QaListComponent } from '../../../base/custom/dialogs/qa-list/qa-list.component';
import { EVENT_DIALOGS_NAMES_QA_CUSTOM } from './event-names.const';
import { QaListViewComponent } from '../../../base/custom/dialogs/qa-list-view/qa-list-view.component';
import { MQaFinishComponent } from '../../../base/custom/dialogs/qa-finish/qa-finish.component';

export const EVENT_DIALOGS_QA_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_QA.QA_LIST]: QaListComponent,
  [EVENT_DIALOGS_NAMES_QA_CUSTOM.QA_LIST_CUSTOM]: QaListViewComponent,
  [EVENT_DIALOGS_NAMES_QA.QA_FINISH]: MQaFinishComponent,
};
