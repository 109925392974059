import { Directive, Injectable } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../store/state';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { selectGameBoardTile } from '../../../../../../store/game/selectors';
import { filter, take } from 'rxjs/operators';
import { BuildingsService } from '../../../../services/buildings.service';
import { BoardTileState } from '../../../../../../store/game/interfaces/board-tile.state';
import { BuildingData } from '../../interfaces/core/dialogs/building-data.interface';
import { DemolishBuildingInfoResponse } from '../../../../interfaces/buildings.interfaces';
import { translate } from '../../../../../../core/helpers/translate.helper';

@Directive()
@Injectable()
export abstract class AbstractBuildingDemolishComponent extends AbstractInjectBaseComponent {
  @OwInject(MAT_DIALOG_DATA) data: BuildingData;
  @OwInject(BuildingsService) buildingsService: BuildingsService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractBuildingDemolishComponent>;

  boardTileState: BoardTileState;
  demolishBuildingInfo: DemolishBuildingInfoResponse;

  subs = {
    board: null,
  };

  subscribeBoardTile() {
    this.subs.board = this.store
      .pipe(
        select(selectGameBoardTile, {playerTileId: this.data.playerTileId}),
        filter(state => !!state),
        take(1),
      )
      .subscribe((tile) => {
        this.boardTileState = tile;
        this.demolishBuildInfo();
      });
  }

  demolishBuildInfo() {
    this.buildingsService.demolishBuildInfo(this.boardTileState.player_building.player_building_id)
      .subscribe((resp) => {
        this.demolishBuildingInfo = resp;
        this.demolishBuildingInfo.products.length === 0 && this.demolishBuildingInfo.currencies.length === 0 ?
          this.openAlertConfirmNoRefund() : this.openAlertConfirm();
      }, () => {
        this.close();
      });
  }

  demolishBuild() {
    this.buildingsService.demolishBuild(this.boardTileState.player_building.player_building_id)
      .subscribe(() => {
        this.close();
      }, () => {
        this.close();
      });
  }

  openAlertConfirmNoRefund() {
    this.dialogService.openConfirm({
      title: translate('building-demolish.title'),
      description: translate('building-demolish.description-no-refund'),
      buttonSuccess: {
        label: translate('building-demolish.button-success'),
        colorSchema: 'tertiary',
      },
    }, (confirm) => {
      if (confirm) {
        this.demolishBuild();
      } else {
        this.close();
      }
    });
  }

  openAlertConfirm() {
    this.dialogService.openConfirm({
      title: translate('building-demolish.title'),
      description: translate('building-demolish.description'),
      buttonSuccess: {
        label: translate('building-demolish.button-success'),
        colorSchema: 'tertiary',
      },
      costs: {
        separatorTitle: translate('building-demolish.separator-title'),
        currencies: this.demolishBuildingInfo.currencies,
        products: this.demolishBuildingInfo.products,
        lack: false,
      }
    }, (confirm) => {
      if (confirm) {
        this.demolishBuild();
      } else {
        this.close();
      }
    });
  }

  close() {
    this.matDialogRef.close();
  }
}
