import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractMissionDetailsComponent } from '../../../../abstract/core/abstract-mission-details.component';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';

@Component({
  selector: "app-mission-details",
  templateUrl: "./mission-details.component.html"
})
export class MissionDetailsComponent
  extends AbstractMissionDetailsComponent
  implements OnInit, OnDestroy
{
  ngOnInit() {
    this.getMissionDetails(this.data.mission.player_mission_id);
    this.subs.globalEmitter = this.globalService.globalEvents.subscribe(
      this.handleGameEvents.bind(this)
    );
  }

  ngOnDestroy() {
    this.dispatchRemoveMissionToOpen();
    this.clearInterval();
    unsubscribeObject(this.subs);
  }

  getMissionDetails(id: number) {
    this.apiMissionService.getMissionDetails(id).subscribe((resp) => {
      this.mission = resp;
      this.mission.conditions.sort(this.sortConditionsByCompletion);
      this.afterMissionDetailsRequest();
    });
  }

  //Put all completed conditions from mission in the end of array
  sortConditionsByCompletion(FirstCondition, NextCondition) {
    if (FirstCondition.completed_at) {
      return 1;
    } else if (FirstCondition.completed_at == NextCondition.completed_at) {
      return 0;
    } else {
      return -1;
    }
  }
}
