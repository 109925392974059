import { Component, OnDestroy } from '@angular/core';
import { AbstractButtonOpenQaComponent } from '../../../../abstract/core/abstract-button-open-qa.component';
import { EVENT_DIALOGS_NAMES_QA } from 'src/app/modules/game/game-ui/qa/consts/core/event-dialogs/event-names.const';
import { unsubscribeObject } from 'src/app/core/utility/unsubscribe-array';
import { NotificationsService } from 'src/app/modules/game/services/notifications.service';
import { OwInject } from 'src/app/core/decorators/ow-inject.decorator';

enum QAType {
  pill = "pill",
  quiz = "quiz",
  survey = "survey"
}
@Component({
  selector: "button-open-qa",
  templateUrl: "./button-open-qa.component.html"
})
export class ButtonOpenQaComponent
  extends AbstractButtonOpenQaComponent
  implements OnDestroy
{
  @OwInject(NotificationsService) notificationsService: NotificationsService;

  subs = {
    notyficationReadedPills: null
  };

  openQaList() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_QA.QA_LIST,
      config: {
        data: {
          eachQaId: this.qaInfo.qa_id,
          isShowBackButton: true
        }
      }
    });
    this.readNotyfication();
  }

  readNotyfication() {
    const readedNotyficationType = this.qaInfo.qa_type;
    switch (readedNotyficationType) {
      case QAType.pill:
        this.subs.notyficationReadedPills = this.notificationsService
          .setNotificationReaded("pill")
          .subscribe();
        break;
      case QAType.quiz:
        this.subs.notyficationReadedPills = this.notificationsService
          .setNotificationReaded("qa")
          .subscribe();
        break;
      case QAType.survey:
        this.subs.notyficationReadedPills = this.notificationsService
          .setNotificationReaded("survey")
          .subscribe();
        break;
    }
  }

  ngOnDestroy(): void {
    unsubscribeObject(this.subs);
  }
}
